import { Col, Form, Row, Container } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { createBudgetApi } from "../../api/BudgetApiHandler";
import { useNavigate } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { ProjectFormSelectComponent } from "../../components/formComponents/projectFormSelect";
import { Plus } from "react-feather";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { useEffect, useState } from "react";
import { ClientInterface } from "../../interfaces/client";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";

export const BudgetCreatePage = () => {

  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const createBudgetHandler = async (event: any) => {
    event.preventDefault();
    const formData = event.target;
    const createBudget = await createBudgetApi({
      project_id: formData.project.value,
      budget: formData.budget.value,
    });

    if(createBudget) navigate('/budget');
  };

  return (
    <Container fluid style={{ padding: "1em" }}>
      <Row>
        <Col lg={2} md={12} sm={12} style={{ marginRight: "1em" }}>
          <AddButtonStyled
            onClick={() => {
              navigate("/budget/create");
            }}
          >
            <Plus /> Add Budget
          </AddButtonStyled>
          <InputComponentStyled
            placeholder="buscar presupuesto"
            disabled
          />
        </Col>
        <Col lg={9} md={12} sm={12}>
          <TitleText> Presupuestos </TitleText>
          <Container
            fluid
            style={{
              backgroundColor: "white",
              paddingTop: "1em",
              borderRadius: ".3em",
              height: "100%",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <CardComponent style={{ paddingBottom: "0" }}>
              <form onSubmit={createBudgetHandler}>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Project name
                      </LabelStyled>
                      <Col sm={8}>
                        <ProjectFormSelectComponent
                          name={"project"}
                          onChange={() => {}}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Project budget
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          name="budget"
                          className="budget_field"
                          type="text"
                          placeholder="budget amount"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <AddButtonStyled
                      style={{
                        width: "60%",
                        borderRadius: "0.3em",
                        padding: ".2em",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Add Budget
                    </AddButtonStyled>
                  </Col>
                </Row>
              </form>
            </CardComponent>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
