import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate, useParams } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { toast } from "react-toastify";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { useUpdateItem } from "../../hooks/useUpdateItem";

export const EmployeeEditPage = () => {
	const [endDateActive, setEndDateActive] = useState(false);
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [contractEndDate, setContractEndDate] = useState("");
	const [contractStartDate, setContractStartDate] = useState("");
	const [bornDate, setBornDate] = useState("");
	const [position, setPosition] = useState("");
	const [permissions, setPermissions] = useState<any>({});
	const [nif,setNif] = useState("")
	const [address,setAddress] = useState("")
	const { id } = useParams();
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.employee,
		parseInt(id!)
	);
	const { updateData, updateLoading, updateError, updateItem } =
		useUpdateItem(`${ApiEndpoints.employee}/${id}/`);
	useEffect(() => {
		let userData = data;
		if (data != null) {
			console.log(userData)
			setName(userData.name);
			setLastName(userData.last_name);
			setEmail(userData.email);
			setContractEndDate(userData.contract_end_date);
			setContractStartDate(userData.contract_start_date);
			setBornDate(userData.born_date);
			setPosition(userData.position!);
			setPermissions({
				employees: userData.employees,
				projects: userData.projects,
				clients: userData.clients,
				tasks: userData.tasks,
				attachments: userData.attachments,
				turnos: userData.turnos,
			});
			setNif(userData.nif)
			setAddress(userData.address)
		}
	}, [data]);
	useEffect(() => {
		fetchItems();
	}, []);
	const updateEmployeeHandler = async (event: any) => {
		let correct = true;
		event.preventDefault();
		const formData = event.target;
		if (formData.bornDate.value === "") {
			toast.error("Es obligatorio indicar la fecha de nacimiento");
			correct = false;
		}
		if (formData.startDate.value === "") {
			toast.error(
				"Es obligatorio indicar la fecha de inicio de contrato"
			);
			correct = false;
		}
		if (formData.name.value === "") {
			toast.error("Es obligatorio indicar el nombre del empleado");
			correct = false;
		}
		if (!correct) {
			return;
		}

		await updateItem({
			password: "",
			name: formData.name.value,
			last_name: formData.surnames.value,
			email: formData.email.value,
			contract_end_date: formData.endDate.value,
			contract_start_date: formData.startDate.value,
			born_date: formData.bornDate.value,
			is_staff: true,
			position: formData.position.value,
			permissions: permissions,
			address: formData.address.value,
			nif: formData.nif.value

		});
		if (updateError) {
			toast.error(updateError.toString());
		} else {
			toast.info("Empleado actualizado correctamente");
		}
	};
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Editar empleado</TitleText>
			<CardComponent>
				<form onSubmit={updateEmployeeHandler}>
					<Row>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Nombre</LabelStyled>
								<InputStyled
									name="name"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
									value={name}
									onChange={(event: any) =>
										setName(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Apellidos</LabelStyled>
								<InputStyled
									name="surnames"
									className="project_input_field"
									type="text"
									placeholder="Apellidos"
									value={lastName}
									onChange={(event: any) =>
										setLastName(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>E-mail</LabelStyled>
								<InputStyled
									name="email"
									className="project_input_field"
									type="text"
									placeholder="email@email.com"
									value={email}
									onChange={(event: any) =>
										setEmail(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Fecha de nacimiento</LabelStyled>
								<InputStyled
									name="bornDate"
									className="project_input_field"
									type="date"
									placeholder="dd/mm/yyyy"
									value={bornDate}
									onChange={(event: any) =>
										setBornDate(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>

					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>
									Fecha de inicio de contrato
								</LabelStyled>
								<InputStyled
									name="startDate"
									className="project_input_field"
									type="date"
									placeholder="dd/mm/yyyy"
									value={contractStartDate}
									onChange={(event: any) =>
										setContractStartDate(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<Form.Check
									type="switch"
									id="custom-switch"
									label="Fecha de fin"
									onChange={() => {
										setEndDateActive(!endDateActive);
									}}
								/>

								<InputStyled
									className="project_input_field"
									type="date"
									name="endDate"
									placeholder="dd/mm/aaa"
									readOnly={!endDateActive}
									value={contractEndDate}
									onChange={(event: any) =>
										setContractEndDate(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col lg={6} md={12} xs={12}>
							<Form.Group>
								<LabelStyled>Cargo en la empresa</LabelStyled>
								<SelectInputStyled
									value={position}
									name="position"
									onChange={(event) => {
										setPosition(event.target.value);
									}}>
									<option value="encargado">Encargado</option>
									<option value="empleado">Empleado</option>
									<option value="gestor">Gestor</option>
									<option value="gerente">Gerente</option>
								</SelectInputStyled>
							</Form.Group>
						</Col>
						<Col lg={6} md={12} xs={12}>
							<Form.Group>
								<LabelStyled>NIF</LabelStyled>
								<InputStyled
									className="project_input_field"
									type="text"
									name="nif"
									placeholder="NIF"
									value={nif}
									onChange={(event: any) =>
										setNif(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						
						<Col lg={12} md={12} xs={12}>
							<Form.Group>
								<LabelStyled>Dirección</LabelStyled>
								<InputStyled
									className="project_input_field"
									type="text"
									name="address"
									placeholder="Dirección"
									value={address}
									onChange={(event: any) =>
										setAddress(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col>
							<Form.Group>
								<p
									style={{
										fontSize: "0.75rem",
										color: "rgba(48, 100, 217, 1)",
										marginBottom: "0.5em",
										fontWeight: 600,
									}}>
									Permisos que puede gestionar
								</p>
								<Form.Check
									type="switch"
									label="Empleados"
									id="employees"
									onChange={() => {
										setPermissions({
											...permissions,
											employees: !permissions.employees,
										});
									}}
									checked={permissions!.employees}
								/>
								<Form.Check
									type="switch"
									label="Proyectos"
									id="projects"
									checked={permissions.projects}
									onChange={() => {
										setPermissions({
											...permissions,
											projects: !permissions.projects,
										});
									}}
								/>
								<Form.Check
									type="switch"
									label="Tareas"
									id="tasks"
									onChange={() => {
										setPermissions({
											...permissions,
											tasks: !permissions.tasks,
										});
									}}
									checked={permissions!.tasks}
								/>
								<Form.Check
									type="switch"
									label="Turnos"
									id="turnos"
									checked={permissions.turnos}
									onChange={() => {
										setPermissions({
											...permissions,
											turnos: !permissions.turnos,
										});
									}}
								/>
								<Form.Check
									type="switch"
									id="clients"
									label="Clientes"
									checked={permissions.clients}
									onChange={() => {
										setPermissions({
											...permissions,
											clients: !permissions.clients,
										});
									}}
								/>
								<Form.Check
									type="switch"
									id="attachments"
									label="Adjuntos"
									checked={permissions.attachments}
									onChange={() => {
										setPermissions({
											...permissions,
											attachments:
												!permissions.attachments,
										});
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row
						style={{ marginTop: "1em" }}
						className="flex-row-reverse">
						<AddButtonStyled
							style={{
								width: "25%",
								borderRadius: "0.3em",
								padding: ".2em",
							}}>
							Guardar
						</AddButtonStyled>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
