import { useEffect, useState } from "react";
import { ChatFeed } from "react-chat-ui";
import { Col, Container, Row } from "react-bootstrap";
import { TitleText } from "../login/LoginStyles";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { ChevronLeft } from "react-feather";
import { AiFillDelete } from "react-icons/ai";
import {
  getRoomsApi,
  createRoomApi,
  getMessagesApi,
  createMessagesApi,
  deleteRoomApi,
  getEmployeeListApi,
} from "../../api/EmployeeApiHandler";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { Send } from "react-feather";
import { useNavigate } from "react-router-dom";
import "./chat.css"
export const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const MySwal = withReactContent(Swal);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 760);
  const navigate = useNavigate();

  const handleCreateRoom = async (selectedEmployee) => {
    const chatAlreadyCreated = chats.find(
      (chat) => chat.person === selectedEmployee.label
    );
    if (chatAlreadyCreated) {
      setActiveChat(chatAlreadyCreated.id);
      return;
    }
    await createRoomApi({ users: [selectedEmployee.value] });
    const rooms = await getRoomsApi();
    setChats(rooms);
    setActiveChat(selectedEmployee.value);
    setSelectedEmployee(null);
  };

  const formattingMessages = (messages) => {
    const formattedMessages = messages.map((message) => ({
      id: Number(message.status),
      message: message.message,
      senderName: message.senderName,
    }));
    setMessages(formattedMessages);
  };

  const sendMessageOnClick = async () => {
    if (inputValue !== "" && activeChat) {
      await createMessagesApi({ message: inputValue }, activeChat);
      setInputValue("");

      const updatedMessages = await getMessagesApi(activeChat);
      formattingMessages(updatedMessages);
    }
  };

  const deleteRoomOnClick = async (room_id) => {
    const response = await deleteRoomApi(room_id);
    const rooms = await getRoomsApi();
    setActiveChat(null);
    setChats(rooms);
    return response;
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChatClick = (chatId) => {
    setActiveChat(chatId);
  };

  useEffect(() => {
    const fetchChatsAndMessages = async () => {
      if (chats.length < 1) {
        const rooms = await getRoomsApi();
        setChats(rooms);
      }
      if (activeChat) {
        const messagesData = await getMessagesApi(activeChat);
        formattingMessages(messagesData);
      }
      if (employeeList.length < 1) {
        const employees = await getEmployeeListApi(true);
        setEmployeeList(employees);
      }
    };

    fetchChatsAndMessages();

    const intervalId = setInterval(fetchChatsAndMessages, 2000);

    return () => clearInterval(intervalId);
  }, [activeChat, chats, selectedEmployee, employeeList]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 760);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
      <Container style={{ borderRadius: "1em", height: "70vh" }}>
        <Row>
          <TitleText>Chat</TitleText>
        </Row>
        <Row style={{ borderRadius: "1em", height: "70vh" }}>
          <Col lg={12} md={12} xs={12}>
            <Row style={{ height: "100%" }}>
              <Container
                style={{
                  width: !isMobileView ? "30%" : "100%",
                  paddingLeft: !isMobileView ? 0 : "0.5em",
                  height: "105%",
                  backgroundColor: "white",
                }}
              >
                <Select
                    styles={{ zIndex: 0 }}
                  className="Index"
                  options={
                    employeeList &&
                    employeeList.map((employee) => ({
                      value: employee.id,
                      label: employee.name,
                    }))
                  }
                  value={selectedEmployee}
                  onChange={(selectedOption) =>
                    handleCreateRoom(selectedOption)
                  }
                  placeholder="Crear nuevo chat"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      zIndex: 9999
                    })
                  }}
                />
                {chats &&
                  chats.map((el, index) => (
                    <Row
                      key={index}
                      style={{ padding: ".3em", marginTop: ".5em" }}
                      onClick={!isMobileView ? () => {handleChatClick(el.id)}: () => {navigate(`/chat/${el.id}`)}}
                    >
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            padding: ".3em",
                            width: "100%",
                          }}
                        >
                          <img
                            style={{
                              marginRight: ".3em",
                              height: "50px",
                              width: "50px",
                              borderRadius: "50%",
                            }}
                            src="https://mdbcdn.b-cdn.net/img/new/avatars/8.webp"
                            alt = "avatar"
                          />
                          <div style={{ width: "100%", position: "relative" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <p style={{ margin: 0 }}>{el.person}</p>
                              <div>
                                <p style={{ margin: 0 }}>{el.date}</p>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "0px",
                                    cursor: "pointer",
                                    zIndex: 0,
                                  }}
                                  onClick={() => {
                                    MySwal.fire({
                                      title:
                                        "¿Estás seguro de eliminar el chat? ",
                                      text: "Eliminar el chat eliminará todos los mensajes que contiene",
                                      showCancelButton: true,
                                      confirmButtonText: "Eliminar",
                                      cancelButtonText: `No eliminar`,
                                      confirmButtonColor: "red",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        const response =
                                          await deleteRoomOnClick(el.id);
                                        if (response.status !== 204) {
                                          toast.error(
                                            `Error: ${response.status} ${response.statusText}`
                                          );
                                        } else {
                                          toast.success(
                                            "Chat eliminado con éxito"
                                          );
                                        }
                                      }
                                    });
                                  }}
                                >
                                  <AiFillDelete style={{ color: "red" }} />
                                </div>
                              </div>
                            </div>

                            <Row>
                              <p className="text-muted" style={{ margin: 0 }}>
                                {el.lastMsg}
                              </p>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </Container>

              {!isMobileView ? (
                <Container
                  style={{ width: "70%", paddingLeft: 0, height: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      paddingTop: ".5em",
                      paddingBottom: ".5em",
                    }}
                  >
                    <ChevronLeft
                      onClick={(event) => setActiveChat(null)}
                      style={{ marginRight: ".5em" }}
                      size={"24"}
                      color="rgba(0, 122, 255, 1)"
                    />
                    <p style={{ margin: 0 }}>
                      {chats
                        ? chats.find((c) => c.id === activeChat)?.person
                        : "Seleccione un chat"}
                    </p>
                  </div>
                  {activeChat ? (
                    <Container
                      style={{
                        position: "relative",
                        backgroundColor: "rgba(213, 212, 242, 1)",
                        width: "100%",
                        height: "100%",
                        paddingRight: 0,
                      }}
                    >
                      <div
                        style={{
                          maxHeight: "780px",
                          overflowY: "auto",
                          paddingRight: "10px",
                        }}
                      >
                        <ChatFeed
                          messages={messages}
                          isTyping={true}
                          hasInputField={false}
                          showSenderName
                          bubblesCentered={false}
                          bubbleStyles={{
                            text: {
                              fontSize: 14,
                              color: "#000000",
                            },
                            chatbubble: {
                              borderRadius: 15,
                              padding: 10,
                            },
                            userBubble: {
                              backgroundColor: "rgba(52, 151, 249, 0.4)",
                            },
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "90%",
                          position: "absolute",
                          bottom: 7,
                          left: "5%",
                          right: "10%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                        }}
                      >
                        <div
                          onClick={sendMessageOnClick}
                          style={{
                            marginLeft: "5px",
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            position: "absolute",
                            bottom: 0,
                          }}
                        >
                          <InputComponentStyled
                            type="text"
                            placeholder="Ingresa tu mensaje"
                            value={inputValue}
                            onChange={handleChange}
                          />

                          <Button
                            variant="primary"
                            style={{
                              marginTop: "8px",
                              marginLeft: "5px",
                            }}
                          >
                            <Send size={18} />
                          </Button>
                        </div>
                      </div>
                    </Container>
                  ) : (
                    <Container
                      style={{
                        position: "relative",
                        backgroundColor: "lightGray",
                        padding: "1em",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Row className="text-center">
                        <p
                          style={{
                            color: "rgba(48, 100, 217, 1)",
                            fontSize: "30px",
                            fontWeight: "400",
                          }}
                        >
                          Seleccciona un chat <br /> o <br /> crea un{" "}
                          <strong>Nuevo Chat</strong>
                        </p>
                      </Row>
                    </Container>
                  )}
                </Container>
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
  );
};
