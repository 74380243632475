import axios from 'axios';
import { TaskInterface } from '../interfaces/task';
import { apiClient } from './axiosClient';



export const getEmployeeTasksApi = async (employeeId: number) => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/employee/tasks/${employeeId}`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return false;
    }
}
export const getEmployeeProjectApi = async (employeeId: number) => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/employee/project/${employeeId}`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return false;
    }
}
export const getEmployeeCalendarApi = async (employeeId: number) => {
    try{
        let data = []
        const token =localStorage.getItem("token")
        const requestRecords = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/employee/calendar/${employeeId}/records`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        data = requestRecords.data
        const requestPlan = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/employee/calendar/${employeeId}/plan`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        data = [...data,...requestPlan.data]
        return data
    }
    catch (error){
        return false;
    }

}

export const getTimesheetForTask = async (taskId: number) => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/task/${taskId}/timesheet`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return false;
    }
}