import { Plus } from "react-feather";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TitleText } from "../login/LoginStyles";
import { useEffect, useState } from "react";
import { getFichajes } from "../../api/TurnosApi";
import Select from "react-select";
import * as XLSX from "xlsx";

export const TimeSheetListPage = () => {
  const [fichajesList, setFichajesList] = useState([]);
  const [infoType, setInfoType] = useState("employee");
  const [infoTime, setInfoTime] = useState("currentMonth");
  const [labels, setLabels] = useState(["Empleado", "Tiempo", "Proyecto"]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleExportToExcel() {
    // Transformar el objeto en un arreglo de objetos para Excel
    const arreglo = Object.entries(fichajesList).map(([id, proyectos]) => {
      // Incluir el ID como una columna en cada fila
      const fila = { ID: id, ...proyectos };
      return fila;
    });

    // Crear un nuevo libro y una hoja con los datos
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.json_to_sheet(arreglo);

    // Añadir la hoja al libro
    XLSX.utils.book_append_sheet(libro, hoja, "Datos");

    // Escribir el libro a un archivo Excel
    XLSX.writeFile(libro, "Datos.xlsx");
  }
  useEffect(() => {
    getFichajes(infoType, infoTime).then((data) => {

		setFichajesList(data);
		switch (infoType) {
			case "employee":
			setLabels(["Empleado", "Tiempo", "Proyecto"]);
			break;
			case "client":
			setLabels(["Clientes", "Tiempo", "Empleado"]);
			break;
			case "project":
			setLabels(["Poryectos", "Tiempo", "Empleado"]);
			break;
		}
    });
  }, [infoType, infoTime]);

  return (
    <Container fluid style={{ padding: "1em" }}>
      <Row>
        {isMobileView && <TitleText> Fichajes</TitleText>}
        <Col lg={2} md={4} sm={4} style={{ marginRight: "1em" }}>
          <AddButtonStyled
            onClick={() => {
              handleExportToExcel();
            }}
          >
            <Plus /> Exportar fichajes
          </AddButtonStyled>
          <div style={{ marginTop: "1em" }}>
            <Select
              defaultValue={{ label: "Empleados", value: "employee" }}
              options={[
                { label: "Empleados", value: "employee" },
                //{ label: "Clientes", value: "client" },
                { label: "Proyectos", value: "project" },
              ]}
              onChange={(value) => setInfoType(value.value)}
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <Select
              defaultValue={{ label: "Empleados", value: "employee" }}
              options={[
                { label: "Mes actual", value: "currentMonth" },
                { label: "Última semana", value: "week" },
                { label: "Último mes", value: "month" },
              ]}
              onChange={(value) => setInfoTime(value.value)}
            />
          </div>
        </Col>
        <Col lg={9} md={8} sm={8}>
          {!isMobileView && <TitleText> Fichajes</TitleText>}
          <Container
            fluid
            style={{
              backgroundColor: "white",
              paddingTop: "1em",
              borderRadius: ".3em",
              height: "100%",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <Row>
              <Col lg={2}>
                <div
                  style={{
                    padding: ".5em",
                    borderBottom: "solid 1px rgba(149, 187, 232, 0.3)",
                    borderRight: "solid 1px rgba(149, 187, 232, 0.3)",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      color: "rgba(48, 100, 217, 1)",
                      margin: ".2em",
                      fontWeight: "600",
                    }}
                  >
                    {labels[0]}
                  </p>
                </div>
                <div
                  style={{
                    padding: ".5em",
                    borderBottom: "solid 1px rgba(149, 187, 232, 0.3)",
                    borderRight: "solid 1px rgba(149, 187, 232, 0.3)",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      color: "rgba(48, 100, 217, 1)",
                      margin: ".2em",
                      fontWeight: "600",
                    }}
                  >
                    {labels[1]}
                  </p>
                </div>
                <div
                  style={{
                    padding: ".5em",
                    borderBottom: "solid 1px rgba(149, 187, 232, 0.3)",
                    borderRight: "solid 1px rgba(149, 187, 232, 0.3)",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      color: "rgba(48, 100, 217, 1)",
                      margin: ".2em",
                      fontWeight: "600",
                    }}
                  >
                    {labels[2]}
                  </p>
                </div>
              </Col>
              {fichajesList &&
                Object.keys(fichajesList).map((employeeName, index) => (
                  <Col>
                    <div
                      style={{
                        padding: ".5em",
                        borderBottom: "solid 1px rgba(149, 187, 232, 0.3)",
                        borderRight: "solid 1px rgba(149, 187, 232, 0.3)",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          margin: ".2em",
                          fontWeight: "600",
                        }}
                      >
                        {employeeName}
                      </p>
                    </div>
                    <div
                      style={{
                        padding: ".5em",
                        borderBottom: "solid 1px rgba(149, 187, 232, 0.3)",
                        borderRight: "solid 1px rgba(149, 187, 232, 0.3)",
                      }}
                    >
                      {Object.keys(fichajesList[employeeName]).map(
                        (projectName, index) => (
                          <p
                            style={{
                              fontFamily: "Roboto",
                              display: "inline-block",
                              fontSize: "16px",
                              margin: ".2em",
                              fontWeight: "600",
                              marginLeft: "1em",
                            }}
                          >
                            {projectName}
                          </p>
                        )
                      )}
                    </div>
                    <div
                      style={{
                        padding: ".5em",
                        borderBottom: "solid 1px rgba(149, 187, 232, 0.3)",
                        borderRight: "solid 1px rgba(149, 187, 232, 0.3)",
                      }}
                    >
                      {Object.keys(fichajesList[employeeName]).map(
                        (projectName, index) => (
                          <p
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              margin: ".2em",
                              fontWeight: "600",
                              display: "inline-block",
                              marginLeft: "1em",
                            }}
                          >
                            {fichajesList[employeeName][projectName]}
                          </p>
                        )
                      )}
                    </div>
                  </Col>
                ))}
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
