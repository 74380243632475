import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { TEXT } from "../../constants/text";
import { Link } from "react-router-dom";


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px 0 20px;
`;

export const TopRigthText = styled.span`
  font-size: ${TEXT.textSizeUsual};
  line-height: ${TEXT.lineHigh};
  color: ${COLORS.textColorBlue};
  font-weight: ${TEXT.textWeightsUsual};
`;

export const CustomLink = styled(Link)`
  color: ${COLORS.primaryBlue};
`;
