import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate, useParams } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { ProjectFormSelectComponent } from "../../components/formComponents/projectFormSelect";
import { toast } from "react-toastify";
import { Plus } from "react-feather";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { useEffect, useMemo, useState } from "react";
import { getExpenseApi, updateExpenseApi } from "../../api/ExpenseApiHandler";
import { isEmpty } from "../../utility/isEmpty";

export const ExpenseEditPage = () => {

  const navigate = useNavigate();

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);
  const [selectedImage, setSelectedImage] = useState("");
  const [image, setImage] = useState("");
  const { id } = useParams();

  const [projectId, setProjectId] = useState(0);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [concept, setConcept] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useMemo(async () => {
    const rlt = await getExpenseApi(parseInt(id!));
    if (rlt) {
      setProjectId(rlt.project_id);
      setName(rlt.name);
      setAmount(rlt.amount);
      setConcept(rlt.concept);
      setImage(rlt.photo?.substring(rlt.photo.lastIndexOf("/") + 1));
    } else navigate(-1);
  }, [id]);

  const updateExpenseHandler = async (event: any) => {
    event.preventDefault();
    if (event.target.name.value === "") {
      toast.error("Type of expenses field is required!");
      return;
    }
    if (event.target.amount.value === "") {
      toast.error("Expense amount field is required!");
      return;
    }

    let formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("project_id", event.target.project_id.value);
    formData.append("name", event.target.name.value);
    formData.append("amount", event.target.amount.value);
    formData.append("concept", event.target.concept.value);
    if(!isEmpty(image)) formData.append("invoice", image.substring(image.lastIndexOf(".") + 1));

    const updateExpense = await updateExpenseApi(parseInt(id!), formData);

    if (updateExpense) navigate(-1);
  };

  return (
    <Container fluid style={{ padding: "1em" }}>
      <Row>
        <Col lg={2} md={12} sm={12} style={{ marginRight: "1em" }}>
          <AddButtonStyled
            onClick={() => {
              navigate("/budget/create");
            }}
          >
            <Plus /> Add Budget
          </AddButtonStyled>
          <InputComponentStyled
            placeholder="buscar gastos"
            disabled
          />
        </Col>
        <Col lg={9} md={12} sm={12}>
          <TitleText> Presupuestos </TitleText>
          <Container
            fluid
            style={{
              backgroundColor: "white",
              paddingTop: "1em",
              borderRadius: ".3em",
              height: "100%",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <CardComponent style={{ paddingBottom: "0" }}>
              <form onSubmit={updateExpenseHandler}>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Project related
                      </LabelStyled>
                      <Col sm={8}>
                        <ProjectFormSelectComponent
                          name={"project_id"}
                          onChange={(event: any) => {
                            setProjectId(event.target.value);
                          }}
                          value={projectId}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Type of expenses
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          name="name"
                          type="text"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          placeholder="Type of expense"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Amount (no VAT)
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          name="amount"
                          type="text"
                          value={amount}
                          onChange={(e) => setAmount(parseInt(e.target.value))}
                          placeholder="amount"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Concept
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          name="concept"
                          type="text"
                          value={concept}
                          onChange={(e) => setConcept(e.target.value)}
                          placeholder="concept"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Photo (add)
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          value={image}
                          placeholder="choose any image"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <Col sm={4}></Col>
                      <Col sm={8}>
                        <AddButtonStyled
                          style={{
                            width: "60%",
                            borderRadius: "0.3em",
                            padding: ".2em",
                          }}
                          type="button"
                          onClick={(event) => {
                            document.getElementById("imageInput")!.click();
                          }}
                        >
                          {isEmpty(image) ? "Add " : "Update "}
                          file
                        </AddButtonStyled>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .bmp, .gif"
                          name="photo"
                          id="imageInput"
                          hidden={true}
                          onChange={(event: any) => {
                            if (event.target.files[0]) {
                              setSelectedImage(event.target.files[0]);
                              setImage(event.target.files[0].name);
                            } else {
                              setSelectedImage("");
                              setImage("");
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <AddButtonStyled
                      style={{
                        width: "60%",
                        borderRadius: "0.3em",
                        padding: ".2em",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Update expenses
                    </AddButtonStyled>
                  </Col>
                </Row>
              </form>
            </CardComponent>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
