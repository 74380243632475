import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useParams } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { useUpdateItem } from "../../hooks/useUpdateItem";
import { toast } from "react-toastify";

export const ClientEditPage = () => {
	const [name, setName] = useState<string>("");
	const [phone, setPhone] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [nif, setNif] = useState<string>("");
	const [razonSocial, setRazonSocial] = useState<string>("");
	const [address, setAddress] = useState<string>("");
	const [startDate, setStartDate] = useState<string>("");

	const { id } = useParams();
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.client,
		parseInt(id!)
	);
	const { updateData, updateLoading, updateError, updateItem } =
		useUpdateItem(`${ApiEndpoints.client}/${id}/`);
	useEffect(() => {
		fetchItems();
	}, []);
	useEffect(() => {
		if (data != null) {
			setName(data.name);
			setPhone(data.phone_number);
			setEmail(data.email);
			setNif(data.nif);
			setRazonSocial(data.razon_social);
			setAddress(data.address);
			setStartDate(data.start_date);
		}
	}, [data]);

	const updateClientHandler = async (event: any) => {
		event.preventDefault();
		const formData = event.target;
		await updateItem({
			name: formData.name.value,
			email: formData.email.value,
			phone_number: formData.phone_number.value,
			nif: formData.nif.value,
			razon_social: formData.razon_social.value,
			address: formData.address.value,
			start_date: formData.start_date.value,
		});
		if (updateError) toast.error(updateError.toString());
		else toast.info("Cliente actualizado correctamente");
	};
	console.log(data);
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Actualizar cliente</TitleText>
			<CardComponent>
				{data && (
					<form onSubmit={updateClientHandler}>
						<Row>
							<Col
								lg={6}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled>Nombre</LabelStyled>
									<InputStyled
										name="name"
										className="project_input_field"
										type="text"
										placeholder="Nombre"
										value={name}
										onChange={(event: any) =>
											setName(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
							<Col
								lg={6}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled>Teléfono</LabelStyled>
									<InputStyled
										name="phone_number"
										className="project_input_field"
										type="text"
										placeholder="Número de teléfono"
										value={phone}
										onChange={(event: any) =>
											setPhone(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>E-mail</LabelStyled>
									<InputStyled
										name="email"
										className="project_input_field"
										type="text"
										placeholder="email@email.com"
										value={email}
										onChange={(event: any) =>
											setEmail(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>NIF</LabelStyled>
									<InputStyled
										name="nif"
										className="project_input_field"
										type="text"
										placeholder="NIF"
										value={nif}
										onChange={(event: any) =>
											setNif(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>Fecha de inicio</LabelStyled>
									<Form.Control
										name="start_date"
										type="date"
										value={startDate}
										onChange={(event) => {
											setStartDate(event.target.value);
										}}
									/>
								</Form.Group>
							</Col>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>Dirección</LabelStyled>
									<InputStyled
										name="address"
										className="project_input_field"
										type="text"
										placeholder="Direción"
										value={address}
										onChange={(event: any) =>
											setAddress(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>
							<Col
								lg={12}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>Razón Social</LabelStyled>
									<InputStyled
										name="razon_social"
										className="project_input_field"
										type="text"
										placeholder="Razón social"
										value={razonSocial}
										onChange={(event: any) =>
											setRazonSocial(event.target.value)
										}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row
							style={{ marginTop: "1em" }}
							className="flex-row-reverse">
							<AddButtonStyled
								style={{
									width: "25%",
									borderRadius: "0.3em",
									padding: ".2em",
								}}>
								Actualizar
							</AddButtonStyled>
						</Row>
					</form>
				)}
			</CardComponent>
		</Container>
	);
};
