import axios from "axios";
import { CompanyInterface } from "../interfaces/comapnyInterface";
import { apiClient } from "./axiosClient";


export const UpdateUserSettings = async (notifications: any) => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.put(`${process.env.REACT_APP_BASE_URL}api/v1/notifications/settings`,    
        notifications,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        if (request.status === 204)
            return true
        return false
    }
    catch (error){
        return [];
    }
}


export const GetUserSettings = async () => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/notifications/settings`,    
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return [];
    }
}