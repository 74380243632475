import axios from "axios";
import { CompanyInterface } from "../interfaces/comapnyInterface";
import { apiClient } from "./axiosClient";


export const UpdateCompanyApi = async (data: CompanyInterface) => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.put(`${process.env.REACT_APP_BASE_URL}api/v1/company/${data.id}`,
        data,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return [];
    }
}

export const GetComapnyApi  = async () => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/company`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        console.log(request.data)
        return request.data;
    }
    catch (error){
        return [];
    }
}


export const UpdateComapnyApi  = async (company: CompanyInterface) => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.put(`${process.env.REACT_APP_BASE_URL}api/v1/company`,
        company,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        console.log(request.data)
        return request.data;
    }
    catch (error){
        return [];
    }
}