import { useCallback, useState } from "react"
import { apiClient } from "../api/axiosClient"


export const useFetchItems = (url: string,id?:number) => {
    const [isLoading,setIsLoading] = useState(false)
    const [error,setError] = useState<Error|null>(null)
    const [data,setData] = useState<any>(null)
    const fetchItems = useCallback(async (queryParams?: string) => {
        const token =localStorage.getItem("token")
        setIsLoading(true)
        try{
            let modifiedUrl = id !== undefined ? `${url}/${id}` : url;
            if (queryParams)
              modifiedUrl = `${modifiedUrl}?${queryParams}`
            const request = await apiClient(modifiedUrl,
              {
                headers:{
                    "Authorization": `Bearer ${token}`
                }
            })
            setData(request.data)
          }
        catch (error) {
            if (error instanceof Error) {
              setError(error);
            } else {
              setError(new Error('Error obteniendo la información'));
            }
          } finally {
            setIsLoading(false);
          }
    },[url,id])
    return {data,isLoading,error,fetchItems}
}
