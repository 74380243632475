import { toast } from "react-toastify";
import { isEmpty } from "./isEmpty";

export const Notification = (type: string, data: any) => {
    if(isEmpty(data)) return

    if (type === 'error') Object.keys(data).map((item) => setTimeout(() => {
        let contents = data[item];
        toast.error(contents[0])
    }, 300))
    else if (type === "info")
      Object.keys(data).map((item) =>
        setTimeout(() => {
          let contents = data[item];
          toast.info(contents[0]);
        }, 300)
      );
    else if (type === "loading")
      Object.keys(data).map((item) =>
        setTimeout(() => {
          let contents = data[item];
          toast.loading(contents[0]);
        }, 300)
      );
    else if (type === "success")
      Object.keys(data).map((item) =>
        setTimeout(() => {
          let contents = data[item];
          toast.success(contents[0]);
        }, 300)
      );
    else if (type === "warning")
      Object.keys(data).map((item) =>
        setTimeout(() => {
          let contents = data[item];
          toast.warning(contents[0]);
        }, 300)
        );
    else if (type === "warn")
      Object.keys(data).map((item) =>
        setTimeout(() => {
          let contents = data[item];
          toast.warn(contents[0]);
        }, 300)
        );
    else if (type === "dark")
      Object.keys(data).map((item) =>
        setTimeout(() => {
          let contents = data[item];
          toast.dark(contents[0]);
        }, 300)
      );
}