import { useNavigate } from "react-router-dom";
import { APILoginHandler, GetUserInfoHandler } from "../../api/UserApiHandler";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { HeaderLoginComponent } from "../../components/headerLogin/HeaderLogin";
import {
	GlobalContainer,
	TitleText,
	FormContainer,
	Label,
	InputContainer,
	Input,
	ButtonContainer,
	ButtonText,
	TextContainer,
	Text,
	CustomLinkText,
} from "./LoginStyles";
import { ToastContainer, toast } from "react-toastify";

export const AccessPage = () => {
	const navigate = useNavigate();
	const loginSubmitHandler = async (event: any) => {
		event.preventDefault();
		const formData = event.target;
		const token = await APILoginHandler(
			formData.username.value,
			formData.password.value
		);
		if (token) {
			localStorage.setItem("token", token.access);
			GetUserInfoHandler().then((loggedUser) => {
				localStorage.setItem(
					"permissions",
					JSON.stringify(loggedUser.permissions)
				);
			});
			navigate("/");
		} else {
			toast.error("Credenciales inválidas");
		}
	};
	return (
		<>
			<ToastContainer />
			<HeaderLoginComponent isNewUserText={false} />
			<GlobalContainer>
				<TitleText>Acceder</TitleText>
				<FormContainer onSubmit={loginSubmitHandler}>
					<InputContainer>
						<Label>Email</Label>
						<Input
							type="text"
							name="username"
							placeholder="Introduce tu correo electrónico"
						/>
					</InputContainer>
					<InputContainer>
						<Label>Contraseña</Label>
						<Input
							type="password"
							name="password"
							placeholder="Contraseña"
						/>
					</InputContainer>
					<div style={{ display: "flex", justifyContent: "end" }}>
						<SelectButtonStyled
							active={true}
							style={{ width: "30%" }}>
							<p style={{ margin: 0 }}>Login</p>
						</SelectButtonStyled>
					</div>
				</FormContainer>

				<TextContainer>
					<Text>
						¿No tienes cuenta?{" "}
						<CustomLinkText to="/recover-password">
							Recuperar contraseña
						</CustomLinkText>
					</Text>
				</TextContainer>
			</GlobalContainer>
		</>
	);
};
