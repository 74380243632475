import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectFormSelectComponent } from "../../components/formComponents/projectFormSelect";
import { useEffect, useState } from "react";
import { EmployeeFormSelect } from "../../components/formComponents/employeeFormSelect";
import { TaskInterface } from "../../interfaces/task";
import Spinner from "react-bootstrap/Spinner";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { ApiEndpoints } from "../../constants/urls";
import { useFetchItems } from "../../hooks/useFetchItems";
import { useUpdateItem } from "../../hooks/useUpdateItem";
import useDeleteItem from "../../hooks/useDeleteItem";

export const TaskDetailPage = () => {
	const [task, setTask] = useState<TaskInterface | null>(null);
	const [employee, setEmployee] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState<any>([]);

	const navigate = useNavigate();
	const { id } = useParams();
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.task,
		parseInt(id!)
	);
	const { updateData, updateLoading, updateError, updateItem } =
		useUpdateItem(`${ApiEndpoints.task}/${id}/`);
	const { deleteData, deleteLoading, deleteError, deleteItem } =
		useDeleteItem(`${ApiEndpoints.task}/${id}`);
	const MySwal = withReactContent(Swal);
	useEffect(() => {
		fetchItems();
	}, []);

	useEffect(() => {
		if (data != null) {
			setTask(data!);
			let dataF: any = [];
			if (data.labels) {
				JSON.parse(data.labels).forEach((e: string) => {
					dataF.push(e);
				});
				setSelectedOptions(dataF);
			}
			if (data.employee_json.length > 0){
				setEmployee(data.employee_json)
			}
			setTask({
				...data,
			});
		}
	}, [data]);
	const updateTaskHandler = async (event: any) => {
		event.preventDefault();
		if (task!.name === "") {
			toast.error("Es obligatorio rellenar el nombre de la tarea");
			return;
		}
		if (task!.due_date === "") {
			toast.error("Es obligatorio rellenar la fecha de finalización de la tarea");
			return;
		}
		let employee_ids:any[] = []
		employee.forEach((empl:any) => employee_ids.push(empl.value))
		
		await updateItem({
			name: event.target.taskName.value,
			project: event.target.project.value,
			employee: employee_ids,
			labels: JSON.stringify(selectedOptions),
			status: event.target.status.value,
			notes: event.target.notes.value || "",
			due_date: event.target.due_date.value,
		});
		
		if (updateError !== null) {
			toast.error(updateError.toString());
		} else {
			await fetchItems();
			toast.info("Tarea actualizada correctamente");
		}
	};
	const handleEtiquetasChanges = (selectedOptions: any) => {
		setSelectedOptions(selectedOptions);
	};

	return (
		<Container style={{ padding: "1em" }}>
			<Row style={{ marginTop: "1em", marginBottom: "1em" }}>
				<Col style={{ textAlign: "center" }}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}>
						<div style={{ display: "flex" }}>
							<p
								style={{
									marginBottom: 0,
									fontSize: "1.4rem",
									fontWeight: 800,
									fontFamily: "Roboto",
									lineHeight: "1.6 rem",
									color: "rgba(48, 100, 217, 1)",
									marginLeft: ".5em",
								}}>
								Actualizar tarea
							</p>
						</div>
						<div>
							<SelectButtonStyled
								active={false}
								onClick={() => {
									MySwal.fire({
										title: "¿Estas seguro de eliminar la tarea? ",
										showCancelButton: true,
										confirmButtonText: "Eliminar",
										cancelButtonText: `No eliminar`,
										confirmButtonColor: "red",
									}).then(async (result) => {
										if (result.isConfirmed) {
											await deleteItem();

											toast.info(
												`Tarea eliminada correctamente`
											);

											navigate("/task");
										}
									});
								}}>
								Eliminar
							</SelectButtonStyled>
						</div>{" "}
					</div>
				</Col>
			</Row>
			<CardComponent>
				{task == null ? (
					<Spinner
						animation="border"
						role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				) : (
					<>
						<form
							id="UpdateTask"
							onSubmit={updateTaskHandler}>
							<Row>
								<Col
									lg={6}
									md={6}
									xs={6}>
									<Form.Group>
										<LabelStyled className="project_input_label">
											Nombre de la tarea *
										</LabelStyled>
										<InputStyled
											name="taskName"
											className="project_input_field"
											type="text"
											placeholder="Nombre"
											onChange={(event) =>
												setTask({
													...task,
													name: event.target.value,
												})
											}
											value={task!.name}
										/>
									</Form.Group>
								</Col>
								<Col
									lg={6}
									md={6}
									xs={6}>
									<Form.Group>
										<LabelStyled>Etiquetas</LabelStyled>
										<CreatableSelect
											isMulti // Permite seleccionar múltiples opciones
											onChange={handleEtiquetasChanges}
											value={selectedOptions}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: "2em" }}>
								<Col
									lg={6}
									md={6}
									xs={6}>
									<Form.Group>
										<LabelStyled className="project_input_label">
											Asignar proyecto
										</LabelStyled>
										<ProjectFormSelectComponent
											name={"project"}
											value={task!.project}
											onChange={(event: any) => {
												setTask({
													...task,
													project: event.target.value,
												});
											}}
										/>
									</Form.Group>
								</Col>
								<Col
									lg={6}
									md={6}
									xs={6}>
									<Form.Group>
										<LabelStyled>Empleado</LabelStyled>
										<EmployeeFormSelect
											multi={true}
											name="employee"
											value={employee}
											onChange={(value: any) => {
												let ids: any = [];
												value.forEach((val: any) =>
													ids.push(val)
												);
												setEmployee(ids);
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: "2em" }}>
								<Col
									lg={8}
									md={8}
									xs={8}>
									<Form.Group>
										<Form.Check
											type="switch"
											id="perm-employee"
											name="employees"
											label="¿Tarea finalizada?"
											checked={task.status === "end"}
											onClick={ async() => {
												let employee_ids:any[] = []
												employee.forEach((empl:any) => employee_ids.push(empl.value))
												await updateItem({
													name: task!.name,
													project: task!.project,
													employee: employee_ids,
													labels: JSON.stringify(selectedOptions),
													status: "end",
													notes: task!.notes ,
													due_date: task.due_date,
												});
												if (task.status !== "end") {
													setTask({
														...task,
														status: "end",
													});
												}
											}}
										/>
										<LabelStyled className="project_input_label">
											Estado de la tarea
										</LabelStyled>
										<SelectInputStyled
											onChange={(event) =>
												setTask({
													...task,
													status: event.target.value,
												})
											}
											value={task.status}
											name="status">
											<option value="new">Nueva</option>
											<option value="running">
												En Ejecución
											</option>
											<option value="end">
												Finalizada
											</option>
										</SelectInputStyled>
										<LabelStyled className="project_input_label">
											Fecha de entrega
										</LabelStyled>
										<Form.Control
											name="due_date"
											type="datetime-local"
											value={task.due_date  ? task.due_date.slice(0, 16): null}
											onChange={(event) => {
												setTask({
													...task,
													due_date:
														event.target.value,
												});
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row className="mt-4">
								<Col>
									<LabelStyled>Notas</LabelStyled>
									<Form.Control
										as="textarea"
										onChange={(event) =>
											setTask({
												...task,
												notes: event.target.value,
											})
										}
										rows={3}
										value={task!.notes}
										name="notes"></Form.Control>
								</Col>
							</Row>
							<Row
								style={{ marginTop: "2em" }}
								className="flex-row-reverse">
								<Col
									lg={2}
									md={2}
									xs={6}>
									<AddButtonStyled
										style={{
											borderRadius: "0.3em",
											padding: ".2em",
										}}>
										Actualizar
									</AddButtonStyled>
								</Col>
							</Row>
						</form>
					</>
				)}
			</CardComponent>
		</Container>
	);
};
