import { ArrowLeft} from "react-feather";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { GetUserSettings, UpdateUserSettings } from "../../api/settingsClient";
import { toast } from "react-toastify";

export const NotificationSettingsPage = () => {
	const [settings, setSettings] = useState({
		assign_tasks: false,
		create_employee: false,
		edit_tasks: false,
		edit_turno: false,
		start_timer: false,
		stop_timer: false,
	});
	const navigate = useNavigate();
	useEffect(() => {
		const getSettings = async () => {
			let settingsInfo = await GetUserSettings();
			setSettings(settingsInfo);
		};
		getSettings();
	}, []);
	const updateNotificationSettings = async () => {
		await UpdateUserSettings(settings)
		toast.success("Notificaciones actualizadas correctamente")
	}
	return (
		<>
			{settings != null && (
				<Container style={{ padding: "1em" }}>
					<Row>
						<Col
							lg={8}
							md={8}
							sm={8}>
							<Row>
								<Col style={{ textAlign: "center" }}>
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
										}}>
										<div style={{ display: "flex" }}>
											<button
												onClick={() => {
													navigate("/notifications");
												}}
												style={{
													backgroundColor:
														"rgba(48, 100, 217, 0.2)",
													borderRadius: ".5em",
													borderColor: "transparent",
													paddingLeft: ".6em",
													paddingRight: ".6em",
												}}>
												<ArrowLeft color="rgba(48, 100, 217, 1)" />
											</button>
											<p
												style={{
													marginBottom: 0,
													fontSize: "1.4rem",
													fontWeight: 800,
													fontFamily: "Roboto",
													lineHeight: "1.6 rem",
													color: "rgba(48, 100, 217, 1)",
													marginLeft: ".5em",
												}}>
												Ajuste de notificaciones
											</p>
										</div>
										<div>
											<SelectButtonStyled
												active={true}
												onClick={() => updateNotificationSettings()}>
												Guardar
											</SelectButtonStyled>
										</div>{" "}
									</div>
								</Col>
							</Row>
							<Row style={{ marginTop: "2em" }}>
								<Container
									className="d-flex flex-row justify-content-between"
									style={{
										backgroundColor: "white",
										padding: "1em",
									}}>
									<Row>
										<Col className="text-center">
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}>
												<Form.Check
													type="switch"
													id="perm-employee"
													name="employees"
													checked={
														settings.assign_tasks
													}
													onChange={() => {
														setSettings({
															...settings,
															assign_tasks: !settings.assign_tasks 
														})
													}}
												/>
												<p
													style={{
														margin: 0,
														color: "rgba(48, 100, 217, 1)",
														fontSize: "12px",
														fontWeight: "600",
													}}>
													Tareas asignadas
												</p>
											</div>
											<p
												style={{
													fontSize: "12px",
													fontWeight: "400",
													fontFamily: "Roboto",
												}}>
												Informar cuando te asignen una
												tarea
											</p>
										</Col>
									</Row>
								</Container>
							</Row>
							<Row style={{ marginTop: ".5em" }}>
								<Container
									className="d-flex flex-row justify-content-between"
									style={{
										backgroundColor: "white",
										padding: "1em",
									}}>
									<Row>
										<Col className="text-center">
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}>
												<Form.Check
													type="switch"
													id="perm-employee"
													name="employees"
													checked={settings.edit_tasks}
													onChange={() => {
														setSettings({
															...settings,
															edit_tasks: !settings.edit_tasks 
														})
													}}
												/>
												<p
													style={{
														margin: 0,
														color: "rgba(48, 100, 217, 1)",
														fontSize: "12px",
														fontWeight: "600",
													}}>
													Edición de tareas
												</p>
											</div>
											<p
												style={{
													fontSize: "12px",
													fontWeight: "400",
													fontFamily: "Roboto",
												}}>
												Informar cuando editan las
												tareas o proyectos
											</p>
										</Col>
									</Row>
								</Container>
							</Row>
							<Row style={{ marginTop: ".5em" }}>
								<Container
									className="d-flex flex-row justify-content-between"
									style={{
										backgroundColor: "white",
										padding: "1em",
									}}>
									<Row>
										<Col className="text-center">
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}>
												<Form.Check
													type="switch"
													id="perm-employee"
													name="employees"
													checked={settings.create_employee}
													onChange={() => {
														setSettings({
															...settings,
															create_employee: !settings.create_employee 
														})
													}}
												/>
												<p
													style={{
														margin: 0,
														color: "rgba(48, 100, 217, 1)",
														fontSize: "12px",
														fontWeight: "600",
													}}>
													Nuevos empleados
												</p>
											</div>
											<p
												style={{
													fontSize: "12px",
													fontWeight: "400",
													fontFamily: "Roboto",
												}}>
												Informar cuando crean nuevos
												empleados
											</p>
										</Col>
									</Row>
								</Container>
							</Row>
							<Row style={{ marginTop: ".5em" }}>
								<Container
									className="d-flex flex-row justify-content-between"
									style={{
										backgroundColor: "white",
										padding: "1em",
									}}>
									<Row>
										<Col className="text-center">
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}>
												<Form.Check
													type="switch"
													id="perm-employee"
													name="employees"
													checked={settings.edit_turno}
													onChange={() => {
														setSettings({
															...settings,
															edit_turno: !settings.edit_turno 
														})
													}}
												/>
												<p
													style={{
														margin: 0,
														color: "rgba(48, 100, 217, 1)",
														fontSize: "12px",
														fontWeight: "600",
													}}>
													Edición de turnos
												</p>
											</div>
											<p
												style={{
													fontSize: "12px",
													fontWeight: "400",
													fontFamily: "Roboto",
												}}>
												Informar cuando editan los
												turnos
											</p>
										</Col>
									</Row>
								</Container>
							</Row>
							<Row style={{ marginTop: ".5em" }}>
								<Container
									className="d-flex flex-row justify-content-between"
									style={{
										backgroundColor: "white",
										padding: "1em",
									}}>
									<Row>
										<Col className="text-center">
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}>
												<Form.Check
													type="switch"
													id="perm-employee"
													name="employees"
													checked={settings.start_timer}
													onChange={() => {
														setSettings({
															...settings,
															start_timer: !settings.start_timer 
														})
													}}
												/>
												<p
													style={{
														margin: 0,
														color: "rgba(48, 100, 217, 1)",
														fontSize: "12px",
														fontWeight: "600",
													}}>
													Inicio de jornada
												</p>
											</div>
											<p
												style={{
													fontSize: "12px",
													fontWeight: "400",
													fontFamily: "Roboto",
												}}>
												Informar cuando tus emeplados
												inicien su jornada
											</p>
										</Col>
									</Row>
								</Container>
							</Row>
							<Row style={{ marginTop: ".5em" }}>
								<Container
									className="d-flex flex-row justify-content-between"
									style={{
										backgroundColor: "white",
										padding: "1em",
									}}>
									<Row>
										<Col className="text-center">
											<div
												style={{
													display: "flex",
													alignItems: "center",
												}}>
												<Form.Check
													type="switch"
													id="perm-employee"
													name="employees"
													checked={settings.stop_timer}
													onChange={() => {
														setSettings({
															...settings,
															stop_timer: !settings.stop_timer 
														})
													}}
												/>
												<p
													style={{
														margin: 0,
														color: "rgba(48, 100, 217, 1)",
														fontSize: "12px",
														fontWeight: "600",
													}}>
													Pausa de jornada
												</p>
											</div>
											<p
												style={{
													fontSize: "12px",
													fontWeight: "400",
													fontFamily: "Roboto",
												}}>
												Informar cuando tus empleados
												pausen su jornada
											</p>
										</Col>
									</Row>
								</Container>
							</Row>
						</Col>
					</Row>
				</Container>
			)}
		</>
	);
};
