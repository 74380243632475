import styled from "styled-components";
import Form from 'react-bootstrap/Form';


export const SwitcherStyled = styled(Form.Check)`
    ::before {
        background-color: rgba(48, 100, 217, 1);
    }
    ::after{
        background-color:  rgba(172, 172, 172, 1);
    }
    label{
        font-family: "Roboto";
        font-size: 0.75 rem;
        color: rgba(48, 100, 217, 0.5);
        font-weight: 600;
    }
`