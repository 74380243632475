import { useEffect } from "react"
import { SelectInputStyled } from "./selectStyled";
import { ProjectInterface } from "../../interfaces/project";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { useNavigate } from "react-router-dom";



export const ProjectFormSelectComponent = (props: { name: string, onChange: any, value?: number }) => {
    const navigate = useNavigate();

    const {data,isLoading,error,fetchItems} = useFetchItems(ApiEndpoints.project)
    useEffect(() => {
        fetchItems()
    }, [])
    
    if (error) navigate(-1);

    return (
        <SelectInputStyled value={props.value} name={props.name} onChange={props.onChange}>
            {data && data.map((project: ProjectInterface,index: number) => 
                <option value={project.id}> {project.name}</option>
            )}
        </SelectInputStyled>
    )

}