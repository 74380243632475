import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate, useParams } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { toast } from "react-toastify";
import { useCreateItem } from "../../hooks/useCreateItems";
import { ApiEndpoints } from "../../constants/urls";

export const AttachmentCreatePage = () => {
	const navigate = useNavigate()
	const {id} = useParams();
	const {data,isLoading,error,createItem} = useCreateItem(ApiEndpoints.attachment);
	const createFileHandler = async (event:any) => {
		event.preventDefault();
		let valid = true;
		console.log(event.target.file.value)
		if (!event.target.file.value){
			toast.error("Es necesario agregar un archivo")
			valid =false;
		}
		if (event.target.name.value === ""){
			toast.error("Es necesario especificar el nombre del archivo")
			valid =false
		}
		if (!valid)
			return

		const attachmentInformation =  new FormData();
		attachmentInformation.append('file', event.target.file.files[0]);    
        attachmentInformation.append('name', event.target.name.value)
		if (id)
			attachmentInformation.append("folder",id!)
		await createItem(attachmentInformation)
		if (error)
			toast.error(error.toString())
		navigate("/attachment")
	}
	
	return (
		<Container style={{ padding: "1em"}}>
			<TitleText>Nuevo adjunto</TitleText>
			<CardComponent>
				<form onSubmit={createFileHandler}>
				<Row>
					<Col
						lg={12}
						md={12}
						xs={12}>
						<Form.Group>
							<LabelStyled >
								Nombre *
							</LabelStyled>
							<InputStyled
								name="name"
								className="project_input_field"
								type="text"
								placeholder="Nombre"
							/>
						</Form.Group>
					</Col>
					
				</Row>
				<Row style={{ marginTop: "1em" }}>
					<Col
						lg={12}
						md={12}
						xs={12}>
						<Form.Group controlId="formFileMultiple" className="mb-3">
							<Form.Label>Selecciona los adjuntos para añadir</Form.Label>
							<Form.Control onChange={(event: any) => (console.log(event.target.files[0]))}  name="file" type="file" />
						</Form.Group>
					</Col>
					
				</Row>


				<Row
						style={{ marginTop: "2em" }}
						className="flex-row-reverse">
						<Col lg={2} md={2} xs={6}>
						<AddButtonStyled
							style={{
								
								borderRadius: "0.3em",
								padding: ".2em",
							}}>
							Guardar
						</AddButtonStyled>
						</Col>
					</Row>
				</form>
				
			</CardComponent>
		</Container>
	);
};
