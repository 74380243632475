import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import {
	AddButtonStyled,
} from "../../components/buttonComponent/buttonStyled";
import { useNavigate, useParams } from "react-router-dom";
import {
	LabelStyled,
	FormParagraphStyled,
} from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import useDeleteItem from "../../hooks/useDeleteItem";
import { useUpdateItem } from "../../hooks/useUpdateItem";

export const FolderDetailPage = () => {
	const [name, setName] = useState<null|string>(null);
	const navigate = useNavigate();
	const { id } = useParams();
	const {data,isLoading,error,fetchItems} = useFetchItems(ApiEndpoints.attachment,parseInt(id!));
	const {deleteData,deleteLoading,deleteError,deleteItem} = useDeleteItem(`${ApiEndpoints.attachment}/${id!}`);
	const {updateData,updateLoading,updateError,updateItem} = useUpdateItem(`${ApiEndpoints.attachment}/${id!}`);

	const MySwal = withReactContent(Swal);

	useEffect(() => {fetchItems()},[])
	useEffect(() => {
		if (data != null && name == null)
			setName(data!.name)
	},[data])

	const editFileHandler = async(event: any) => {
		event.preventDefault();
		await updateItem({name: name})
		if (updateError)
			toast.error(updateError.toString())
		else
			toast.success("Adjunto actualizado correctamente")
	};
	
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Editar carpeta</TitleText>
			<CardComponent>
				{data && (
					<>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<div>
								<SelectButtonStyled
									active={false}
									onClick={() => {
										MySwal.fire({
											title: "¿Estas seguro de eliminar la carpeta? ",
											text: "Eliminar la carpeta eliminará todos los adjuntos que contiene",
											showCancelButton: true,
											confirmButtonText: "Eliminar",
											cancelButtonText: `No eliminar`,
											confirmButtonColor: "red",
										}).then(async (result) => {
											if (result.isConfirmed) {
												await deleteItem()
												if (deleteError)
													toast.error(deleteError.toString())
												else
													navigate("/attachment");
											}
										});
									}}>
									Eliminar
								</SelectButtonStyled>
							</div>
					
						</div>{" "}
						<form onSubmit={editFileHandler}>
							<Row>
								<Col
									lg={12}
									md={12}
									xs={12}>
									<Form.Group>
										<LabelStyled>Nombre *</LabelStyled>
										<InputStyled
											name="name"
											className="project_input_field"
											type="text"
											placeholder="Nombre"
											value={name!=null ? name : ""}	
											onChange={(event) =>
												setName(event.target.value)
											}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: "1em" }}>
								<Col
									lg={6}
									md={12}
									xs={12}>
									<FormParagraphStyled>
										Autor
									</FormParagraphStyled>
									<p>{data!.author}</p>
								</Col>
								<Col
									lg={6}
									md={12}
									xs={12}>
									<FormParagraphStyled>
										Última modificación
									</FormParagraphStyled>
									<p>
										{data!.modified_date.toString()}
									</p>
								</Col>
							</Row>
							<Row
						style={{ marginTop: "2em" }}
						className="flex-row-reverse">
						<Col lg={2} md={2} xs={6}>
						<AddButtonStyled
							style={{
								
								borderRadius: "0.3em",
								padding: ".2em",
							}}>
							Guardar
						</AddButtonStyled>
						</Col>
					</Row>
						</form>
					</>
				)}
			</CardComponent>
		</Container>
	);
};
