import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { ClientFormSelect } from "../../components/formComponents/clientFormSelect";
import { toast } from "react-toastify";
import { EmployeeFormSelect } from "../../components/formComponents/employeeFormSelect";
import { useCreateItem } from "../../hooks/useCreateItems";
import { ApiEndpoints } from "../../constants/urls";

export const ProjectCreatePage = () => {
	const navigate = useNavigate();
	const { data, isLoading, error, createItem } = useCreateItem(
		`${ApiEndpoints.project}/`
	);
	const createProjectHandler = async (event: any) => {
		event.preventDefault();
		let valid = true;
		const formData = event.target;
		if (event.target.name.value === "") {
			toast.error("Es necesario especificar el nombre del proyecto");
			valid = false;
		}
		if (event.target.client.value === "") {
			toast.error("Es necesario especificar el cliente en el proyecto");
			valid = false;
		}
		if (event.target.divisa.value === "") {
			toast.error("Es necesario especificar la divisa del proyecto");
			valid = false;
		}
		if (event.target.color.value === "") {
			toast.error("Es necesario especificar el color del proyecto");
			valid = false;
		}
		if (valid) {
			await createItem({
				name: formData.name.value,
				client: formData.client.value,
				divisa: formData.divisa.value,
				color: formData.color.value,
				project_ow: formData.projectBoss.value,
				due_date: formData.due_date.value
			});
			if (error) toast.error(`Error creando el proyecto \n ${error} `);
			else navigate("/project");
		}
	};
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Crear nuevo proyecto</TitleText>
			<CardComponent>
				<form onSubmit={createProjectHandler}>
					<Row>
						<Col
							lg={10}
							md={10}
							xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Nombre del proyecto
								</LabelStyled>
								<InputStyled
									name="name"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
								/>
							</Form.Group>
						</Col>
						<Col
							lg={2}
							md={2}
							xs={12}>
							<Form.Group>
								<LabelStyled>Divisa</LabelStyled>
								<SelectInputStyled name={"divisa"}>
									<option value="eur">EUR</option>
									<option value="usd">USD</option>
									<option value="ngn">NGN</option>
									<option value="cop">COP</option>
								</SelectInputStyled>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "2em" }}>
						<Col
							lg={10}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Nombre del cliente
								</LabelStyled>
								<ClientFormSelect
									name={"client"}
									onChange={() => {}}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={2}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Divisa</LabelStyled>
								<SelectInputStyled name={"color"}>
									<option value="red">Rojo</option>
									<option value="blue">Azul</option>
									<option value="yellow">Amarillo</option>
								</SelectInputStyled>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "0.5em" }}>
						<Col lg={12}>
							<LabelStyled>
								* Configura la asignacion de empleados dentro
								del proyecto
							</LabelStyled>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col>
							<LabelStyled>Jefe de proyecto</LabelStyled>
							<EmployeeFormSelect
								name="projectBoss"
								onChange={(event: any) => {}}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
						<LabelStyled className="project_input_label">
									Fecha de entrega
								</LabelStyled>
								<Form.Control name="due_date" type="datetime-local"/>
						</Col>
					</Row>
					<Row
						style={{ marginTop: "2em" }}
						className="flex-row-reverse">
						<Col
							lg={2}
							md={2}
							xs={6}>
							<AddButtonStyled
								style={{
									borderRadius: "0.3em",
									padding: ".2em",
								}}>
								Guardar
							</AddButtonStyled>
						</Col>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
