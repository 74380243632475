import { Pause, Play, Plus, Settings } from "react-feather";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { TitleText } from "../login/LoginStyles";
import { Link } from "react-router-dom";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { useEffect } from "react";
import { DeleteNotificationList } from "../../api/NotificationApi";

const notificaciones = [{

}]


export const NotificationListPage = () => {
    const {data,isLoading,error,fetchItems}= useFetchItems(ApiEndpoints.notification)

    useEffect(() => {
        fetchItems();
    },[] )

    const deleteNotifications = () => {
        DeleteNotificationList().then(() => {})
        window.location.reload();
    } 
    return (
		<Container
			fluid
			style={{ padding: "1em" }}>
			<Row>
				<Col
					lg={2}
					md={4}
					sm={4}
					style={{ marginRight: "1em" }}>
                        <InputComponentStyled
						placeholder="Buscar"
						onChange={() => {}}
					/>
                    <AddButtonStyled 
                    onClick={() => deleteNotifications()}
                    style={{marginBottom: "1em",marginTop: "1em"}}>
                        Eliminar notificaciones
                    </AddButtonStyled>
					<AddButtonStyled
                    color="rgba(130, 130, 130, 0.5)"
						onClick={() => {
							
						}}>
						<Link to="/notifications/settings" style={{margin: 0, color: "black",textDecoration: "none"}}><Settings /> Ajuste de notificaciones</Link>
					</AddButtonStyled>
				</Col>
				<Col
					lg={5}
					md={5}
					sm={5}>
					<Row>
                        <Row>
                            <TitleText> Notificaciones </TitleText>
                        </Row>
                        <Row>
                            {data && data.map((element: any,index:number) =>   <Container className="d-flex flex-row justify-content-between" style={{backgroundColor: "white", padding: "1em"}}>
                                <Row>
                                        <img style={{height: "50px",width: "auto", borderRadius: "50%"}} src={element.employee_photo}/>
                                        <Col>
                                        <p style={{margin: 0}}>{element.title}</p>
                                            <p style={{margin: 0}}> {element.message}</p>
                                        </Col>
                                </Row>
                                <p> {element.time}</p>
                            </Container>)}
                          
                           

                        </Row>
                    </Row>
				</Col>
			</Row>
		</Container>
	);
}