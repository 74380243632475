import { Employee } from '../interfaces/employee';
import { apiClient } from './axiosClient';
import { IMessage } from '../interfaces/messages';
import { IRoom } from '../interfaces/rooms';



export const getEmployeeListApi= async (isChat?:boolean) => {
    try{
        const token =localStorage.getItem("token")
        const url = isChat ? `${process.env.REACT_APP_BASE_URL}api/v1/employee?is_chat=True/` : `${process.env.REACT_APP_BASE_URL}api/v1/employee/`
        const request = await apiClient.get(url,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return false;
    }
}


export const getEmployeeMeApi= async () => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/employee/me`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return false;
    }
}


export const getEmployeeApi = async (id: number) => {
    try{
        const token =localStorage.getItem("token")
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/employee/${id}/`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return false;
    }
}

export const createEmployeeApi = async(employee:Employee) => {
    
        try{
            const token =localStorage.getItem("token")
            console.log(token)
            console.log(employee)
            console.log({ 
                headers:{
                    "Authorization": `Bearer ${token}`
            }})
            const request = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/employee/`,{
                method: "POST",
                body: JSON.stringify(employee),
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
            }})
            const response = await request.json()
            return [response];
        
        }
        catch (error){
            
            return [false,error];
        }    
    
}
export const updateEmployeeApi = async(id:string | undefined,employee:Employee) => {
    
    try{
        const token =localStorage.getItem("token")
      
        const request = await apiClient.put(`${process.env.REACT_APP_BASE_URL}api/v1/employee/${id}`,{
            method: "PUT",
            body: JSON.stringify(employee),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
        }})
        if (request.status === 204)
            return true
        return false;
    
    }
    catch (error){
        
        console.log(error)
        return false;
    }    

}

export const deleteEmployeeApi = async(id:string | undefined) => {
    
    try{
        const token = localStorage.getItem("token")
        const request = await apiClient.delete(`${process.env.REACT_APP_BASE_URL}api/v1/employee/${id}/`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return false;
    }    

}


export const changeStatusEmployeeApi = async(status:string) => {
    try{
        
        const token =localStorage.getItem("token")
        const data = {status: status};
        const headers = {"Authorization": `Bearer ${token}`}
        console.log(data)
        const request = await apiClient.put(`${process.env.REACT_APP_BASE_URL}api/v1/status/`,data,{headers: headers})
        return request.data;
    }
    catch (error){
        return false;
    }  
}

export const getMonthDiary = async (monthNumber: number) => {
    try{
        
        const token =localStorage.getItem("token")
    
        const headers = {"Authorization": `Bearer ${token}`}
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/diary?time=month&value=${monthNumber}`,{headers: headers})
        return request.data;
    }
    catch (error){
        return false;
    }  
}

export const getWeekDiary = async () => {
    try{
        
        const token =localStorage.getItem("token")
    
        const headers = {"Authorization": `Bearer ${token}`}
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/diary?time=week`,{headers: headers})
        return request.data;
    }
    catch (error){
        return false;
    }  
}

export const getRoomsApi = async () => {
    try{
        const token =localStorage.getItem("token")
        const headers = {"Authorization": `Bearer ${token}`}
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/rooms/`,{headers: headers})
        return request.data;
    }
    catch (error){
        return false;
    }  
}

export const getRoomApi = async (id:any) => {
    try{
        const token =localStorage.getItem("token")
        const headers = {"Authorization": `Bearer ${token}`}
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/rooms/${id}/`,{headers: headers})
        return request.data;
    }
    catch (error){
        return false;
    }  
}

export const createRoomApi = async(body:IRoom) => {
    
    try{
        const token =localStorage.getItem("token")
        console.log(token)
        console.log(body)
        console.log({ 
            headers:{
                "Authorization": `Bearer ${token}`
        }})
        const request = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/rooms/`,{
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
        }})
        const response = await request.json()
        return response;
    
    }
    catch (error){
        
        return [false,error];
    }    

}


export const deleteRoomApi = async (id: number) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const request = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v1/rooms/${id}`,
        {
          method: "DELETE",
          headers: headers,
        }
      );
      return request;
    } catch (error) {
      return [false, error];
    }
  };

export const getMessagesApi = async (room_id: any) => {
    try{
        
        const token =localStorage.getItem("token")
    
        const headers = {"Authorization": `Bearer ${token}`}
        const request = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/rooms/${room_id}/messages/`,{headers: headers})
        // setState(request.data)
        return request.data;
    }
    catch (error){
        return false;
    }  
}

export const createMessagesApi = async(body:IMessage, room_id:any) => {
    
    try{
        const token =localStorage.getItem("token")
        const request = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/rooms/${room_id}/messages/`,{
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
        }})
        const response = await request.json()
        return response;
    
    }
    catch (error){
        
        return [false,error];
    }    

}