import { HeaderLoginComponent } from "../../components/headerLogin/HeaderLogin";
import {
  GlobalContainer,
  TitleText,
  FormContainer,
  Label,
  InputContainer,
  Input,
  ButtonContainer,
  ButtonText,
} from "./LoginStyles";

export const NewPasswordPage = () => {
  return (
    <>
      <HeaderLoginComponent isNewUserText={true} />
      <GlobalContainer>
        <TitleText>Nueva contraseña</TitleText>
        <FormContainer>
          <InputContainer>
            <Label>Contraseña</Label>
            <Input type="password" placeholder="Contraseña" />
          </InputContainer>
          <InputContainer>
            <Label>Confirmar Contraseña</Label>
            <Input type="password" placeholder="Repite Contraseña" />
          </InputContainer>
          <ButtonContainer>
            <ButtonText>Enviar</ButtonText>
          </ButtonContainer>
        </FormContainer>
      </GlobalContainer>
    </>
  );
};
