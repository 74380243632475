import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./layout/Layout";
import { AccessPage } from "./pages/login/AccessPage";
import { NewPasswordPage } from "./pages/login/NewPasswordpage";
import { DashboardPage } from "./pages/Dashboard";
import { RecoverPasswordPage } from "./pages/login/RecoverPasswordPage";
import { PrivateRoute } from "./privateRoute/PrivateRoute";
import "./App.css";
import { CreateAccountPage } from "./pages/login/CreateAccountPage";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { EmployeeListPage } from "./pages/Employee/EmployeeList";
import { EmployeeCreatePage } from "./pages/Employee/EmployeeCreatePage";
import 'bootstrap/dist/css/bootstrap.min.css';
import { EmployeeDetailPage } from "./pages/Employee/EmployeeDetailPage";
import { EmployeeEditPage } from "./pages/Employee/EmployeeEditPage";
import { ClientListPage } from "./pages/Client/ClientList";
import { ClientCreatePage } from "./pages/Client/ClientCreate";
import { ClientDetailPage } from "./pages/Client/ClientDetailPage";
import { ClientEditPage } from "./pages/Client/ClientEditPage";
import { ProjectListPage } from "./pages/Project/projectListPage";
import { ProjectCreatePage } from "./pages/Project/ProjectCreatePage";
import { ProjectEditPage } from "./pages/Project/ProjectEditPage";
import { ProjectDetailPage } from "./pages/Project/ProjectDetailPage";
import { TaskCreatePage } from "./pages/tasks/taskCreatePage";
import { TaskListPage } from "./pages/tasks/TaskListPage";
import "react-toastify/dist/ReactToastify.css";
import { AttachmentListPage } from "./pages/Attachments/AttachmentListPage";
import { AttachmentCreatePage } from "./pages/Attachments/AttachmentCreatePage";
import { AttachmentDetailPage } from "./pages/Attachments/AttachmentDetailPage";
import { FolderCreatePage } from "./pages/Attachments/FolderCreatePage";
import { FolderListPage } from "./pages/Attachments/FolderListPage";
import { TurnosListPage } from "./pages/Turnos/TurnoListPage";
import { TurnosCreatePage } from "./pages/Turnos/TurnosCreatePage";
import { TaskDetailPage } from "./pages/tasks/taskDetailPage";
import { CompanyEditPage } from "./pages/Company/companyEditPage";
import { CalendarPage } from "./pages/calendar/CalendarPage";
import { TimeSheetListPage } from "./pages/TimeSheet/TimeSheetListPage";
import { UserProfilePage } from "./pages/UserProfile/UserProfilePage";
import { NotificationListPage } from "./pages/Notifications/NotificationsListPage";
import { NotificationSettingsPage } from "./pages/Notifications/NotificationSettingsPage";
import { ChatPage } from "./pages/Chat/ChatPage";
import { FolderDetailPage } from "./pages/Attachments/FolderDetailPage";
import { ChatMobilePage } from "./pages/Chat/ChatMobilePage";
import { HolidayListPage } from "./pages/Holiday/HolidayListPage";
import { HolidayDetailPage } from "./pages/Holiday/HolidayDetailPage";
import { HolidayCreatePage } from "./pages/Holiday/HolidayCreatePage";
import { InputacionEditPage } from "./pages/Inputacion/InputacionEditPage";
import { InputacionCreatePage } from "./pages/Inputacion/InputacionCreatePage";
import { BudgetListPage } from "./pages/Budget/BudgetListPage";
import { ExpenseCreatePage } from "./pages/Budget/ExpenseCreatePage";
import { ExpenseEditPage } from "./pages/Budget/ExpenseEditPage";
import { BudgetDetailPage } from "./pages/Budget/BudgetDetailPage";
import { BudgetCreatePage } from "./pages/Budget/BudgetCreatePage";

export const isLoggedIn = (): boolean => {
  const authData = localStorage.getItem("token");
  return !!authData;
};

function App() {
return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<AccessPage />} />
        <Route path="/new-password" element={<NewPasswordPage />} />
        <Route path="/recover-password" element={<RecoverPasswordPage />} />
        <Route path="/create-account" element={<CreateAccountPage />} />

        <Route element={<PrivateRoute  />}>
          <Route element={<Layout />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/employee" element={<EmployeeListPage />}/>
            <Route path="/employee/:id" element={<EmployeeDetailPage />}/>
            <Route path="/employee/create" element={<EmployeeCreatePage />} />
            <Route path="/employee/edit/:id" element={<EmployeeEditPage/>}/>
            <Route path="/client" element={<ClientListPage/>}/>
            <Route path="/client/create" element={<ClientCreatePage/>}/>
            <Route path="/client/:id" element={<ClientDetailPage/>}/>
            <Route path="/client/edit/:id" element={<ClientEditPage/>}/>
            <Route path="/project/" element={<ProjectListPage />} />
            <Route path="/project/:id" element={<ProjectDetailPage />} />
            <Route path="/project/create" element={<ProjectCreatePage />} />
            <Route path="/project/edit/:id" element={<ProjectEditPage />} />
            <Route path="/task/create" element={<TaskCreatePage/>}/>  
            <Route path="/task" element={<TaskListPage/>}/>
            <Route path="/task/:id" element={<TaskDetailPage/>}/>
            <Route path="/attachment" element={<AttachmentListPage/>}/>
            <Route path="/attachment/create" element={<AttachmentCreatePage/>}/>
            <Route path="/attachment/folder/:id/create/" element={<AttachmentCreatePage/>}/>
            <Route path="/attachment/folder/create" element={<FolderCreatePage/>}/>
            <Route path="/attachment/folder/:id" element={<FolderListPage/>}/>
            <Route path="/attachment/folder/:id/edit" element={<FolderDetailPage/>}/>
            <Route path="/attachment/:id" element={<AttachmentDetailPage/>}/>
            <Route path="/turnos" element={<TurnosListPage/>}/>
            <Route path="/turnos/create" element={<TurnosCreatePage/>}/>
            <Route path="/company" element={<CompanyEditPage/>}/>
            <Route path="/calendar" element={<CalendarPage/>}/>
            <Route path="/inputacion/create" element={<InputacionCreatePage/>}/>
            <Route path="/inputacion/:id" element={<InputacionEditPage/>}/>
            <Route path="/fichajes" element={<TimeSheetListPage/>}/>
            <Route path="/profile" element={<UserProfilePage/>}/>
            <Route path="/notifications" element={<NotificationListPage/>}/>
            <Route path="/notifications/settings" element={<NotificationSettingsPage/>}/>
            <Route path="/chat" element={<ChatPage/>}/>
            <Route path="/chat/:id" element={<ChatMobilePage/>}/>
            <Route path="/holiday/" element={<HolidayListPage/>}/>
            <Route path="/holiday/:id" element={<HolidayDetailPage/>}/>
            <Route path="/holiday/create" element={<HolidayCreatePage />} />
            <Route path="/budget" element={<BudgetListPage />} />
            <Route path="/budget/create" element={<BudgetCreatePage />} />
            <Route path="/budget/:id" element={<BudgetDetailPage />} />
            <Route path="/budget/create/:id" element={<ExpenseCreatePage />} />
            <Route path="/budget/edit/:id" element={<ExpenseEditPage />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
