import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { useNavigate } from "react-router-dom";
import { ProjectFormSelectComponent } from "../../components/formComponents/projectFormSelect";
import { EmployeeFormSelect } from "../../components/formComponents/employeeFormSelect";
import { toast } from "react-toastify";
import { useCreateItem } from "../../hooks/useCreateItems";
import { ApiEndpoints } from "../../constants/urls";
import CreatableSelect from "react-select/creatable";
import { useState } from "react";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";

export const TaskCreatePage = () => {
	const [employee, setEmployee] = useState([]);
	const navigate = useNavigate();
	const { data, isLoading, error, createItem } = useCreateItem(
		`${ApiEndpoints.task}/`
	);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const handleEtiquetasChanges = (selectedOptions: any) => {
		setSelectedOptions(selectedOptions);
	};
	const createTaskHandler = async (
		event: React.ChangeEvent<HTMLFormElement>
	) => {
		event.preventDefault();
		if (event.target.taskName.value === "") {
			toast.error("Es obligatorio rellenar el nombre de la tarea");
			return;
		}
		let data: any = [];
		selectedOptions.forEach((item: any) => data.push(item.value));
		await createItem({
			name: event.target.taskName.value,
			project: event.target.project.value,
			employee: employee,
			labels: JSON.stringify(data),
			notes: event.target.notes.value,
			status: event.target.status.value,
			due_date: event.target.due_date.value,
		});
		if (error){
			toast.error(error.toString());
		}
		else {
			window.location.href = "/task";
		}
	};

	return (
		<Container
			fluid
			style={{ padding: "1em" }}>
			<TitleText>Añadir nueva tarea</TitleText>
			<CardComponent>
				<form onSubmit={createTaskHandler}>
					<Row>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Nombre de la tarea *
								</LabelStyled>
								<InputStyled
									name="taskName"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Etiquetas</LabelStyled>
								<CreatableSelect
									isMulti // Permite seleccionar múltiples opciones
									onChange={handleEtiquetasChanges}
									value={selectedOptions}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "2em" }}>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Asignar proyecto
								</LabelStyled>
								<ProjectFormSelectComponent
									name={"project"}
									onChange={() => {}}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Empleado</LabelStyled>
								<EmployeeFormSelect
									multi={true}
									name="employee"
									onChange={(value: any) => {
										let ids: any = [];
										value.forEach((val: any) =>
											ids.push(val.value)
										);
										setEmployee(ids);
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "2em" }}>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Notas
								</LabelStyled>
								<Form.Control
									as="textarea"
									rows={3}
									name="notes"></Form.Control>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Estado de la tarea
								</LabelStyled>
								<SelectInputStyled name="status">
									<option value="new">Nueva</option>
									<option value="running">
										En Ejecución
									</option>
									<option value="end">Finalizada</option>
								</SelectInputStyled>
								<LabelStyled className="project_input_label">
									Fecha de entrega
								</LabelStyled>
								<Form.Control
									name="due_date"
									type="datetime-local"
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row
						style={{ marginTop: "2em" }}
						className="flex-row-reverse">
						<Col
							lg={2}
							md={2}
							xs={6}>
							<AddButtonStyled
								style={{
									borderRadius: "0.3em",
									padding: ".2em",
								}}>
								Guardar
							</AddButtonStyled>
						</Col>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
