import axios from 'axios';
import { ClientInterface } from '../interfaces/client';
import { apiClient } from './axiosClient';

export const createClientApi = async (clientData: any) => {
    try{
        const token =localStorage.getItem("token")
        const createClient = await apiClient.post(`${process.env.REACT_APP_BASE_URL}api/v1/client/`,
        clientData,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return createClient.data;
    }
    catch (error){
        return false;
    }
}

export const getClientListApi = async () => {
    try{
        const token =localStorage.getItem("token")
        const getClientList = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/client/`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return getClientList.data;
    }
    catch (error){
        console.log(error)
        
        return false;
    }
}

export const getClientApi = async (id:string) => {
    try{
        const token =localStorage.getItem("token")
        const getClientList = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/client/${id}/`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
    
        return getClientList.data;
    }
    catch (error){
        return false;
    }
}


export const updateClientApi = async (id:string,clientData: ClientInterface) => {
    try{
        const token = localStorage.getItem("token")
      
        const getClientList = await apiClient.put(`${process.env.REACT_APP_BASE_URL}api/v1/client/${id}/`,clientData,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
    
        return getClientList.data;
    }
    catch (error){
        return false;
    }
}

export const deleteClientApi = async (id:string|undefined) => {
    try{
        const token =localStorage.getItem("token")
      
        const getClientList = await apiClient.delete(`${process.env.REACT_APP_BASE_URL}api/v1/client/${id}/`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
    
        return true;
    }
    catch (error){
        return false;
    }
}

export const getTasksForClient = async(client_id:string) => {
    try{
        const token =localStorage.getItem("token")
        const getClientTaskList = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/client/${client_id}/tasks`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        console.log("???")
        console.log(getClientTaskList.data)
        return getClientTaskList.data;
    }
    catch (error){
        return false;
    }
}

export const getTimeForClient = async(client_id:string) => {
    try{
        const token =localStorage.getItem("token")
        const getClientTime = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/client/${client_id}/time`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return getClientTime.data;
    }
    catch (error){
        return false;
    }
}