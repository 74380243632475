import { ToastContainer, toast } from "react-toastify";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { HeaderLoginComponent } from "../../components/headerLogin/HeaderLogin";
import {
  GlobalContainer,
  TitleText,
  FormContainer,
  Label,
  InputContainer,
  Input,
  CustomLinkText,
  AsteriskText,
  RowContainer,
} from "./LoginStyles";
import Switch from "@mui/material/Switch";
import { FormEvent, useRef, useState } from "react";
import { createUser } from "../../api/UserApiHandler";
import { useNavigate } from "react-router-dom";

export const CreateAccountPage = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate()
  const createAccountHandler = (event: any) => {
    event.preventDefault()
    let valid = true;
    if (event.target.name.value === ""){
      toast.error("Es necesario rellenar el nombre del usuario")
      valid = false
    }
    if (event.target.email.value === ""){
      toast.error("Es necesario rellenar el correo electrónico")
      valid = false
    }
    if (event.target.password.value === ""){
      toast.error("Es necesario rellenar la contraseña del usuario")
      valid = false
    }
    if (!checked ){
      toast.error("Es necesario aceptar la política de privacidad")
      valid = false
    }
    if (!valid)
      return  
    createUser({
      name: event.target.name.value,
      password:event.target.password.value,
      email: event.target.email.value,
      mobile: event.target.mobile.value

    }).then((user) => {
      console.log(user)
      if (!user) 
        toast.error("Error al crear el usuario")
      else
        navigate("/login")
    })
  };
  return (
    <>
      <ToastContainer/>
      <HeaderLoginComponent isNewUserText={true} />
      <GlobalContainer>
        <TitleText>Crear una cuenta</TitleText>
        <FormContainer onSubmit={createAccountHandler}>
          <InputContainer>
            <Label>
              Nombre completo <AsteriskText>*</AsteriskText>
            </Label>
            <Input type="text" name="name" placeholder="Escribe tu nombre y apellidos" />
          </InputContainer>
          <InputContainer>
            <Label>Teléfono</Label>
            <Input type="tel"  name="mobile" placeholder="Escribe tu número de teléfono" />
          </InputContainer>
          <InputContainer>
            <Label>Email</Label>
            <Input type="email" name="email" placeholder="Introduce tu correo electrónico" />
          </InputContainer>
          <InputContainer>
            <Label>Contraseña</Label>
            <Input type="password" name="password" placeholder="Contraseña" />
          </InputContainer>
          <RowContainer>
            <Switch checked={checked} onChange={() => {setChecked((prev) => !prev)} } />
            <Label style={{ margin: "0" }}>
              Acepto la{" "}
              <CustomLinkText to={"/"}>Política de privacidad</CustomLinkText>
            </Label>
          </RowContainer>
          <div style={{display: "flex",justifyContent: "end"}}>
            <SelectButtonStyled style={{width: "30%"}} active={true}>
              Guardar
            </SelectButtonStyled>
          </div>
        </FormContainer>

       
      </GlobalContainer>
    </>
  );
};
