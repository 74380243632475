import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { ArrowLeft, Plus } from "react-feather";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { useEffect, useState } from "react";
import {
	EmployeeTableName,
	EmployeeTableText,
} from "../../components/EmployeeList/employeeTableStyled";
import Modal from "react-bootstrap/Modal";
import {
	assignEmployeeToProjects,
	deleteProjectApi,
} from "../../api/ProjectApiHandler";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { Employee } from "../../interfaces/employee";
import FullCalendar from "@fullcalendar/react";
import { CalendarOptions, CalendarApi } from "@fullcalendar/core";

import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";

export const ProjectDetailPage = () => {
	const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false);
	const { id } = useParams();
	const [employeeList, setEmployeeList] = useState([]);
	const [newProjectemployees, setNewProjectEmployees] = useState<Number[]>(
		[]
	);
	const [showCard, setShowCard] = useState<string>("employee");
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.project,
		parseInt(id!)
	);
	const {
		data: employeeData,
		isLoading: employeeLoading,
		error: employeeError,
		fetchItems: fethEmployee,
	} = useFetchItems(ApiEndpoints.employee);
	const {
		data: projectCalendarData,
		isLoading: rojectCalendarLoading,
		error: rojectCalendarError,
		fetchItems: fetchProjectCalendar,
	} = useFetchItems(
		`${process.env.REACT_APP_BASE_URL}api/v1/project/${id}/calendar`
	);

	const navigate = useNavigate();
	const MySwal = withReactContent(Swal);

	useEffect(() => {
		fethEmployee();
		fetchItems();
		fetchProjectCalendar();
	}, []);

	const processAddNewEmployeesToProject = () => {
		assignEmployeeToProjects(id!, newProjectemployees).then(
			(newEmployee: any) => {
				setEmployeeList(newEmployee);
			}
		);
	};
	const updateEmployeeLists = (event: any) => {
		if (newProjectemployees.includes(parseInt(event.target.id))) {
			let newEmployees = newProjectemployees.filter(
				(el) => el != parseInt(event.target.id)
			);
			setNewProjectEmployees(newEmployees);
		} else
			setNewProjectEmployees([
				parseInt(event.target.id),
				...newProjectemployees,
			]);
	};
	useEffect(() => {
		if (data) {
			let employeeIds: Number[] = [];
			data.employee?.forEach((employee: Employee) =>
				employeeIds.push(employee.id!)
			);
			setNewProjectEmployees(employeeIds);
			setEmployeeList(data!.employee);
		}
	}, [data]);
	console.log(data);
	return (
		<>
			<Container style={{ justifyContent: "start" }}>
				{data && (
					<Col>
						<Row style={{ marginTop: "1em" }}>
							<Col style={{ textAlign: "center" }}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}>
									<div style={{ display: "flex" }}>
										<button
											onClick={() => {
												navigate("/project");
											}}
											style={{
												backgroundColor:
													"rgba(48, 100, 217, 0.2)",
												borderRadius: ".5em",
												borderColor: "transparent",
												paddingLeft: ".6em",
												paddingRight: ".6em",
											}}>
											<ArrowLeft color="rgba(48, 100, 217, 1)" />
										</button>
										<p
											style={{
												marginBottom: 0,
												fontSize: "1.4rem",
												fontWeight: 800,
												fontFamily: "Roboto",
												lineHeight: "1.6 rem",
												color: "rgba(48, 100, 217, 1)",
												marginLeft: ".5em",
											}}>
											Proyectos
										</p>
									</div>
									<div>
										<SelectButtonStyled
											active={true}
											onClick={() =>
												navigate(`/project/edit/${id}`)
											}>
											Editar
										</SelectButtonStyled>
										<SelectButtonStyled
											active={false}
											onClick={() => {
												MySwal.fire({
													title: "¿Estas seguro de eliminar el cliente? ",
													showCancelButton: true,
													confirmButtonText:
														"Eliminar",
													cancelButtonText: `No eliminar`,
													confirmButtonColor: "red",
												}).then((result: any) => {
													if (result.isConfirmed) {
														deleteProjectApi(
															id!.toString()
														);
														navigate("/project");
													}
												});
											}}>
											Eliminar
										</SelectButtonStyled>
									</div>{" "}
								</div>
							</Col>
						</Row>
						<Row style={{ marginTop: "2em" }}>
							<Col className="text-center">
								<div
									style={{
										display: "flex",
										textAlign: "center",
									}}>
									<img
										style={{
											width: "100px",
											height: "auto",
										}}
										className="rounded-circle"
									/>
									<h1
										style={{
											fontSize: "42px",
											fontWeight: 500,
											fontFamily: "Roboto",
											marginTop: "1em",
											marginLeft: "1em",
										}}>
										{data.name}
									</h1>
								</div>
							</Col>
						</Row>
						<Row
							style={{
								width: "50%",
								marginTop: "1em",
							}}>
							<Col lg={3}>
								<p
									style={{
										margin: 0,
										fontSize: "0.8rem",
										fontFamily: "Roboto",
										fontWeight: 400,
									}}>
									Fecha de inicio
								</p>
								<p
									style={{
										fontWeight: 700,
										fontSize: "1.4rem",
										fontFamily: "Roboto",
										color: "rgba(48, 100, 217, 1.5)",
									}}>
									{data.start_date}
								</p>
							</Col>
							<Col lg={4}>
								<p
									style={{
										margin: 0,
										fontSize: "0.8rem",
										fontFamily: "Roboto",
										fontWeight: 400,
									}}>
									Jefe de proyecto
								</p>
								<p
									style={{
										fontWeight: 700,
										fontSize: "1.4rem",
										fontFamily: "Roboto",
										color: "#3064D9",
									}}>
									{data.admin_user}
								</p>
							</Col>
							<Col lg={4}>
								<p
									style={{
										margin: 0,
										fontSize: "0.8rem",
										fontFamily: "Roboto",
										fontWeight: 400,
									}}>
									Cliente
								</p>
								<p
									style={{
										fontWeight: 700,
										fontSize: "1.4rem",
										fontFamily: "Roboto",
										color: "rgba(48, 100, 217, 1)",
									}}>
									{data.client!}
								</p>
							</Col>
						</Row>
						<Row
							style={{
								width: "20%",
							}}>
							<Col lg={6}>
								<p
									style={{
										margin: 0,
										fontSize: "0.8rem",
										fontFamily: "Roboto",
										fontWeight: 400,
									}}>
									Empleados
								</p>
								<p
									style={{
										fontWeight: 700,
										fontSize: "1.4rem",
										fontFamily: "Roboto",
										color: "rgba(48, 100, 217, 1)",
									}}>
									{data.employee!.length}
								</p>
							</Col>
							<Col lg={6}>
								<p
									style={{
										margin: 0,
										fontSize: "0.8rem",
										fontFamily: "Roboto",
										fontWeight: 400,
									}}>
									Tiempo
								</p>
								<p
									style={{
										fontWeight: 700,
										fontSize: "1.4rem",
										fontFamily: "Roboto",
										color: "rgba(48, 100, 217, 1)",
									}}>
									{data.total_time}
								</p>
							</Col>
						</Row>
						<Row
							style={{
								backgroundColor: "rgba(48, 100, 217, 0.1)",
							}}>
							<Col className="text-center">
								<SelectButtonStyled
									active={showCard === "employee"}>
									<p
										style={{ margin: 0 }}
										onClick={() => setShowCard("employee")}>
										Empleados
									</p>
								</SelectButtonStyled>
								<SelectButtonStyled
									active={showCard === "time"}>
									{" "}
									<p
										style={{ margin: 0 }}
										onClick={() => setShowCard("time")}>
										Tiempo
									</p>
								</SelectButtonStyled>
							</Col>
						</Row>
						{showCard === "employee" ? (
							<Row style={{ marginTop: "1em" }}>
								<Container
									style={{
										backgroundColor: "white",
										paddingTop: "1em",
										borderRadius: ".3em",
										height: "100%",
									}}>
									<Row
										style={{
											height: "2em",
											textAlign: "start",
											padding: " .5em 1em 1em 1em",
										}}>
										<Col lg={3}>
											<EmployeeTableName>
												Empleado
											</EmployeeTableName>
										</Col>
										<Col lg={3}>
											<EmployeeTableText>
												Estado
											</EmployeeTableText>
										</Col>
										<Col lg={3}>
											<EmployeeTableText>
												Horario
											</EmployeeTableText>
										</Col>
										<Col lg={3}>
											<EmployeeTableText>
												Horas Hoy
											</EmployeeTableText>
										</Col>
									</Row>
									<hr
										style={{
											color: "rgba(149, 187, 232, 0.6)",
											borderWidth: "2px",
											marginTop: 0,
											marginBottom: 0,
										}}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											padding: "1em",
											fontWeight: 700,
											fontSize: "1rem",
											fontFamily: "Roboto",
											color: "rgba(48, 100, 217, 1)",
										}}
										onClick={(event) =>
											setOpenAddEmployeeModal(true)
										}>
										<p style={{ marginBottom: 0 }}>
											{" "}
											<Plus /> Agregar Empleado{" "}
										</p>
										<hr
											style={{
												color: "rgba(149, 187, 232, 0.6)",
												borderWidth: "2px",
												marginTop: "1em",
												marginBottom: 0,
											}}
										/>
									</div>
									{employeeList.map((employee: Employee) => (
										<div
											key={employee.id}
											onClick={() => {
												navigate(
													`/employee/${employee.id}`
												);
											}}>
											<Row
												style={{
													height: "3em",
													textAlign: "start",
													padding: "2em",
												}}>
												<Col lg={3}>
													<EmployeeTableName>
														{employee.name}{" "}
														{employee.last_name}
													</EmployeeTableName>
												</Col>

												<Col lg={3}>
													<EmployeeTableText>
														{employee.status}
													</EmployeeTableText>
												</Col>
												<Col lg={3}>
													<EmployeeTableText>
														{
															employee.work_timesheet
														}
													</EmployeeTableText>
												</Col>
												<Col lg={3}>
													<EmployeeTableText>
														{employee.today_time}
													</EmployeeTableText>
												</Col>
											</Row>
											<hr
												style={{
													color: "rgba(149, 187, 232, 0.6)",
													borderWidth: "2px",
													marginTop: "1em",
													marginBottom: 0,
												}}
											/>
										</div>
									))}
								</Container>
							</Row>
						) : (
							<Container>
								<FullCalendar
									contentHeight={"auto"}
									plugins={[timeGridPlugin, dayGridPlugin]}
									initialView="timeGridWeek"
									weekends={true}
									slotDuration={"00:30:00"}
									slotLabelInterval={"00:30:00"}
									slotMinTime={"07:00:00"}
									slotMaxTime={"22:00:00"}
									dayHeaderFormat={{
										weekday: "short",
										day: "numeric",
									}}
									dayHeaderClassNames={"headerCalendar"}
									allDaySlot={false}
									aspectRatio={0}
									headerToolbar={{
										start: "", // Puedes dejar esto vacío o ajustar según sea necesario
										center: "title",
										end: "prev,next timeGridWeek",
									}}
									events={projectCalendarData}
									slotEventOverlap={false}
									views={{
										dayGridMonth: {
											// Personaliza el dayHeaderFormat solo para la vista de mes
											dayHeaderFormat: {
												weekday: "short",
												omitCommas: true,
											},
										},
									}}
								/>
							</Container>
						)}
					</Col>
				)}
			</Container>
			<Modal
				centered
				show={openAddEmployeeModal}
				onHide={() => {
					setOpenAddEmployeeModal(false);
				}}
				contentLabel="Selecciona los empleados que quieres agregar">
				<Modal.Header>
					<Modal.Title>Agregar empleado</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{data &&
						employeeData &&
						employeeData!.map(
							(employee: Employee, index: number) => {
								let employeeInsideList =
									newProjectemployees.filter(
										(el) => el === employee.id
									);
								return (
									<div key={index}>
										<Row
											key={index}
											style={{
												height: "3em",
												textAlign: "start",
												padding: "2em",
											}}>
											<Col
												lg={2}
												md={2}
												xs={2}>
												{
													<Form.Check
														onChange={
															updateEmployeeLists
														}
														id={employee.id!.toString()}
														checked={newProjectemployees.includes(
															employee.id!
														)}
													/>
												}
											</Col>

											<Col
												lg={10}
												md={10}
												xs={10}>
												<EmployeeTableName>
													{employee.name}{" "}
													{employee.last_name}
												</EmployeeTableName>
											</Col>
										</Row>
										<hr
											style={{
												color: "rgba(149, 187, 232, 0.6)",
												borderWidth: "2px",
												marginTop: "1em",
												marginBottom: 0,
											}}
										/>
									</div>
								);
							}
						)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							window.location.reload();
						}}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							processAddNewEmployeesToProject();
							window.location.reload();
						}}>
						Agregar Empleados
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
