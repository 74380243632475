import { Plus } from "react-feather";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import {
  EmployeeTableName,
} from "../../components/EmployeeList/employeeTableStyled";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { ExpenseListInterface } from "../../interfaces/expenseList";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { TitleText } from "../login/LoginStyles";
import { getProjectApi } from "../../api/ProjectApiHandler";
import { isEmpty } from "../../utility/isEmpty";

export const BudgetDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');

  useMemo(async () => {
    if (id !== undefined) {
      const data: any = await getProjectApi(id);
      if (isEmpty(data)) navigate(-1);
      setProjectName(data.name);
    }
  }, [id]);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);

  const { data, isLoading, error, fetchItems } = useFetchItems(
    ApiEndpoints.budgetDetail,
    parseInt(id!)
  );

  useEffect(() => {
    fetchItems();
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [expenseList, setExpenseList] = useState<ExpenseListInterface[]>([]);

  const totalExpense = useMemo(() => {
    return expenseList.reduce((sum, expense: ExpenseListInterface) => sum + parseFloat(expense.amount), 0).toFixed(2);
  }, [expenseList]);

  const filterTableHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.value.toLowerCase();
    if (filter === "") setExpenseList(data);
    else {
      const filteredList = data.filter((expense: ExpenseListInterface) =>
        expense.name.toLowerCase().includes(filter)
      );
      setExpenseList(filteredList);
    }
  };

  useEffect(() => {
    if (data === null) {
      fetchItems();
    } else if (data !== null && expenseList.length === 0) {
      setExpenseList(data);
    }
  }, [data]);

  if (isLoading) return <p>Loading...</p>;
  if (error) navigate(-1);

  return (
    <Container fluid style={{ padding: "1em" }}>
      {isMobileView && <TitleText> Presupuestos </TitleText>}
      <Row>
        <Col lg={2} md={12} sm={12} style={{ marginRight: "1em" }}>
          <AddButtonStyled
            onClick={() => {
              navigate("/budget/create");
            }}
          >
            <Plus /> Add Budget
          </AddButtonStyled>
          <InputComponentStyled
            placeholder="buscar gastos"
            onChange={filterTableHandler}
          />
        </Col>
        <Col lg={9} md={12} sm={12}>
          {!isMobileView && (
            <TitleText> {`Proyectos > ${projectName} > Gastos`} </TitleText>
          )}
          <Container
            fluid
            style={{
              backgroundColor: "white",
              borderRadius: ".3em",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <Row
              style={{
                height: "3em",
                textAlign: "start",
                padding: " 1.5em 1em 1em 1em",
                backgroundColor: "lightgrey",
                borderTopRightRadius: ".3em",
                borderTopLeftRadius: ".3em",
              }}
            >
              <Col xs={4}>
                <EmployeeTableName>Expense Name</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>Amount</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>Concept</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>Invoice</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>Action</EmployeeTableName>
              </Col>
            </Row>
            {expenseList &&
              expenseList.map((expense: ExpenseListInterface) => (
                <div key={expense.id}>
                  <Row
                    style={{
                      height: "3em",
                      textAlign: "start",
                      padding: "2em 1.5em 2em 1.5em",
                    }}
                  >
                    <Col xs={4}>
                      <EmployeeTableName>{expense.name}</EmployeeTableName>
                    </Col>
                    <Col xs={2}>
                      <EmployeeTableName>{expense.amount}</EmployeeTableName>
                    </Col>
                    <Col xs={2}>
                      <EmployeeTableName>{expense.concept}</EmployeeTableName>
                    </Col>
                    <Col xs={2}>
                      <EmployeeTableName>{expense?.invoice}</EmployeeTableName>
                    </Col>
                    <Col
                      xs={2}
                      onClick={() => {
                        navigate(`/budget/edit/${expense.id}`);
                      }}
                    >
                      <EmployeeTableName>Edit</EmployeeTableName>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      color: "rgba(149, 187, 232, 0.6)",
                      borderWidth: "2px",
                      marginTop: "1em",
                      marginBottom: 0,
                    }}
                  />
                </div>
              ))}
            <div key={expenseList.length + 1}>
              <Row
                style={{
                  height: "5em",
                  textAlign: "start",
                  backgroundColor: "papayawhip",
                  padding: "2em 1em 2em 1em",
                  borderBottomRightRadius: ".3em",
                  borderBottomLeftRadius: ".3em",
                }}
              >
                <Col xs={4}>
                  <EmployeeTableName>Total Expenses</EmployeeTableName>
                </Col>
                <Col xs={2}>
                  <EmployeeTableName>{parseFloat(totalExpense).toFixed(2)}</EmployeeTableName>
                </Col>
                <Col xs={2}>
                </Col>
                <Col xs={2}>
                </Col>
                <Col xs={2}>
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
