import { File, Folder, Plus } from "react-feather";
import {
	AddButtonStyled,
	FolderButtonStyled,
} from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TitleText } from "../login/LoginStyles";
import { EmployeeTableName, EmployeeTableText } from "../../components/EmployeeList/employeeTableStyled";
import { useNavigate, useParams } from "react-router-dom";
import { AttachmentInterface } from "../../interfaces/attachment";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { useEffect } from "react";
import useDeleteItem from "../../hooks/useDeleteItem";
import { toast } from "react-toastify";

export const FolderListPage = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const MySwal = withReactContent(Swal);
	const {data,isLoading,error,fetchItems} = useFetchItems(ApiEndpoints.folder,parseInt(id!))
	const {deleteData,deleteLoading,deleteError,deleteItem} = useDeleteItem(`${ApiEndpoints.folder}/${parseInt(id!)}`);

	useEffect(() => {
		fetchItems()
	},[])
	console.log(data)
	return (
		<Container
			fluid
			style={{ padding: "1em" }}>
			<Row>
				<Col
					lg={2}
					md={4}
					sm={4}
					style={{ marginRight: "1em" }}>
					<AddButtonStyled
						onClick={() => {
							navigate(`/attachment/folder/${id}/create`);
						}}>
						<Plus /> Nuevo archivo
					</AddButtonStyled>
					<div style={{ marginTop: "1em" }}>
						<FolderButtonStyled
							onClick={() => {
								navigate(`/attachment/folder/${id}/create`);
							}}>
							<Folder /> Crear carpeta
						</FolderButtonStyled>
					</div>
				</Col>
				<Col
					lg={9}
					md={8}
					sm={8}>
					<div style={{display: "flex",justifyContent: "space-between"}}>
					
							{data && (
								<TitleText>
									{" "}
									Adjuntos /{data[0]!.name}{" "}
								</TitleText>
							)}
						
							<div>
								<SelectButtonStyled
									active={true}
									onClick={() =>
										navigate(`/attachment/folder/${id}/edit`)
									}>
									Editar
								</SelectButtonStyled>
								<SelectButtonStyled
									active={false}
									onClick={() => {
										MySwal.fire({
											title: "¿Estas seguro de eliminar la carpeta? ",
											text: "Eliminar la carpeta eliminará todos los adjuntos que contiene",
											showCancelButton: true,
											confirmButtonText: "Eliminar",
											cancelButtonText: `No eliminar`,
											confirmButtonColor: "red",
										}).then(async (result) => {
											if (result.isConfirmed) {
												await deleteItem()
												if (deleteError)
													toast.error("Error eliminando el adjunto")
												else
													navigate("/attachment");
											}
										});
									}}>
									Eliminar
								</SelectButtonStyled>
							</div>
					</div>
					<Container
						fluid
						style={{
							backgroundColor: "white",
							paddingTop: "1em",
							borderRadius: ".3em",
							height: "100%",
						}}>
						<Row
							style={{
								height: "2em",
								textAlign: "start",
								padding: " .5em 1em 1em 1em",
							}}>
							<Col lg={3}>
								<EmployeeTableName>Nombre</EmployeeTableName>
							</Col>
							<Col lg={5}>
								<EmployeeTableText>
									Última modificación
								</EmployeeTableText>
							</Col>
							<Col lg={4}>
								<EmployeeTableText>Autor</EmployeeTableText>
							</Col>
						</Row>
						<hr
							style={{
								color: "rgba(149, 187, 232, 0.6)",
								borderWidth: "2px",
								marginTop: 0,
								marginBottom: 0,
							}}
						/>
						{data &&
							data[0]!.attachments.map(
								(attachment: AttachmentInterface, index: number) => (
									<Row
										style={{
											height: "2em",
											textAlign: "start",
											padding: " 2em",
										}}
										onClick={(event) =>
											navigate(
												`/attachment/${attachment.id!}`
											)
										}>
										<Col lg={3}>
											<div
												style={{
													display: "flex",
													justifyContent: "start",
													textAlign: "center",
												}}>
												{attachment.type === "file" ? (
													<File
														style={{
															marginRight: "1em",
														}}
														color="red"
													/>
												) : (
													<Folder
														style={{
															marginRight: "1em",
														}}
													/>
												)}
												<EmployeeTableName>{attachment.name}</EmployeeTableName>
											</div>
										</Col>
										<Col lg={5}>
											<EmployeeTableText>
												{attachment.modified_date.toString()}
											</EmployeeTableText>
										</Col>
										<Col lg={4}>
											<EmployeeTableText>{attachment.author}</EmployeeTableText>
										</Col>
									</Row>
								)
							)}
					</Container>
				</Col>
			</Row>
		</Container>
	);
};
