import { Plus } from "react-feather";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { SwitcherStyled } from "../../components/switchComponent/switchComponentStyled";
import { TitleText } from "../login/LoginStyles";
import { Employee } from "../../interfaces/employee";
import {
	EmployeeTableName,
	EmployeeTableText,
} from "../../components/EmployeeList/employeeTableStyled";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { UserImg } from "../../components/topBar/TopBarStyles";

export const EmployeeListPage = () => {
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.employee
	);
	const navigate = useNavigate();
	const [employeeList, setEmployeeList] = useState([]);
	const [isFilteredAll, setIsFilteredAll] = useState(true);
	const [isFilteredAdmin, setIsFilteredAdmin] = useState(false);
	const [isFilteredEncargados, setIsFilteredEncargados] = useState(false);
	const [isFilteredEmpleados, setIsFilteredEmpleados] = useState(false);
	const [isFilteredGestores, setIsFilteredGestores] = useState(false);
	const [isFilteredGerentes, setIsFilteredGerentes] = useState(false);

	const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);

	const handlerFiltereAll = () => {
		setIsFilteredAll(true);
		setIsFilteredAdmin(false);
		setIsFilteredEmpleados(false);
		setIsFilteredGestores(false);
		setIsFilteredEncargados(false);
		setIsFilteredGerentes(false);
		setEmployeeList(data);
	};

	const handlerFilteredEncargados = () => {
    setIsFilteredAll(false);

		setIsFilteredAdmin(false);
		setIsFilteredEmpleados(false);
		setIsFilteredGestores(false);
		setIsFilteredEncargados(true);
		setIsFilteredGerentes(false);
    setEmployeeList(
      data.filter(
        (employee: Employee) => employee.position === "encargado"
      )
    );
	};
	const handlerFilteredEmpleados = () => {
    setIsFilteredAll(false);

		setIsFilteredAdmin(false);
		setIsFilteredEmpleados(true);
		setIsFilteredGestores(false);
		setIsFilteredEncargados(false);
		setIsFilteredGerentes(false);
    setEmployeeList(
      data.filter(
        (employee: Employee) => employee.position === "empleado"
      )
    );
	};
	const handlerFilteredGestores = () => {
    setIsFilteredAll(false);

		setIsFilteredAdmin(false);
		setIsFilteredEmpleados(false);
		setIsFilteredGestores(true);
		setIsFilteredEncargados(false);
		setIsFilteredGerentes(false);
    setEmployeeList(
      data.filter(
        (employee: Employee) => employee.position === "gestor"
      )
    );
	};
	const handlerFilteredGerentes = () => {
    setIsFilteredAll(false);

		setIsFilteredAdmin(false);
		setIsFilteredEmpleados(false);
		setIsFilteredGestores(false);
		setIsFilteredEncargados(false);
		setIsFilteredGerentes(true);
    setEmployeeList(
      data.filter(
        (employee: Employee) => employee.position === "gerente"
      )
    );
	};

	const filterEmployeeHandler = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const filter = event.target.value;
		if (filter === "") setEmployeeList(data);
		const employees = data.filter((employee: Employee) => {
			let fullName = employee.name + employee.last_name;
			if (fullName.includes(filter)) return employee;
		});
		setEmployeeList(employees);
	};

	useEffect(() => {
		if (data === null) {
			fetchItems();
		} else if (data !== null && employeeList.length === 0) {
			setEmployeeList(data);
		}
	}, [data]);
	useEffect(() => {
		fetchItems();
	}, [fetchItems]);


	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 991);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Container
			fluid
			style={{
				padding: "1em",
				paddingRight: isMobileView ? "1rem" : "0",
			}}>
			<Row>
				<Col
					lg={2}
					md={4}
					sm={4}
					style={{
						marginRight: "1em",
						width: isMobileView ? "100%" : "20%",
					}}>
					{isMobileView && <TitleText> Empleados </TitleText>}
					<AddButtonStyled
						onClick={() => {
							navigate("/employee/create");
						}}>
						<Plus /> Añadir empleado
					</AddButtonStyled>
					<InputComponentStyled
						placeholder="Buscar empleado"
						onChange={filterEmployeeHandler}
					/>
					<Container
						className="marginTopForm"
						style={{
							display: isMobileView ? "none" : "block",
						}}>
						<EmployeeTableText
							style={{ marginBottom: ".5em", marginTop: "1em" }}>
							Role
						</EmployeeTableText>
						<SwitcherStyled
							type="switch"
							label="Mostrar todos los empleados"
							id="all"
							onChange={handlerFiltereAll}
							checked={isFilteredAll}
						/>

						<SwitcherStyled
							type="switch"
							label="Encargados"
							id="admin"
							onChange={handlerFilteredEncargados}
							checked={isFilteredEncargados}
						/>
						<SwitcherStyled
							type="switch"
							label="Empleados"
							id="admin"
							onChange={handlerFilteredEmpleados}
							checked={isFilteredEmpleados}
						/>
						<SwitcherStyled
							type="switch"
							label="Gestores"
							id="admin"
							onChange={handlerFilteredGestores}
							checked={isFilteredGestores}
						/>
						<SwitcherStyled
							type="switch"
							label="Gerentes"
							id="admin"
							onChange={handlerFilteredGerentes}
							checked={isFilteredGerentes}
						/>
					</Container>
				</Col>
				<Col
					lg={9}
					md={12}
					sm={12}>
					{!isMobileView && <TitleText> Empleados </TitleText>}
					<Container
						style={{
							backgroundColor: "white",
							paddingTop: "1em",
							borderRadius: ".3em",
							height: "100%",
							marginTop: isMobileView ? "1em" : "0",
						}}>
						<Row
							style={{
								height: "2em",
								textAlign: "start",
								padding: " .5em 1em 1em 1em",
								width: "auto",
							}}>
							<Col lg={4}>
								<EmployeeTableName>Empleado</EmployeeTableName>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>Estado</EmployeeTableText>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>Horario</EmployeeTableText>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>Horas Hoy</EmployeeTableText>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>Email</EmployeeTableText>
							</Col>
						</Row>
						<hr
							style={{
								color: "rgba(149, 187, 232, 0.6)",
								borderWidth: "2px",
								marginTop: 0,
								marginBottom: 0,
							}}
						/>
						{employeeList.map(
							(employee: Employee, index: number) => (
								<div
									onClick={() => {
										navigate(`/employee/${employee.id}`);
									}}>
									<Row
										key={index}
										style={{
											height: "3em",
											textAlign: "start",
											padding: "2em",
										}}>
										<Col lg={4}>
											<div
												style={{
													display: "flex",
												}}>
												<UserImg
													src={`${
														process.env
															.REACT_APP_BASE_URL
													}${employee.photo!}`}
													alt="user profile"
													style={{
														position: "relative",
														bottom: "15px",
														width: "40px",
														height: "40px",
														marginRight: "10px",
													}}
												/>

												<EmployeeTableName>
													{employee.name}{" "}
													{employee.last_name}
												</EmployeeTableName>
											</div>
										</Col>

										<Col lg={2}>
											<EmployeeTableText>
												{employee.status}
											</EmployeeTableText>
										</Col>
										<Col lg={2}>
											<EmployeeTableText>
												{employee.work_timesheet}
											</EmployeeTableText>
										</Col>
										<Col lg={2}>
											<EmployeeTableText>
												{employee.today_time}
											</EmployeeTableText>
										</Col>
										<Col lg={2}>
											<EmployeeTableText>
												{employee.email}
											</EmployeeTableText>
										</Col>
									</Row>
									<hr
										style={{
											color: "rgba(149, 187, 232, 0.6)",
											borderWidth: "2px",
											marginTop: "1em",
											marginBottom: 0,
										}}
									/>
								</div>
							)
						)}
					</Container>
				</Col>
			</Row>
		</Container>
	);
};
