import { useState } from "react";
import { CreateHolidayApi } from "../../api/HolidayApiHandler";
import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { createClientApi } from "../../api/ClientApiHandler";
import { useNavigate } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { toast } from "react-toastify";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";

export const HolidayCreatePage = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const createHolidayHandler = async (event: any) => {
    event.preventDefault();
    if (
      event.target.startDate.value == "" ||
      event.target.endDate.value == ""
    ) {
      toast.error("Es necesario rellenar la fecha de comienzo y final");
      return;
    }
    await CreateHolidayApi({
      name: name,
      start_time: event.target.startDate.value,
      end_time: event.target.endDate.value,
      color: event.target.color.value
    });
    navigate("/holiday");
  };
  return (
    <Container style={{ padding: "1em" }}>
      <TitleText>Nuevo turno de vacaciones</TitleText>
      <CardComponent>
        <form onSubmit={createHolidayHandler}>
          <Row>
            <Col lg={6} md={12} xs={12}>
              <Form.Group>
                <LabelStyled>Nombre</LabelStyled>
                <InputStyled
                  name="name"
                  className="project_input_field"
                  type="text"
                  placeholder="Nombre"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={12} xs={12}>
              <Form.Group>
                <LabelStyled>Color</LabelStyled>
                <SelectInputStyled
                  name={"color"}
                >
                  <option value="red">Rojo</option>
                  <option value="blue">Azul</option>
                  <option value="yellow">Amarillo</option>
                </SelectInputStyled>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Form.Group>
                <LabelStyled>Inicio de las vacaciones</LabelStyled>
                <InputStyled
                  name="startDate"
                  className="project_input_field"
                  type="date"
                  placeholder="Fecha de inicio"
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Form.Group>
                <LabelStyled>Fin de las vacaciones</LabelStyled>
                <InputStyled
                  name="endDate"
                  className="project_input_field"
                  type="date"
                  placeholder="Fecha de inicio"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: "1em" }} className="flex-row-reverse">
            <AddButtonStyled
              style={{
                width: "25%",
                borderRadius: "0.3em",
                padding: ".2em",
              }}
            >
              Crear Vacaciones
            </AddButtonStyled>
          </Row>
        </form>
      </CardComponent>
    </Container>
  );
};
