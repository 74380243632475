import axios from 'axios';
import { apiClient } from './axiosClient';


export const createTimerApi = async (task:number) => {
	try {
        const token =  localStorage.getItem("token")
		const request = await apiClient.post(
			`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/timer/start`,
			{
				start_time: `${new Date().getFullYear()}-${
					new Date().getMonth() + 1
				}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`,
				task: task
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		if (request.status != 201)
			return false
		return request.data
	} catch (error) {
		return false;
	}
};


export const stopCurrentRecord = async() => {
    try {
        const token =  localStorage.getItem("token")
		const request = await apiClient.put(
			`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/timer/stop`,{
				end_time: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}`,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return request.data;
	} catch (error) {
		return false;
	}
}

export const getCurrentTimer = async() => {
    try {
        const token =  localStorage.getItem("token")
		const request = await apiClient.get(
			`${process.env.REACT_APP_BASE_URL}api/v1/time-sheets/timer`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return request.data;
	} catch (error) {
		return false;
	}
}
