import { FC, useState, useEffect } from "react";
import { useMe } from "../../hooks/useMe";
import xIcon from "../../assets/images/x.svg";
import {
  Container,
  NavLinkContainer,
  ImgIcon,
  CustomLink,
} from "./LeftBarStyles";
import { useNavigate } from "react-router-dom";
import { X } from "react-feather";

interface LeftBarComponentProps {
  setLeftBar: any;
}

export const LeftBarComponent: FC<LeftBarComponentProps> = ({ setLeftBar }) => {
  const dashboardRoute = "/";
  const employeesRoute = "/employee/";
  const navigate = useNavigate();
  const [user, setUser] = useState<any>({ permissions: [] });
  const userInfo = useMe();
  useEffect(() => {
    if (userInfo.permissions) setUser(userInfo);
  }, [userInfo]);
  const checkPermission = (routeName: string) => user.permissions[routeName];
  return (
    <Container style={{zIndex: 99}}>
      <NavLinkContainer>
        <X
          color="white"
          onClick={(prevState) => setLeftBar(!prevState)}
        />
        <CustomLink onClick={() => setLeftBar(false)} to={"/"}>Dashboard</CustomLink>
        {checkPermission("clients") ? (<CustomLink onClick={() => setLeftBar(false)} to={"/client"}>Clientes</CustomLink>) : (<></>)}
        {checkPermission("projects") ? (<CustomLink onClick={() => setLeftBar(false)} to={"/project"}>Proyectos</CustomLink>) : (<></>)}
        {checkPermission("tasks") ? (<CustomLink onClick={() => setLeftBar(false)} to={"/task"}>Tareas</CustomLink>) : (<></>)}
        {checkPermission("attachments") ? (<CustomLink onClick={() => setLeftBar(false)} to={"/attachment"}>Adjuntos</CustomLink>) : (<></>)}
        {checkPermission("budgets") ? (<CustomLink onClick={() => setLeftBar(false)} to={"/budget"}>Gastos</CustomLink>) : (<></>)}
        {checkPermission("employees") ? (<CustomLink onClick={() => setLeftBar(false) } to={"/employee"}>Empleados</CustomLink>) : (<></>)}
        <CustomLink onClick={() => setLeftBar(false)} to={"/holiday"}>Vacaciones</CustomLink>
        {checkPermission("turnos") ? (<CustomLink onClick={() => setLeftBar(false)} to={"/turnos"}>Turnos</CustomLink>) : (<></>)}
        <CustomLink onClick={() => setLeftBar(false)} to={"/calendar"}>Horarios</CustomLink>
        {checkPermission("turnos") ? (<CustomLink onClick={() => setLeftBar(false)} to={"/fichajes"}>Fichajes</CustomLink>) : (<></>)}

      </NavLinkContainer>
    </Container>
  );
};
