import { FC } from "react";
import {Container, TopRigthText, CustomLink} from "./HeaderLoginStyle";
import logo from '../../assets/images/logo.png';

interface HeaderLoginProps {
  isNewUserText: boolean;
}

export const HeaderLoginComponent: FC<HeaderLoginProps> = ({ isNewUserText }) => {
  return (
    <Container style={{width: "80%"}}>
      <img src={logo} alt="TikNEO Logo" />

      <TopRigthText>
        {isNewUserText ? "¿Ya tienes una cuenta?" : "¿Nuevo en TikNEO?"}
        {" "}
        <CustomLink to={isNewUserText ? "/access" : "/create-account"}>
          <span >{isNewUserText ? "Acceder" : "Crear una cuenta"}</span>
        </CustomLink>
      </TopRigthText>
    </Container>
  );
};
