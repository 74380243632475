import { createUserInterface } from '../interfaces/createUserInterface';
import { apiClient } from './axiosClient';


export const APILoginHandler = async (email: string,password: string) => {
    
    try {
        const login = await apiClient.post(`${process.env.REACT_APP_BASE_URL}token/`,{
            email: email,
            password: password
        })
        if (login.status === 200)
            return login.data
        return false
    }catch (error){
        return false
    }

}

export const GetUserInfoHandler = async () => {
    try {
        const token =  localStorage.getItem("token")
        const login = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/employee/me`,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (login.status === 200){
            return login.data
        }
        return false
    }catch (error){
        return false
    }

}
export const getTotalTimeWorkedToday = async () => {
    try {
        const token =  localStorage.getItem("token")
        const login = await apiClient.get(`${process.env.REACT_APP_BASE_URL}api/v1/time/total`,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (login.status === 200){
            return login.data
        }
        return false
    }catch (error){
        return false
    }

}

export const UpdateUserInfoHandler = async (userInfo: object) => {
    try {
        const token =localStorage.getItem("token")
        const request = await apiClient.put(`${process.env.REACT_APP_BASE_URL}api/v1/employee/me`,userInfo,{
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        if (request.status === 204){
            return true
        }
        return false
    }catch (error){
        return false
    }

}



export const resetPasswordEmail = async(email: string) => {
    try {
        const request = await apiClient.post(`${process.env.REACT_APP_BASE_URL}api/v1/employee/reset-password/`,{
           email: email
        })
        if (request.status === 201){
            return request.data
        }
        else if (request.status === 404){
            return false
        }
        return false
    }catch (error){
        return false
    } 
}

export const createUser = async (user: createUserInterface) => {

    try {
        const request = await apiClient.post(`${process.env.REACT_APP_BASE_URL}api/v1/employee/register/`,user)
        if (request.status === 201){
            return request.data
        }
        else if (request.status === 404){
            return false
        }
        return false
    }catch (error){
        return false
    } 

}