import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { deleteClientApi } from "../../api/ClientApiHandler";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";

export const ClientDetailPage = () => {
	const [weekLabels, setWeekLabel] = useState<string[]>([]);
	const [weekData, setWeekData] = useState<any>([]);
	const [monthLabel, setMonthLabel] = useState([]);
	const [monthData, setMonthData] = useState([]);
	const { id } = useParams();

	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.client,
		parseInt(id!)
	);
	const navigate = useNavigate();
	const MySwal = withReactContent(Swal);

	useEffect(() => {
		let weekLabel: any = [];
		let weekValues: any = [];
		let monthLabel: any = [];
		let monthValues: any = [];
		const token = localStorage.getItem("token");
		axios
			.get(`${process.env.REACT_APP_BASE_URL}api/v1/client/${id}/time`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((getClientTime) => {
				let clientTime = getClientTime.data;
				if (clientTime.week_records.length > 0) {
					clientTime.week_records.forEach((value: any) => {
						weekLabel.push(value.day);
						weekValues.push(value.time);
					});
					setWeekLabel(weekLabel);
					setWeekData(weekValues);
				}
				if (clientTime.month_records.length > 0) {
					clientTime.month_records.forEach((value: any) => {
						monthLabel.push(value.day.split(" ")[0]);
						monthValues.push(value.time);
					});
					setMonthData(monthValues);
					setMonthLabel(monthLabel);
				}
			});
	}, [data]);
	useEffect(() => {
		fetchItems();
	}, []);

	ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

	return (
		<>
			<Container style={{ justifyContent: "start" }}>
				{data && (
					<Col>
						<Row style={{ marginTop: "1em" }}>
							<Col style={{ textAlign: "center" }}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}>
									<div style={{ display: "flex" }}>
										<button
											onClick={() => {
												navigate("/client");
											}}
											style={{
												backgroundColor:
													"rgba(48, 100, 217, 0.2)",
												borderRadius: ".5em",
												borderColor: "transparent",
												paddingLeft: ".6em",
												paddingRight: ".6em",
											}}>
											<ArrowLeft color="rgba(48, 100, 217, 1)" />
										</button>
										<p
											style={{
												marginBottom: 0,
												fontSize: "1.4rem",
												fontWeight: 800,
												fontFamily: "Roboto",
												lineHeight: "1.6 rem",
												color: "rgba(48, 100, 217, 1)",
												marginLeft: ".5em",
											}}>
											Clientes
										</p>
									</div>
									<div>
										<SelectButtonStyled
											active={true}
											onClick={() =>
												navigate(`/client/edit/${id}`)
											}>
											Editar
										</SelectButtonStyled>
										<SelectButtonStyled
											active={false}
											onClick={() => {
												MySwal.fire({
													title: "¿Estas seguro de eliminar el cliente? ",
													showCancelButton: true,
													confirmButtonText:
														"Eliminar",
													cancelButtonText: `No eliminar`,
													confirmButtonColor: "red",
												}).then((result) => {
													if (result.isConfirmed) {
														deleteClientApi(id);
														navigate("/client");
													}
												});
											}}>
											Eliminar
										</SelectButtonStyled>
									</div>{" "}
								</div>
							</Col>
						</Row>
						<Row style={{ marginTop: "2em" }}>
							<Col className="text-center">
								<div style={{ display: "flex" }}>
									<img
										style={{
											width: "100px",
											height: "auto",
										}}
										className="rounded-circle"
										alt="avatar1"
										src="https://mdbcdn.b-cdn.net/img/new/avatars/9.webp"
									/>
									<div
										style={{
											textAlign: "start",
											marginLeft: "1em",
											alignContent: "start",
										}}>
										<h1
											style={{
												fontSize: "36px",
												fontWeight: 500,
												fontFamily: "Roboto",
												margin: 0,
											}}>
											{data.name}
										</h1>
										<p
											style={{
												fontSize: ".8rem",
												fontWeight: 500,
												fontFamily: "Roboto",
												marginLeft: "1em",
												margin: 0,
											}}>
											E-mail: {data.email}{" "}
										</p>
										<p
											style={{
												fontSize: "0.8rem",
												fontWeight: 500,
												fontFamily: "Roboto",
											}}>
											Tel: {data.phone_number}{" "}
										</p>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col lg={8}>
								<Row
									style={{
										width: "40%",
										marginTop: "1em",
									}}>
									<Col lg={6}>
										<p
											style={{
												margin: 0,
												fontSize: "0.8rem",
												fontFamily: "Roboto",
												fontWeight: 400,
											}}>
											Hoy
										</p>
										<p
											style={{
												fontWeight: 700,
												fontSize: "1.4rem",
												fontFamily: "Roboto",
												color: "rgba(0, 0, 0, 0.5)",
											}}>
											{data.client_total_time}
										</p>
									</Col>
									<Col lg={6}>
										<p
											style={{
												margin: 0,
												fontSize: "0.8rem",
												fontFamily: "Roboto",
												fontWeight: 400,
											}}>
											30 días
										</p>
										<p
											style={{
												fontWeight: 700,
												fontSize: "1.4rem",
												fontFamily: "Roboto",
											}}></p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col>
								<h4>Últimos 7 días</h4>

								<Bar
									options={{
										responsive: true,
										plugins: {
											legend: {
												display: false,
											},
										},
									}}
									data={{
										labels: weekLabels,
										datasets: [
											{
												data: weekData,
												backgroundColor:
													"rgba(255, 99, 132, 0.5)",
											},
										],
									}}
								/>
							</Col>
						</Row>
						<Row>
							<h4>Últimos 30 días</h4>
							<Col>
								<Bar
									options={{
										responsive: true,
										plugins: {
											legend: {
												display: false,
											},
										},
									}}
									data={{
										labels: monthLabel,
										datasets: [
											{
												data: monthData,
												backgroundColor:
													"rgba(255, 99, 132, 0.5)",
											},
										],
									}}
								/>
							</Col>
						</Row>
					</Col>
				)}
			</Container>
		</>
	);
};
