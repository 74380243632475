import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { TEXT } from "../../constants/text";
import { Link } from "react-router-dom";

export const GlobalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
`;

export const TitleText = styled.p`
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.6rem;
    color: rgba(48, 100, 217, 1);
    margin-bottom: 1em;
    font-family: 'Roboto';
    margin-top: 0.5em;
`;

export const SubTitleText = styled.p`
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6rem;
    color: rgba(48, 100, 217, 1);
    margin-bottom: 1em;
    font-family: 'Roboto';
    margin-top: 0;
`;

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: ${COLORS.primaryWhite};
    box-shadow: ${COLORS.boxShadow};
    padding: 25px;
    border-radius: 6px;
    min-width: 300px;
    max-width: 400px;
    position: relative;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    bottom: 15px;
    right: 10px;
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

export const Label = styled.label`
    font-size: ${TEXT.textSizeUsual};
    line-height: ${TEXT.lineHigh};
    color: ${COLORS.textColorBlue};
    margin-bottom: 10px;
    font-weight: ${TEXT.textWeightsUsual};
`;

export const Input = styled.input`
    border-radius: 4px;
    border: 0.5px solid #ccc;
    font-size: ${TEXT.textSizeUsual};
    line-height: ${TEXT.lineHigh};
    color: ${COLORS.textColorBlue};
    padding: 8px;
    `;

export const ButtonContainer = styled.button`
    width: 84px;
    display: flex;
    margin-left: auto;
    background: ${COLORS.primaryBlue};
    poadding: "1em";
`;

export const ButtonText = styled.p`
    width: 100%;
    height: 33px;
    border-radius: 4px;
    color: ${COLORS.primaryWhite};
    font-size: ${TEXT.textSizeUsual};
    line-height: ${TEXT.lineHigh};
    font-weight: ${TEXT.textWeightsUsual};
    font-family: "Roboto";    
    border: none;
    cursor: pointer;
    margin: auto;
`;

export const TextContainer = styled.div`
    margin-top: 20px;
`;

export const Text = styled.span`
    font-size: ${TEXT.textSizeUsual};
    line-height: ${TEXT.lineHigh};
    color: ${COLORS.textColorBlue};
    font-weight: ${TEXT.textWeightsUsual};
    margin-top: 10px;
`;

export const CustomLinkText = styled(Link)`
    font-size: ${TEXT.textSizeUsual};
    line-height: ${TEXT.lineHigh};
    color: ${COLORS.textColorBlue};
    font-weight: ${TEXT.textWeightsUsual};
    text-decoration: none;
    text-decoration: underline;
`;

export const AsteriskText = styled.span`
    color: ${COLORS.orangeAsterisk};
    margin-left: 5px;
`;