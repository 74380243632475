import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { toast } from "react-toastify";
import { EmployeeFormSelect } from "../../components/formComponents/employeeFormSelect";
import { useEffect, useState } from "react";
import { useCreateItem } from "../../hooks/useCreateItems";
import { ApiEndpoints } from "../../constants/urls";
import { useFetchItems } from "../../hooks/useFetchItems";
export const FolderCreatePage = () => {
	const navigate = useNavigate();
	const { data, isLoading, error, createItem } = useCreateItem(
		ApiEndpoints.attachment
	);
	const {
		data: employeeData,
		isLoading: isLoadingEmployee,
		error: employeeError,
		fetchItems: employeeFetch,
	} = useFetchItems(ApiEndpoints.employee);
	useEffect(() => {

		employeeFetch();
	}, []);

	const createFolderHandler = async (event: any) => {
		event.preventDefault();
		await createItem({
			name: event.target.name.value,
			folderOwner: event.target.folderOwner.value,
		});
		if (error) toast.error(error.toString());
		else {
			toast.info("Adjunto creado correctamente");
			navigate("/attachment");
		}
	};
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Nueva Carpeta</TitleText>
			<CardComponent>
				<form onSubmit={createFolderHandler}>
					<Row>
						<Col
							lg={12}
							md={12}
							xs={12}>
							<Form.Group>
								<LabelStyled>Nombre *</LabelStyled>
								<InputStyled
									name="name"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						{employeeData &&  (
							<Col
								lg={12}
								md={12}
								xs={12}>
								<LabelStyled>
									Propietario de la carpeta
								</LabelStyled>
							
								<EmployeeFormSelect
									name="folderOwner"
									onChange={() => {}}
								/>
							</Col>
						)}
					</Row>
					<Row
						style={{ marginTop: "2em" }}
						className="flex-row-reverse">
						<Col lg={2} md={2} xs={6}>
						<AddButtonStyled
							style={{
								
								borderRadius: "0.3em",
								padding: ".2em",
							}}>
							Guardar
						</AddButtonStyled>
						</Col>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
