import axios from "axios";
import { Notification } from "../utility/Notification";

export const createExpenseApi = async (expenseData: any) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/expense`,
      expenseData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  } catch (error: any) {
    Notification("error", error.response.data);
    return false;
  }
};

export const getExpenseApi = async (id: number) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/expense/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error: any) {
    Notification("error", error.response.data);
    return false;
  }
};

export const updateExpenseApi = async (id: number, data: any) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/expense/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  } catch (error: any) {
    Notification("error", error.response.data);
    return false;
  }
};

export const deleteExpenseApi = async (id: string) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/expense/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status !== 200) return null;
    return res.data;
  } catch (error) {
    return null;
  }
};
