import { Col, Form, Row, Toast } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { useNavigate } from "react-router-dom";
import { GetComapnyApi, UpdateCompanyApi } from "../../api/CompanyApi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import "./company.css";
export const CompanyEditPage = () => {
	const navigate = useNavigate();
	const [company, setCompany] = useState<any>(null);
	useEffect(() => {
		GetComapnyApi().then((data) => setCompany(data));
	}, []);

	const updateCompanyData = (event: any) => {
		event.preventDefault();
		const { name, nif, address, zip_code, city, country } = event.target;
		const fields = [
			{ value: name.value, name: "Nomnbre" },
			{ value: nif.value, name: "CIF" },
			{ value: address.value, name: "Dirección" },
			{ value: zip_code.value, name: "Código Postal" },
			{ value: city.value, name: "Ciudad" },
			{ value: country.value, name: "Pais" },
		];
		let valid = true;
		// Verificación de cada campo
		for (let { value, name } of fields) {
			if (value.trim() === "") {
				toast.error(`Es necesario rellenar el campo ${name}`);
				valid = false;
			}
		}
		if (!valid) return;

		const companyRequest = UpdateCompanyApi({
			id: company.id,
			name: event.target.name.value,
			nif: event.target.nif.value,
			address: event.target.address.value,
			zip_code: event.target.zip_code.value,
			city: event.target.city.value,
			country: event.target.country.value,
		}).then((data: any) => {
			navigate("/");
		});
	};
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Configuración de empresa</TitleText>
			<CardComponent>
				{company && (
					<Container>
						<form onSubmit={updateCompanyData}>
							<Row>
								<Col
									lg={6}
									md={6}
									xs={12}>
									<Form.Group>
										<LabelStyled className="project_input_label">
											Nombre de la empresa *
										</LabelStyled>
										<InputStyled
											name="name"
											className="project_input_field"
											type="text"
											placeholder="Nombre"
											value={company.name || ""}
											onChange={(event) =>
												setCompany({
													...company,
													name: event.target.value,
												})
											}
										/>
									</Form.Group>
								</Col>
								<Col
									lg={6}
									md={6}
									xs={12}>
									<Form.Group className="marginTopForm">
										<LabelStyled>NIF</LabelStyled>
										<InputStyled
											name="nif"
											className="project_input_field"
											type="text"
											placeholder="NIF"
											value={company.nif || ""}
											onChange={(event) =>
												setCompany({
													...company,
													nif: event.target.value,
												})
											}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: "1em" }}>
								<Col
									lg={12}
									md={12}
									xs={12}>
									<Form.Group>
										<LabelStyled>Dirección</LabelStyled>
										<InputStyled
											name="address"
											className="project_input_field"
											type="text"
											placeholder="Dirección"
											value={company.address || ""}
											onChange={(event) =>
												setCompany({
													...company,
													address: event.target.value,
												})
											}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row style={{ marginTop: "1em" }}>
								<Col
									lg={4}
									md={12}
									xs={12}>
									<Form.Group>
										<LabelStyled>Código postal</LabelStyled>
										<InputStyled
											name="zip_code"
											className="project_input_field"
											type="text"
											placeholder="Código Postal"
											value={company.zip_code || ""}
											onChange={(event) =>
												setCompany({
													...company,
													zip_code:
														event.target.value,
												})
											}
										/>
									</Form.Group>
								</Col>
								<Col
									lg={4}
									md={4}
									xs={12}>
									<Form.Group className="marginTopForm">
										<LabelStyled className="project_input_label">
											Población
										</LabelStyled>
										<InputStyled
											name="city"
											className="project_input_field margin-top"
											type="text"
											placeholder="Población"
											value={company.city || ""}
											onChange={(event) =>
												setCompany({
													...company,
													city: event.target.value,
												})
											}
										/>
									</Form.Group>
								</Col>
								<Col
									lg={4}
									md={12}
									xs={12}>
									<Form.Group className="marginTopForm">
										<LabelStyled>País</LabelStyled>
										<InputStyled
											name="country"
											className="project_input_field"
											type="text"
											placeholder="País"
											value={company.country || ""}
											onChange={(event) =>
												setCompany({
													...company,
													country: event.target.value,
												})
											}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row
								className="justify-content-end"
								style={{
									marginTop: "1em",
								}}>
								<Col lg={2} md={6} xs={6}>
									<SelectButtonStyled
										active={true}
										>
										<p style={{ margin: 0 }}>Guardar</p>
									</SelectButtonStyled>
								</Col>
							</Row>
						</form>
					</Container>
				)}
			</CardComponent>
		</Container>
	);
};
