import styled from "styled-components";

export const CardComponent = styled.div`
	border-radius: 0.5em;
	padding: 2em;
	background-color: white;
`;

export const CardDashboardComponent = styled.div`
	border-radius: 0.5em;
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	background-color: white;
	margin-bottom: 1em;
`;
