import { ArrowLeft, ArrowRight, Circle, Plus, Trash } from "react-feather";
import {
	AddButtonStyled,
	TurnosButtonSelectStyled,
} from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { SubTitleText, TitleText } from "../login/LoginStyles";
import {
	EmployeeTableName,
	EmployeeTableText,
} from "../../components/EmployeeList/employeeTableStyled";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
	deleteTurnoApi,
	getTurnosApi,
	updateTurnoPlanApi,
} from "../../api/TurnosApi";
import { FormCheck, Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Employee } from "../../interfaces/employee";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { useUpdateItem } from "../../hooks/useUpdateItem";
import { toast } from "react-toastify";

export const TurnosListPage = () => {
	const navigate = useNavigate();
	const [turnos, setTurnos] = useState<any>(null);
	const [turnosTime, setTurnosTime] = useState<string>("week");
	const [assignTurnos, setAssignTurnos] = useState<any>([]);
	const [openEmployeeModal, setShowEmployeeModals] = useState(false);
	/* -- Edit modal */
	const [openEditModal, setOpenEditModal] = useState(false);
	const [editEmployeeTurnosCode, setEditEmployeeTurnosCode] = useState("");
	const {
		data: TurnosEmployee,
		isLoading: LoadingTurnosEmployee,
		error: ErrorTurnosEmployee,
		fetchItems: FetchTurnosEmployee,
	} = useFetchItems(ApiEndpoints.turnosEmployee);
	const { updateData, updateLoading, updateError, updateItem } =
		useUpdateItem(ApiEndpoints.turnosEmployee);

	/* -- Turno Code */
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.employee
	);
	const [update, setUpdate] = useState(false);
	const [employees, setEmployees] = useState<any>([]);
	const [deleteEmployees, setDeleteEmployees] = useState<any>([]);
	const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);

	useEffect(() => {
		const handleResize = () => {
			setIsMobileView(window.innerWidth <= 991);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		fetchItems();
	}, []);
	useEffect(() => {
		fetchItems();
	}, [update]);
	useEffect(() => {
		// Llamamos a la api
		getTurnosApi(turnosTime).then((data) => {
			setTurnos(data);
		});
	}, [turnosTime]);
	const clickTurnoHandler = (event: any) => {
		if (assignTurnos.includes(event.target.id)) {
			event.target.checked = false;
			setAssignTurnos(
				assignTurnos.filter((el: string) => el != event.target.id)
			);
		} else {
			setAssignTurnos([
				...new Set<any>([`${event.target.id}`, ...assignTurnos]),
			]);
			event.target.checked = true;
		}
	};
	const checkAllTurnosSelected = (event: any) => {
		let days: string[] = [];
		if (assignTurnos.length != 0) {
			setAssignTurnos([]);
			return;
		}
		document
			.querySelectorAll(`input[id*='${event.target.id}']`)
			.forEach((dayCheck: any) => {
				console.log(dayCheck.id);
				days.push(dayCheck.id);
			});
		setAssignTurnos([...assignTurnos, ...days]);
	};

	const NextTimeBlock = (event: any) => {
		/* Consultamos el siguiente bloque de tiempo, siguiente dia, semana o mes */
		getTurnosApi(turnosTime, "next", turnos.data[turnos.days[0]].date).then(
			(data) => {
				setTurnos(data);
			}
		);
	};
	const PrevTimeBlock = (event: any) => {
		/* Consultamos el siguiente bloque de tiempo, siguiente dia, semana o mes */
		getTurnosApi(turnosTime, "prev", turnos.data[turnos.days[0]].date).then(
			(data) => {
				setTurnos(data);
			}
		);
	};

	const deleteTurno = (event: any) => {
		deleteTurnoApi(event.target.id).then((data) => {
			if (data) {
				window.location.reload();
			}else{
				toast.error("Error eliminando el turno")
			}
		});
	};
	console.log("!!")
	console.log(openEmployeeModal)
	return (
		<Container
			fluid
			style={{ padding: "1em" }}>
			{isMobileView && <TitleText> Turnos </TitleText>}
			<Row>
				<Col
					lg={3}
					md={12}
					sm={12}
					style={{ marginRight: "1em" }}>
					<AddButtonStyled
						onClick={() => {
							navigate("/turnos/create");
						}}>
						<Plus /> Añadir turno
					</AddButtonStyled>
					<InputComponentStyled
						placeholder="Buscar turno"
						onChange={() => {}}
					/>
					<Container
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "1em",
							backgroundColor: "white",
							padding: "1em",
						}}>
						<Col
							lg={3}
							className="align-center">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<TurnosButtonSelectStyled
									onClick={(event) => setTurnosTime("month")}>
									Mes
								</TurnosButtonSelectStyled>
							</div>
						</Col>
						<Col
							lg={3}
							className="align-center">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<TurnosButtonSelectStyled
									onClick={(event) => setTurnosTime("week")}>
									Semana
								</TurnosButtonSelectStyled>
							</div>
						</Col>

						<Col
							lg={3}
							className="align-center">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<TurnosButtonSelectStyled
									onClick={(event) => setTurnosTime("day")}>
									Día
								</TurnosButtonSelectStyled>
							</div>
						</Col>
					</Container>
					<Container
						style={{
							display: "flex",
							justifyContent: "space-around",
							marginTop: "1em",
							backgroundColor: "white",
							padding: "1em",
						}}>
						<Col lg={2}>
							<TurnosButtonSelectStyled onClick={PrevTimeBlock}>
								<ArrowLeft />
							</TurnosButtonSelectStyled>
						</Col>
						<Col lg={4}>
							<TurnosButtonSelectStyled>
								{turnos && turnos.days[0]} /{" "}
								{turnos && turnos.days[turnos.days.length - 1]}
							</TurnosButtonSelectStyled>
						</Col>
						<Col lg={2}>
							<TurnosButtonSelectStyled onClick={NextTimeBlock}>
								<ArrowRight />
							</TurnosButtonSelectStyled>
						</Col>
					</Container>
				</Col>
				<Col
					lg={8}
					md={8}
					sm={8}>
					<Row>
						<Col lg={8}>
							{!isMobileView && (
								<TitleText style={{ marginBottom: 0 }}>
									{" "}
									Turnos
								</TitleText>
							)}
							<SubTitleText
								style={{
									marginTop: isMobileView ? "1em" : "0",
								}}>
								Tabla de disponibilidad
							</SubTitleText>
						</Col>
						<Col
							lg={4}
							className="align-center">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}>
								<AddButtonStyled
									onClick={() => {
										setShowEmployeeModals(true);
									}}>
									Editar Empleados Asignados
								</AddButtonStyled>
							</div>
						</Col>
					</Row>
					<Container
						fluid
						style={{
							backgroundColor: "white",
							paddingTop: "1em",
							borderRadius: ".3em",
							height: "100%",
							overflow: "scroll",
						}}>
						<Row
							style={{
								height: "2em",
								textAlign: "start",
								padding: " .5em 1em 1em 1em",
							}}>
							<Col lg={3}>
								<EmployeeTableText>Turno</EmployeeTableText>
							</Col>
							<Col lg={9}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}>
									{turnos &&
										turnos.days.map(
											(el: any, index: number) => (
												<p key={el}>
													{el.toUpperCase()}
												</p>
											)
										)}
								</div>
							</Col>
						</Row>
						<hr
							style={{
								color: "rgba(149, 187, 232, 0.6)",
								borderWidth: "2px",
								marginTop: ".3em",
								marginBottom: 0,
							}}
						/>
						{turnos &&
							Object.keys(turnos.turnos).map(
								(turnoName: any, index: number) => {
									return (
										<Row
											style={{
												height: "2em",
												textAlign: "start",
												padding: " 2em 1em 2em 1em",
												marginTop: "2em",
											}}>
											<Col
												lg={3}
												md={12}
												xs={12}>
												<EmployeeTableName>
													<Circle
														style={{
															color: "purple",
															marginRight: ".5em",
														}}
													/>
													{turnoName}
												</EmployeeTableName>
												<Row>
													<Col lg={10}>
														<button
															id={turnoName}
															className="btn"
															style={{
																display:
																	"inline",
																borderColor:
																	"blue",
																marginTop:
																	".5em",
															}}
															onClick={
																checkAllTurnosSelected
															}>
															Seleccionar días
														</button>
														<button
															id={`${turnos.turnos[turnoName]}`}
															className="btn"
															style={{
																display:
																	"inline",
																borderColor:
																	"blue",
																marginTop:
																	".5em",
																marginLeft:
																	"1em",
																backgroundColor:
																	"transparent",
															}}
															onClick={
																deleteTurno
															}>
															<Trash
																id={`${turnos.turnos[turnoName]}`}
																onClick={
																	deleteTurno
																}
																size={"20px"}
															/>
														</button>
													</Col>
												</Row>
											</Col>
											<Col
												lg={9}
												md={12}
												xs={12}
												key={index}>
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
													}}>
													{turnos != null &&
														turnos.days.map(
															(
																el: any,
																index: number
															) => {
																return turnos
																	.data[el]
																	.tasks[
																	turnoName
																] > 0 ? (
																	<div
																		id={
																			turnoName
																		}
																		key={
																			index
																		}
																		onClick={() => {
																			FetchTurnosEmployee(
																				encodeURI(
																					`turno_code=${turnos.data[el].date}-${turnoName}`
																				)
																			).then(
																				() => {
																					setOpenEditModal(
																						true
																					);
																					setEditEmployeeTurnosCode(
																						`${turnos.data[el].date}-${turnoName}`
																					);
																				}
																			);
																		}}>
																		<FormCheck
																			checked={assignTurnos.includes(
																				`${turnos.data[el].date}-${turnoName}`
																			)}
																			onClick={
																				clickTurnoHandler
																			}
																			id={`${turnos.data[el].date}-${turnoName}`}
																			style={{
																				display:
																					"inline",
																				marginRight:
																					".3em",
																			}}
																		/>
																		<p
																			style={{
																				display:
																					"inline",
																				color: "white",
																				backgroundColor:
																					"purple",
																				borderRadius:
																					".1em",
																				padding:
																					".3em",
																				margin: 0,
																			}}>
																			{
																				turnos
																					.data[
																					el
																				]
																					.tasks[
																					turnoName
																				]
																			}
																		</p>
																	</div>
																) : (
																	<div
																		id={
																			turnoName
																		}
																		key={
																			index
																		}>
																		<FormCheck
																			checked={assignTurnos.includes(
																				`${turnos.data[el].date}-${turnoName}`
																			)}
																			onChange={
																				clickTurnoHandler
																			}
																			id={`${turnos.data[el].date}-${turnoName}`}
																			style={{
																				display:
																					"inline",
																				marginRight:
																					".3em",
																			}}
																		/>
																		<p
																			style={{
																				margin: 0,
																				display:
																					"inline",
																			}}>
																			0
																		</p>
																	</div>
																);
															}
														)}
												</div>
											</Col>
										</Row>
									);
								}
							)}
					</Container>
				</Col>
			</Row>
			{openEmployeeModal && <Modal
				centered
				show={openEmployeeModal}
				onHide={() => setOpenEditModal(false) }
				contentLabel="Editar empleados asignados al turno">
				<Modal.Header>
					<Modal.Title>
						Editar empleados asignados al turno
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Selecciona los empleados que quieres asignar a este
						turno
					</p>
					{data &&
						data.map((employee: Employee, index: any) => {
							return (
								<div>
									<Row
										style={{
											height: "3em",
											textAlign: "start",
											padding: "2em",
										}}>
										<Col
											lg={2}
											md={2}
											xs={1}>
											{
												<Form.Check
													onChange={(event) => {
														setEmployees([
															...employees,
															event.target.id,
														]);
													}}
													id={employee.id!.toString()}
												/>
											}
										</Col>

										<Col
											lg={10}
											md={10}
											xs={8}>
											<EmployeeTableName
												style={{ display: "inline" }}>
												{employee.name}{" "}
												{employee.last_name}
											</EmployeeTableName>
										</Col>
									</Row>
									<hr
										style={{
											color: "rgba(149, 187, 232, 0.6)",
											borderWidth: "2px",
											marginTop: "1em",
											marginBottom: 0,
										}}
									/>
								</div>
							);
						})}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							setShowEmployeeModals(false);
							//window.location.reload();
						}}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							setShowEmployeeModals(false);
							updateTurnoPlanApi(assignTurnos, employees).then(
								(employees) => {
									console.log(employees);
									if (employees) {
										setEmployees([]);
										setAssignTurnos([]);
										window.location.reload();
									}
									window.location.reload();
								}
							);
						}}>
						Asignar Empleados
					</Button>
				</Modal.Footer>
			</Modal>}

			{openEditModal && <Modal
				centered
				show={openEditModal}
				onHide={() => setOpenEditModal(false)}
				contentLabel="Selecciona los empleados que quieres agregar">
				<Modal.Header>
					<Modal.Title>Editar empleados asignados</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{TurnosEmployee &&
						TurnosEmployee.map((employee: Employee, index: any) => {
							return (
								<div>
									<Row
										style={{
											height: "3em",
											textAlign: "start",
											padding: "2em",
										}}>
										<Col lg={2}>
											{
												<Form.Check
													onChange={(event) => {
														setDeleteEmployees([
															...employees,
															event.target.id,
														]);
													}}
													id={employee.id!.toString()}
												/>
											}
										</Col>

										<Col lg={10}>
											<EmployeeTableText>
												{employee.name}{" "}
												{employee.last_name}
											</EmployeeTableText>
										</Col>
									</Row>
									<hr
										style={{
											color: "rgba(149, 187, 232, 0.6)",
											borderWidth: "2px",
											marginTop: "1em",
											marginBottom: 0,
										}}
									/>
								</div>
							);
						})}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							setOpenEditModal(false);
						}}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							setOpenEditModal(false);
							updateItem(
								{
									employees: [...deleteEmployees],
								},
								undefined,
								`turno_code=${encodeURI(
									editEmployeeTurnosCode
								)}`
							).then(async () => {
								setDeleteEmployees([]);
								window.location.reload();
							});
						}}>
						Eliminar Empleados
					</Button>
				</Modal.Footer>
			</Modal>}
		</Container>
	);
};
