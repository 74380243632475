import { Button, Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { useNavigate } from "react-router-dom";
import { createTurnoPlanApi } from "../../api/TurnosApi";
import { toast } from "react-toastify";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";

export const TurnosCreatePage = () => {
	const navigate = useNavigate();
	const [timeSelect,setTimeSelect] = useState<any>([{}])


	const createTurnoHandler = (event: any) => {
		event.preventDefault();
		let time = []
		for (let index = 0; index < timeSelect.length; index++) {
			time.push({
				start: event.target[`start${index}`].value,
				end:event.target[`end${index}`].value
			})
			
		}
		createTurnoPlanApi({
			name: event.target.name.value,
			color: event.target.color.value,
			times_list: time
		}).then((turno) => {
				toast.success("Turno creado correctamente");
				navigate("/turnos");
		})
		
	};
	return (
		<Container style={{ padding: "1em"}}>
			<TitleText>Añadir turno</TitleText>
			<CardComponent>
				<form onSubmit={createTurnoHandler}>
					<Row>
						<Col
							lg={10}
							md={10}
							xs={6}>
							<Form.Group>
								<LabelStyled className="project_input_label">
									Nombre del turno
								</LabelStyled>
								<InputStyled
									name="name"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
								/>
							</Form.Group>
						</Col>
						<Col
							lg={2}
							md={2}
							xs={6}>
							<Form.Group>
								<LabelStyled>Color</LabelStyled>
								<SelectInputStyled
									name={"color"}
									onChange={(event) =>{}
										//setColor(event.target.value)
									}>
									<option value="red">Rojo</option>
									<option value="blue">Azul</option>
									<option value="yellow">Amarillo</option>
								</SelectInputStyled>
							</Form.Group>
						</Col>
					</Row>
					
					<Row style={{margin: "1em"}} className="start-end">
						<Col lg={2} xs={2} md={2}>
							<Button style={{padding: ".1em"}} onClick={(event) => setTimeSelect([{},...timeSelect])}>
								<Plus/> 
							</Button>
						</Col>
					</Row>
					<Row>
						{timeSelect.map((el:any,index:number) => <Row>
							<Col lg={6}>
								<Form.Group>
									<LabelStyled className="project_input_label">
										Hora de inicio
									</LabelStyled>
									<InputStyled
										name={`start${index}`}
										className="project_input_field"
										type="time"
										placeholder="00:00"
									/>
								</Form.Group>
							</Col>
							<Col lg={6}>
								<Form.Group>
									<LabelStyled className="project_input_label">
										Hora de fin
									</LabelStyled>
									<InputStyled
										name={`end${index}`}
										className="project_input_field"
										type="time"
										placeholder="00:00"
									/>
								</Form.Group>
							</Col>
						</Row>)}
					</Row>

					<Row
						style={{ marginTop: "2em" }}
						className="flex-row-reverse">
						<Col lg={2} md={2} xs={6}>
						<AddButtonStyled
							style={{
								
								borderRadius: "0.3em",
								padding: ".2em",
							}}>
							Guardar
						</AddButtonStyled>
						</Col>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
