import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMe } from "../../hooks/useMe";
import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { SelectInputStyled } from "../../components/formComponents/selectStyled";
import { UpdateUserInfoHandler } from "../../api/UserApiHandler";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";

export const UserProfilePage = () => {
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [bornDate, setBornDate] = useState("");
	const [position, setPosition] = useState("");
	const [selectedImage, setSelectedImage] = useState(null);
	const [address,setAddress] = useState("")
	const [nif,setNif] = useState("")
	const user = useMe();

	useEffect(() => {
		if (user.name) {
			console.log(user);
			let userData = user;
			setName(userData.name);
			setLastName(userData.last_name);
			setEmail(userData.email);
			setBornDate(userData.born_date);
			setPosition(userData.position!);
			setAddress(userData.address);
			setNif(userData.nif)
		}
	}, [user]);

	const updateMeInformation = async (event: any) => {
		let correct = true;
		event.preventDefault();
		const formData = event.target;
		if (formData.bornDate.value === "") {
			toast.error("Es obligatorio indicar la fecha de nacimiento");
			correct = false;
		}
		if (formData.surnames.value === "") {
			toast.error("Es obligatorio indicar los apellidos");
			correct = false;
		}
		if (formData.name.value === "") {
			toast.error("Es obligatorio indicar el nombre del empleado");
			correct = false;
		}
		if (!correct) {
			return;
		}
		const newUserInfo = new FormData();
		newUserInfo.append("name", formData.name.value);
		newUserInfo.append("last_name", formData.surnames.value);
		newUserInfo.append("email", formData.email.value);
		newUserInfo.append("born_date", formData.bornDate.value);
		newUserInfo.append("position", formData.position.value);
		newUserInfo.append("nif", formData.nif.value);
		newUserInfo.append("address", formData.address.value);

		if (selectedImage !== null) newUserInfo.append("file", selectedImage!);
		const updateEmployee = await UpdateUserInfoHandler(newUserInfo).then(
			(data) => {
				if (data)
					toast.success("Información actualizada correctamente");
				else
					toast.error(
						"Error actualizando la información del usuario"
					);
			}
		);
		
	};
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Editar empleado</TitleText>
			<CardComponent>
				<Row>
					<Col className="text-center">
						<div style={{ display: "flex" }}>
							{selectedImage ? (
								<img
									src={URL.createObjectURL(selectedImage)}
									alt="Vista previa"
									style={{ width: "100px" }}
								/>
							) : (
								<img
									style={{
										width: "100px",
										height: "auto",
									}}
									className="rounded-circle"
									alt="avatar1"
									src={`${
										process.env.REACT_APP_BASE_URL
									}${user.photo!}`}
								/>
							)}
							<Container
								style={{ width: "200px", marginLeft: "2em" }}>
								<Col className="justify-content-center">
									<AddButtonStyled
										onClick={(event) => {
											document
												.getElementById("imageInput")!
												.click();
										}}>
										{" "}
										Editar foto de perfil{" "}
									</AddButtonStyled>
								</Col>
							</Container>
						</div>
					</Col>
				</Row>
				<form onSubmit={updateMeInformation}>
					<input
						type="file"
						id="imageInput"
						hidden={true}
						onChange={(event: any) => {
							if (event.target.files[0])
								setSelectedImage(event.target.files[0]);
							
						}}
					/>
					<Row>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Nombre</LabelStyled>
								<InputStyled
									name="name"
									className="project_input_field"
									type="text"
									placeholder="Nombre"
									value={name}
									onChange={(event: any) =>
										setName(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Apellidos</LabelStyled>
								<InputStyled
									name="surnames"
									className="project_input_field"
									type="text"
									placeholder="Apellidos"
									value={lastName}
									onChange={(event: any) =>
										setLastName(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>E-mail</LabelStyled>
								<InputStyled
									name="email"
									className="project_input_field"
									type="text"
									placeholder="email@email.com"
									value={email}
									onChange={(event: any) =>
										setEmail(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={6}
							xs={12}>
							<Form.Group>
								<LabelStyled>Fecha de nacimiento</LabelStyled>
								<InputStyled
									name="bornDate"
									className="project_input_field"
									type="date"
									placeholder="dd/mm/yyyy"
									value={bornDate}
									onChange={(event: any) =>
										setBornDate(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>

					

					<Row style={{ marginTop: "1em" }}>
						<Col
							lg={6}
							md={12}
							xs={12}>
							<Form.Group>
								<LabelStyled>Dirección</LabelStyled>
								<InputStyled
									name="address"
									className="project_input_field"
									type="text"
									placeholder="Dirección"
									value={address}
									onChange={(event: any) =>
										setAddress(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
						<Col
							lg={6}
							md={12}
							xs={12}>
							<Form.Group>
								<LabelStyled>NIF</LabelStyled>
								<InputStyled
									name="nif"
									className="project_input_field"
									type="text"
									placeholder="NIF"
									value={nif}
									onChange={(event: any) =>
										setNif(event.target.value)
									}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row style={{ marginTop: "1em" }}>
						<Form.Group>
							<LabelStyled>Cargo en la empresa</LabelStyled>
							<SelectInputStyled
								value={position}
								name="position"
								onChange={(event) => {
									setPosition(event.target.value);
								}}>
								<option value="encargado">Encargado</option>
								<option value="empleado">Empleado</option>
								<option value="gestor">Gestor</option>
								<option value="gerente">Gerente</option>
							</SelectInputStyled>
						</Form.Group>
					</Row>
					<Row
						className="justify-content-end"
						style={{
							marginTop: "1em",
						}}>
						<Col
							lg={2}
							md={6}
							xs={6}>
							<SelectButtonStyled active={true}>
								<p style={{ margin: 0 }}>Guardar</p>
							</SelectButtonStyled>
						</Col>
					</Row>
				</form>
			</CardComponent>
		</Container>
	);
};
