import { useCallback, useState } from "react"
import { apiClient } from "../api/axiosClient"
import { AxiosError } from "axios"



export const useDeleteItem = (url: string ) => {
    const [deleteLoading,setIsLoading] = useState(false)
    const [deleteError,setError] = useState<string|boolean|null>(null)
    const [deleteData,setData] = useState<any>(null)
    const deleteItem = useCallback(async () => {
        const token =localStorage.getItem("token")
        setIsLoading(true)
        try{
            const request = await apiClient.delete(url,
              {
                headers:{
                    "Authorization": `Bearer ${token}`
                },
              }
            )
            if (request.status != 204)
              setError(true)
            else{
              setData(request.data)
              setError(false)
            }
          }
        catch (error) {
            if (error instanceof AxiosError) {
              setError(error.message.toString());
            } else {
              setError('Error eliminando el elemento');
            }
          } finally {
            setIsLoading(false);
          }
    },[url])
    return {deleteData,deleteLoading,deleteError,deleteItem}
}
export default useDeleteItem