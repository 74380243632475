import { useCallback, useState } from "react";
import { apiClient } from "../api/axiosClient";

export const useUpdateItem = (url: string) => {
	const [updateLoading, setIsLoading] = useState(false);
	const [updateError, setError] = useState<Error | null>(null);
	const [updateData, setData] = useState<any>(null);
	const updateItem = useCallback(
		async (updateDataObject: any, id?: string | number,query_params?: string) => {
			const token = localStorage.getItem("token");
			setIsLoading(true);
			const configuration: any = {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};
			if (updateDataObject instanceof FormData) {
				configuration["headers"]["Content-Type"] =
					"multipart/form-data";
			}
			try {
				let modifiedUrl = url
				if (id !== undefined)
					modifiedUrl += `/${id}/`
				if (query_params !== undefined)
					modifiedUrl += `?${query_params}`
				let request = await apiClient.put(
					modifiedUrl,
					updateDataObject,
					configuration
				);

				setData(request.data);
			} catch (error) {
				if (error instanceof Error) {
					setError(error);
				} else {
					setError(new Error("Error obteniendo la información"));
				}
			} finally {
				setIsLoading(false);
			}
		},
		[url]
	);
	return { updateData, updateLoading, updateError, updateItem };
};
