import { Col, Form, Row } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import Container from "react-bootstrap/Container";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { createClientApi } from "../../api/ClientApiHandler";
import { useNavigate } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { toast } from "react-toastify";

export const ClientCreatePage = () => {
	const navigate = useNavigate();
	const createClientHandler = async (event: any) => {
		event.preventDefault();
		const formData = event.target;
		if (formData.name.value === "") {
			toast.error("Es necesario establecer el nombre del cliente");
			return;
		}
		const createClient = await createClientApi({
			name: formData.name.value,
			email: formData.email.value,
			phone_number: formData.phone_number.value,
			nif: formData.nif.value,
			razon_social: formData.razon_social.value,
			address: formData.address.value,
			start_date: formData.start_date.value
		});
		if (createClient) navigate("/client");
	};
	return (
		<Container style={{ padding: "1em" }}>
			<TitleText>Nuevo cliente</TitleText>
			<CardComponent>
			<form onSubmit={createClientHandler}>
						<Row>
							<Col
								lg={6}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled>Nombre</LabelStyled>
									<InputStyled
										name="name"
										className="project_input_field"
										type="text"
										placeholder="Nombre"
										
									/>
								</Form.Group>
							</Col>
							<Col
								lg={6}
								md={6}
								xs={12}>
								<Form.Group>
									<LabelStyled>Teléfono</LabelStyled>
									<InputStyled
										name="phone_number"
										className="project_input_field"
										type="text"
										placeholder="Número de teléfono"
										
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>E-mail</LabelStyled>
									<InputStyled
										name="email"
										className="project_input_field"
										type="text"
										placeholder="email@email.com"
										
									/>
								</Form.Group>
							</Col>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>NIF</LabelStyled>
									<InputStyled
										name="nif"
										className="project_input_field"
										type="text"
										placeholder="NIF"
										
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>Fecha de inicio</LabelStyled>
									<Form.Control
										name="start_date"
										type="date"
										
									/>
								</Form.Group>
							</Col>
							<Col
								lg={6}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>Dirección</LabelStyled>
									<InputStyled
										name="address"
										className="project_input_field"
										type="text"
										placeholder="Direción"
										
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row style={{ marginTop: "1em" }}>
							<Col
								lg={12}
								md={12}
								xs={12}>
								<Form.Group>
									<LabelStyled>Razón Social</LabelStyled>
									<InputStyled
										name="razon_social"
										className="project_input_field"
										type="text"
										placeholder="Razón social"
										
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row
							style={{ marginTop: "1em" }}
							className="flex-row-reverse">
							<AddButtonStyled
								style={{
									width: "25%",
									borderRadius: "0.3em",
									padding: ".2em",
								}}>
								Actualizar
							</AddButtonStyled>
						</Row>
					</form>
		

			</CardComponent>
		</Container>
	);
};
