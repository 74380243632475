import { Plus } from "react-feather";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { TitleText } from "../login/LoginStyles";
import {
  EmployeeTableName,
  EmployeeTableText,
} from "../../components/EmployeeList/employeeTableStyled";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ClientInterface } from "../../interfaces/client";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";

export const ClientListPage = () => {
  const [clientList, setClientList] = useState<ClientInterface[]>([]);
  const navigate = useNavigate();
  const { data, isLoading, error, fetchItems } = useFetchItems(
    ApiEndpoints.client
  );
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);

  const filterTableHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.value;
    if (filter === "") setClientList(data);
    const clients = data.filter((client: ClientInterface) => {
      let fullName = client.name;
      if (fullName.includes(filter)) return client;
    });
    setClientList(clients);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (data === null) {
      fetchItems();
    } else if (data !== null && clientList.length === 0) {
      setClientList(data);
    }
  }, [data]);
  useEffect(() => {
    fetchItems();
  }, []);
  return (
    <Container fluid style={{ padding: "1em" }}>
      {isMobileView && <TitleText> Clientes </TitleText>}
      <Row>
        <Col lg={2} md={12} sm={12} style={{ marginRight: "1em" }}>
          <AddButtonStyled
            onClick={() => {
              navigate("/client/create");
            }}
          >
            <Plus /> Añadir cliente
          </AddButtonStyled>
          <InputComponentStyled
            placeholder="Buscar cliente"
            onChange={filterTableHandler}
          />
        </Col>
        <Col lg={9} md={12} sm={12}>
          {!isMobileView && <TitleText> Clientes </TitleText>}
          <Container
            fluid
            style={{
              backgroundColor: "white",
              paddingTop: "1em",
              borderRadius: ".3em",
              height: "100%",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <Row
              style={{
                height: "2em",
                textAlign: "start",
                padding: " .5em 1em 1em 1em",
              }}
            >
              <Col lg={4}>
                <EmployeeTableName>Nombre</EmployeeTableName>
              </Col>
              <Col lg={4}>
                <EmployeeTableText>Email</EmployeeTableText>
              </Col>
              <Col lg={4}>
                <EmployeeTableText>Teléfono</EmployeeTableText>
              </Col>
            </Row>
            <hr
              style={{
                color: "rgba(149, 187, 232, 0.6)",
                borderWidth: "2px",
                marginTop: 0,
                marginBottom: 0,
              }}
            />
            {clientList &&
              clientList.map((client: ClientInterface) => (
                <div
                  key={client.id}
                  onClick={() => {
                    navigate(`/client/${client.id}`);
                  }}
                >
                  <Row
                    style={{
                      height: "3em",
                      textAlign: "start",
                      padding: "2em",
                    }}
                  >
                    <Col lg={4}>
                      <EmployeeTableName>{client.name}</EmployeeTableName>
                    </Col>
                    <Col lg={4}>
                      <EmployeeTableText>{client.email}</EmployeeTableText>
                    </Col>
                    <Col lg={4}>
                      <EmployeeTableText>
                        {client.phone_number}
                      </EmployeeTableText>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      color: "rgba(149, 187, 232, 0.6)",
                      borderWidth: "2px",
                      marginTop: "1em",
                      marginBottom: 0,
                    }}
                  />
                </div>
              ))}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
