import { Plus } from "react-feather";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { EmployeeTableName } from "../../components/EmployeeList/employeeTableStyled";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { BudgetListInterface } from "../../interfaces/budgetList";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import { TitleText } from "../login/LoginStyles";

export const BudgetListPage = () => {

  const [budgetList, setBudgetList] = useState<BudgetListInterface[]>([]);
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);

  const { data, isLoading, error, fetchItems } = useFetchItems(
    ApiEndpoints.budget
  );

  useEffect(() => {
    fetchItems();
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalBudget = useMemo(() => {
    return budgetList.reduce((sum, budget) => sum + parseFloat(budget.budget), 0).toFixed(2);
  }, [budgetList]);

  const totalExpense = useMemo(() => {
    return budgetList.reduce((sum, budget) => sum + parseFloat(budget.total_expense), 0).toFixed(2);
  }, [budgetList]);

  const filterTableHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.value.toLowerCase();
    if (filter === "") setBudgetList(data);
    else {
      const filteredList = data.filter((budget: BudgetListInterface) =>
        budget.project_name.toLowerCase().includes(filter)
      );
      setBudgetList(filteredList);
    }
  };


  useEffect(() => {
    if (data === null) {
      fetchItems();
    } else if (data !== null && budgetList.length === 0) {
      setBudgetList(data);
    }
  }, [data]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <Container fluid style={{ padding: "1em" }}>
      {isMobileView && <TitleText> Presupuestos </TitleText>}
      <Row>
        <Col lg={2} md={12} sm={12} style={{ marginRight: "1em" }}>
          <AddButtonStyled
            onClick={() => {
              navigate("/budget/create");
            }}
          >
            <Plus /> Add Budget
          </AddButtonStyled>
          <InputComponentStyled
            placeholder="Buscar Presupuesto"
            onChange={filterTableHandler}
          />
        </Col>
        <Col lg={9} md={12} sm={12}>
          {!isMobileView && <TitleText> Presupuestos </TitleText>}
          <Container
            fluid
            style={{
              backgroundColor: "white",
              borderRadius: ".3em",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <Row
              style={{
                height: "3em",
                textAlign: "start",
                padding: " 1.5em 1em 1em 1em",
                backgroundColor: "lightgrey",
                borderTopRightRadius: ".3em",
                borderTopLeftRadius: ".3em",
              }}
            >
              <Col xs={4}>
                <EmployeeTableName>Nombre</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>Presupuesto</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>Gastos totales</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>View</EmployeeTableName>
              </Col>
              <Col xs={2}>
                <EmployeeTableName>Action</EmployeeTableName>
              </Col>
            </Row>
            {budgetList &&
              budgetList.map((budget: BudgetListInterface) => (
                <div key={budget.id}>
                  <Row
                    style={{
                      height: "3em",
                      textAlign: "start",
                      padding: "2em 1.5em 2em 1.5em",
                    }}
                  >
                    <Col xs={4}>
                      <EmployeeTableName>
                        {budget.project_name}
                      </EmployeeTableName>
                    </Col>
                    <Col xs={2}>
                      <EmployeeTableName>{budget.budget}</EmployeeTableName>
                    </Col>
                    <Col xs={2}>
                      <EmployeeTableName style={parseFloat(budget.budget) >= parseFloat(budget.total_expense) ? {} : { color: 'red' }}>
                        {parseFloat(budget.total_expense).toFixed(2)}
                      </EmployeeTableName>
                    </Col>
                    <Col
                      xs={2}
                      onClick={() => {
                        navigate(`/budget/${budget.project_id}`);
                      }}
                    >
                      <EmployeeTableName>View Details</EmployeeTableName>
                    </Col>
                    <Col
                      xs={2}
                      onClick={() => {
                        navigate(`/budget/create/${budget.project_id}`);
                      }}
                    >
                      <EmployeeTableName>Add Expense</EmployeeTableName>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      color: "rgba(149, 187, 232, 0.6)",
                      borderWidth: "2px",
                      marginTop: "1em",
                      marginBottom: 0,
                    }}
                  />
                </div>
              ))}
            <div key={budgetList.length + 1}>
              <Row
                style={{
                  height: "5em",
                  textAlign: "start",
                  backgroundColor: "papayawhip",
                  padding: "2em 1em 2em 1em",
                  borderBottomRightRadius: ".3em",
                  borderBottomLeftRadius: ".3em",
                }}
              >
                <Col xs={4}>
                  <EmployeeTableName>Total Projects</EmployeeTableName>
                </Col>
                <Col xs={2}>
                  <EmployeeTableName>{totalBudget}</EmployeeTableName>
                </Col>
                <Col xs={2}>
                  <EmployeeTableName style={parseFloat(totalBudget) >= parseFloat(totalExpense) ? {} : { color: 'red' }}>{totalExpense}</EmployeeTableName>
                </Col>
                <Col xs={2}>
                  <EmployeeTableName></EmployeeTableName>
                </Col>
                <Col xs={2}>
                  <EmployeeTableName></EmployeeTableName>
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
