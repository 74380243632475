import styled from "styled-components";


export const AddButtonStyled = styled.button<{color?: string}>`
    display: inline-flex;
    background-color: ${props => props.color  ? props.color: "rgba(48, 100, 217, 0.9)" };
    color: white;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.2em;
    border-color: transparent;
    font-size: 14px;
    font-weight: 600;

    svg{
        margin-right: 0.2em;
    }

`

export const LinkButtonStyled = styled.a`
    display: inline-flex;
    background-color: rgba(48, 100, 217, 0.9);
    color: white;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.2em;
    border-color: transparent;
    font-size: 14px;
    font-weight: 600;
    decoration: none;

    svg{
        margin-right: 0.2em;
    }

`

export const FolderButtonStyled = styled.button`
    display: inline-flex;
    background-color: rgba(48, 100, 217, 0.2);
    color: rgba(48, 100, 217, 1);
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.2em;
    border-color: transparent;
    font-size: 14px;
    font-weight: 600;

    svg{
        margin-right: 0.2em;
    }

`

export const TurnosButtonSelectStyled = styled.button<{color?: string}>`
    display: inline-block;
    background-color: ${props => props.color  ? props.color: "rgba(48, 100, 217, 0.9)" };
    color: white;
    width: 100%;
    border-radius: 0.4em;
    border-color: transparent;
    font-size: 14px;
    font-weight: 600;
`