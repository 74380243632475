import { useEffect, useState } from "react";
import { GetUserInfoHandler } from "../api/UserApiHandler";

export const useMe = (): any => {
	const [employee, setEmployee] = useState<any>({});
	useEffect(() => {
		GetUserInfoHandler().then((data) => {
            setEmployee(data)
        })
	}, []);
	return employee;
};
