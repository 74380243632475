import { File, Folder, Plus } from "react-feather";
import {
	AddButtonStyled,
	FolderButtonStyled,
} from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TitleText } from "../login/LoginStyles";
import { EmployeeTableName, EmployeeTableText } from "../../components/EmployeeList/employeeTableStyled";
import { useNavigate } from "react-router-dom";
import { AttachmentInterface } from "../../interfaces/attachment";
import { useEffect, useState } from "react";
import { ApiEndpoints } from "../../constants/urls";
import { useFetchItems } from "../../hooks/useFetchItems";

export const AttachmentListPage = () => {
	const navigate = useNavigate();
	const [attachments,setAttachments] = useState<any>([])
	const {data,isLoading,error,fetchItems} = useFetchItems(ApiEndpoints.attachment);


	useEffect(() => {
		fetchItems()
	},[])
	useEffect(() => {
		if (data !== null && attachments.length === 0)
			setAttachments(data)
	},[data])
	
	return (
		<Container
			fluid
			style={{ padding: "1em" }}>
			<Row>
				<Col
					lg={2}
					md={4}
					sm={4}
					style={{ marginRight: "1em" }}>
					<AddButtonStyled
						onClick={() => {
							navigate("/attachment/create");
						}}>
						<Plus /> Nuevo archivo
					</AddButtonStyled>
					<div style={{ marginTop: "1em" }}>
						<FolderButtonStyled
							onClick={() => {
								navigate("/attachment/folder/create");
							}}>
							<Folder /> Crear carpeta
						</FolderButtonStyled>
					</div>
				</Col>
				<Col
					lg={9}
					md={8}
					sm={8}>
					<TitleText> Adjuntos </TitleText>
					<Container
						fluid
						style={{
							backgroundColor: "white",
							paddingTop: "1em",
							borderRadius: ".3em",
							height: "100%",
						}}>
						<Row
							style={{
								height: "2em",
								textAlign: "start",
								padding: " .5em 1em 1em 1em",
							}}>
							<Col lg={3}>
								<EmployeeTableName>Nombre</EmployeeTableName>
							</Col>
							<Col lg={5}>
								<EmployeeTableText>
									Última modificación
								</EmployeeTableText>
							</Col>
							<Col lg={4}>
								<EmployeeTableText>Autor</EmployeeTableText>
							</Col>
						</Row>
						<hr
							style={{
								color: "rgba(149, 187, 232, 0.6)",
								borderWidth: "2px",
								marginTop: 0,
								marginBottom: 0,
							}}
						/>
						{attachments.map(
							(attachment: AttachmentInterface, index:number) => (
								<Row
									style={{
										height: "2em",
										textAlign: "start",
										padding: " 2em"
									}}
									onClick={(event) => attachment.type === "file" ? navigate(`/attachment/${attachment.id!}`) : navigate(`/attachment/folder/${attachment.id!}`) }
									>
									<Col
										lg={3}
									>
										<div style={{display: "flex", justifyContent: "start",textAlign:"center"}}>
											{attachment.type === "file" ? (
												<File style={{"marginRight": "1em"}} color="red" />
											) : (
												<Folder style={{"marginRight": "1em"}} />
											)}
											<EmployeeTableName>
												{attachment.name}
											</EmployeeTableName>
										</div>
									</Col>
									<Col lg={5}>
										<EmployeeTableText>
											{attachment.modified_date.toString()}
										</EmployeeTableText>
									</Col>
									<Col lg={4}>
										<EmployeeTableText>
											{attachment.author}
										</EmployeeTableText>
									</Col>
								</Row>
							)
						)}
					</Container>
				</Col>
			</Row>
		</Container>
	);
};
