import {Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
	createTimerApi,
	getCurrentTimer,
	stopCurrentRecord,
} from "../api/TimeSheetApi";
import { TaskSelectComponent } from "./formComponents/taskSelectComponent";
import start from "../assets/images/start.png";
import stop from "../assets/images/stop.png";
import { toast } from "react-toastify";
import { InputadorComponentStyled } from "./Inputador/InputadorComponent";

export const InputadorComponent = () => {
	const [taskTime, setTaskTime] = useState({
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const [isActive, setIsActive] = useState(false);
	const [task,setTask] = useState(0)
	const [project,setProject] = useState("")
	const [timer,setTimer] = useState({
		"task":{
			"id": 0,
			"project_name": ""
		}
	})
	const [taskSelected,setTaskSelected] = useState("")
	const startTimer = () => {
		if (task == 0){
			toast.error("Es necesario seleccionar una tarea para comenzar el timer")
			return
		}
		setIsActive(true);
		createTimerApi(task).then((data) => {});
	};
	const stopTimer = () => {
		stopCurrentRecord().then((data) => {
			setTask(0)
			setProject("")
			setTimer({
				"task":{
					"id": 0,
					"project_name": ""
				}
			})
		});
		setIsActive(false);
	};
	useEffect(() => {
		getCurrentTimer().then((timer:any) => {
			if (timer.data) {
				setTaskTime({
					hours: timer.data.split(":")[0],
					minutes: timer.data.split(":")[1],
					seconds: 0,
				});
				setIsActive(true);
				setTimer(timer)
				setProject(timer.task.project_name)
				setTaskSelected(`${timer.task.id}#${timer.task.project_name}`)
			}
		});
	}, [task]);
	return (
		<InputadorComponentStyled>
			<div style={{display: "flex",flexDirection: "column",justifyContent: "center",width: "60%"}}>
				<TaskSelectComponent
					name={"task"}
					onChange={(event: any) => {
						setTask(event.target.value.split("#")[0])
						setProject(event.target.value.split("#")[1])
						setTaskSelected(`${event.target.value.split("#")[0]}#${event.target.value.split("#")[1]}`)
					}}
					value={taskSelected}
				/>
				{project != "" ? <p>{project}</p> : <p></p>}
			</div>

			{isActive ? (
				<button
					style={{
						borderRadius: 90,
						borderColor: "transparent",
						paddingTop: 10,
						paddingBottom: 10,
						paddingLeft: 10,
						paddingRight: 10,
					}}
					onClick={stopTimer}>
					<img src={stop} />
				</button>
			) : (
				<button
					style={{
						borderRadius: 90,
						borderColor: "transparent",
						backgroundColor: "transparent",
						paddingTop: 10,
						paddingBottom: 10,
						paddingLeft: 10,
						paddingRight: 10,
					}}
					onClick={startTimer}>
					<img src={start} />
				</button>
			)}
		</InputadorComponentStyled>
	);
};
