import axios from "axios";
import { Notification } from "../utility/Notification";

export const createBudgetApi = async (budgetData: any) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/`,
      budgetData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error: any) {
    Notification("error", error.response.data);
    return false;
  }
};

export const getBudgetApi = async (id: number) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error: any) {
    Notification("error", error.response.data);
    return false;
  }
};

export const updateBudgetApi = async (id: number, data: any) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/${id}/`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status !== 204) return null;
    return res.data;
  } catch (error) {
    return null;
  }
};

export const deleteBudgetApi = async (id: number) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}api/v1/budget/${id}/`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status !== 200) return null;
    return res.data;
  } catch (error) {
    return null;
  }
};
