import { useEffect } from "react";
import { ApiEndpoints } from "../../constants/urls";
import { useFetchItems } from "../../hooks/useFetchItems";
import { SelectInputStyled } from "./selectStyled";
import { ClientInterface } from "../../interfaces/client";


export const ClientFormSelect = (props: {name: string,onChange: any,value?:any}) => {
    const {data,isLoading,error,fetchItems} = useFetchItems(ApiEndpoints.client)
    useEffect(() => {fetchItems()},[])
    return data && <SelectInputStyled name={props.name} onChange={props.onChange} value={props.value}>
    {data.map((client: ClientInterface,index:number) => 
        <option key={client.id} value={client.id}>{client.name}</option>
    )}

    </SelectInputStyled>
    
}