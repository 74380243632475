import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import { NavLink } from "react-router-dom";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.primaryBlue};
  top: 0px;
`;

export const NavLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-top: 50px;
`;

export const ImgIcon = styled.img`
  width: 24px;
  height: 24px;
  padding: 8px 12px 8px 12px;
`;

export const CustomLink = styled(NavLink)`
  width: 95px;
  text-decoration: none;
  color: ${COLORS.primaryWhite};
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin-top: 20px;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  box-sizing: border-box;

  &.active {
    color: black;
    background-color: ${COLORS.primaryWhite};
  }
`;
