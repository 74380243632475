import { Plus } from "react-feather";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { TitleText } from "../login/LoginStyles";
import { EmployeeTableName, EmployeeTableText } from "../../components/EmployeeList/employeeTableStyled";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProjectInterface } from "../../interfaces/project";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";

export const ProjectListPage = () => {
	const {data,isLoading,error,fetchItems} = useFetchItems(ApiEndpoints.project)
	const navigate = useNavigate();
	const [projectList, setProjectList] = useState([]);
	const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);
	const filterTableHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const filter = event.target.value;
		if (filter === "") setProjectList(data);
		const clients = data.filter((project: any) => {
			let fullName = project.name;
			if (fullName.includes(filter)) return project;
		});
		setProjectList(clients);
	};

	useEffect(() => {
		fetchItems()
	}, []);
	useEffect(() => {
		if (data!= null && projectList.length === 0)
			setProjectList(data)
	},[data])

	useEffect(() => {
		const handleResize = () => {
		  setIsMobileView(window.innerWidth <= 991);
		};
		window.addEventListener("resize", handleResize);
		return () => {
		  window.removeEventListener("resize", handleResize);
		};
	  }, []);


	return (
		<Container
			fluid
			style={{ padding: "1em" }}>
				{isMobileView && <TitleText> Proyectos </TitleText>}
			<Row>
				<Col
					lg={2}
					md={12}
					sm={12}
					style={{ marginRight: "1em" }}>
					<AddButtonStyled
						onClick={() => {
							navigate("/project/create");
						}}>
						<Plus /> Añadir proyecto
					</AddButtonStyled>
					<InputComponentStyled
						placeholder="Buscar proyecto"
						onChange={filterTableHandler}
					/>
				</Col>
				<Col
					lg={9}
					md={12}
					sm={12}>
					{!isMobileView && <TitleText> Proyectos </TitleText>}
					<Container
						fluid
						style={{
							backgroundColor: "white",
							paddingTop: "1em",
							borderRadius: ".3em",
							height: "100%",
							marginTop: isMobileView ? "1em" : "0",
						}}>
						<Row
							style={{
								height: "2em",
								textAlign: "start",
								padding: " .5em 1em 1em 1em",
							}}>
							<Col lg={3}>
								<EmployeeTableName>Proyecto</EmployeeTableName>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>
									Administrador
								</EmployeeTableText>
							</Col>
							<Col lg={1}>
								<EmployeeTableText>Cliente</EmployeeTableText>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>Empleados</EmployeeTableText>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>
									Horas totales
								</EmployeeTableText>
							</Col>
							<Col lg={2}>
								<EmployeeTableText>Estado</EmployeeTableText>
							</Col>
						</Row>
						<hr
							style={{
								color: "rgba(149, 187, 232, 0.6)",
								borderWidth: "2px",
								marginTop: 0,
								marginBottom: 0,
							}}
						/>
						{projectList.map((project: ProjectInterface,index: number) => (
							<div
								key={project.id}
								onClick={() => {
									navigate(`/project/${project.id!}`);
								}}>
								<Row
									style={{
										height: "3em",
										textAlign: "start",
										padding: "2em",
									}}>
									<Col lg={3}>
										<EmployeeTableName style={{wordBreak: "break-word"}}>
											{project.name}
										</EmployeeTableName>
									</Col>
									<Col lg={2}>
										<EmployeeTableText>
											{project.admin_user}
										</EmployeeTableText>
									</Col>
									<Col lg={1}>
										<EmployeeTableText>
											{project.client}
										</EmployeeTableText>
									</Col>
									
									<Col lg={2}>
										<EmployeeTableText>
											Empleados
										</EmployeeTableText>
									</Col>
									<Col lg={2}>
										<EmployeeTableText>
											{project.total_time}
										</EmployeeTableText>
									</Col>
									<Col lg={2}>
										<EmployeeTableText>
											{project.status}
										</EmployeeTableText>
									</Col>
								</Row>
								<hr
									style={{
										color: "rgba(149, 187, 232, 0.6)",
										borderWidth: "2px",
										marginTop: "1em",
										marginBottom: 0,
									}}
								/>
							</div>
						))}
					</Container>
				</Col>
			</Row>
		</Container>
	);
};
