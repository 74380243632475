import axios from "axios";

export const CreateHolidayApi = async (holidayInfo: any) => {
  try {
    const token = localStorage.getItem("token")
    const request = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/v1/holiday`,
      holidayInfo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request.data;
  } catch (error) {
    return false;
  }
};

export const getHolidayListApi = async () => {
  try {
    const token = localStorage.getItem("token")
    const request = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/v1/holiday`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request.data;
  } catch (error) {
    return false;
  }
};


export const getHolidayApi = async (id: any) => {
    try {
      const token = localStorage.getItem("token")
      const request = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/holiday/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return request.data;
    } catch (error) {
      return false;
    }
  };
  
export const updateHolidayApi = async (id: string |string[],employeeIds: number[] ) => {
    try {
      const token = localStorage.getItem("token")
      const request = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/holiday/${id}`,
        {
            employees: employeeIds
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return request.data;
    } catch (error) {
      return false;
    }
  };


export const deleteHolidayApi = async (id: any) => {
  try {
    const token = localStorage.getItem("token")
    const request = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}api/v1/holiday/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return request.data;
  } catch (error) {
    return false;
  }
};