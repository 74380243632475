import { Form } from "react-bootstrap";
import styled from "styled-components";



export const LabelStyled = styled(Form.Label)`
    font-family: 'Roboto';
    font-size: 0.8rem;
    font-weight: 500;
    line-height: .9rem;
    color: rgba(0, 0, 0, 0.5);
`

export const FormParagraphStyled = styled.p`
font-family: 'Roboto';
font-size: 0.8rem;
font-weight: 500;
line-height: .9rem;
color: rgba(0, 0, 0, 0.5);
`
