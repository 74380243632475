
import axios from 'axios';
import { ExpenseInterface } from '../interfaces/expense';
import { ProjectInterface } from '../interfaces/project';
import { apiClient } from './axiosClient';

export const getProjectListApi = async () => {
    try{
        const token =localStorage.getItem("token")
        const request = await axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/project/`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return [];
    }
}
export const getProjectApi = async (id:string) => {
    try{
        const token =localStorage.getItem("token")
        const request = await axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/project/${id}/`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return [];
    }
}

export const updateProjectApi = async (id:string,data:any) => {
    console.log(data)
    try{
        const token =localStorage.getItem("token")
        const request = await axios.put(`${process.env.REACT_APP_BASE_URL}api/v1/project/${id}/`,
            data,
            {
                headers:{"Authorization": `Bearer ${token}`}
            }
        )
        if (request.status !== 204)
            return null
        return request.data;
    }
    catch (error){
        return null;
    }
}

export const assignEmployeeToProjects = async (id:string, employeeIds: Number[]) => {
    try{
        const token =localStorage.getItem("token")
        console.log(employeeIds)
        const request = await axios.patch(`${process.env.REACT_APP_BASE_URL}api/v1/project/${id}/employee`,
            {
                "employees": employeeIds
            },
            {
                headers:{"Authorization": `Bearer ${token}`}
            }
        )
   
        return request.data;
    }
    catch (error){
        return null;
    }
}


export const deleteProjectApi = async (id:string) => {
    try{
        const token =localStorage.getItem("token")
        const request = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/v1/project/${id}/`,
            {
                headers:{"Authorization": `Bearer ${token}`}
            }
        )
        if (request.status !== 200)
            return null
        return request.data;
    }
    catch (error){
        return null;
    }
}
