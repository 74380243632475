import { Form } from "react-bootstrap";
import styled from "styled-components";



export const SelectInputStyled= styled(Form.Select)`
    border-radius: .3em;
    font-size: .8rem;
    font-weight: 500;
    ::placeholder{
        color: rgba(0, 0, 0, 0.2);
    }
`