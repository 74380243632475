import { useEffect } from "react";
import { ApiEndpoints } from "../../constants/urls";
import { useFetchItems } from "../../hooks/useFetchItems";
import { SelectInputStyled } from "./selectStyled";
import { Employee } from "../../interfaces/employee";
import Select from 'react-select';
export const EmployeeFormSelect = (props) => {
	const { data, isLoading, error, fetchItems } = useFetchItems(
		ApiEndpoints.employee
	);
	useEffect(() => {
		fetchItems();
	}, []);
	console.log(props)
	console.log(props.multi === true)
	if (props.multi === true) {
		return data && (
			<Select
				isMulti
				className="Index"
				options={data.map((employee) => ({
					value: employee.id,
					label: `${employee.name} ${employee.last_name}`,
				}))}
				value={props.value}
				onChange={(employee) => props.onChange(employee)}
				placeholder="Seleccionar empleados"
			/>
		);
	}
	if (props.value === undefined)
		return (
			<SelectInputStyled
				name={props.name}
				onChange={props.onChange}>
				{data &&
					data.map((employee, index) => (
						<option
							key={employee.id}
							value={employee.id}>
							{`${employee.name} ${employee.last_name}`}
						</option>
					))}
			</SelectInputStyled>
		);

	return (
		<SelectInputStyled
			name={props.name}
			value={props.value}
			onChange={props.onChange}>
			{data &&
				data.map((employee, index) => (
					<option
						key={employee.id}
						value={employee.id}>
				{`${employee.name} ${employee.last_name}`}
					</option>
				))}
		</SelectInputStyled>
	);
};
