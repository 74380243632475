import axios from "axios";



export const GetNotificationList = async () => {
    try{
        const token =localStorage.getItem("token")
        const request = await axios.get(`${process.env.REACT_APP_BASE_URL}api/v1/notifications`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return [];
    }
}

export const DeleteNotificationList = async () => {
    try{
        const token =localStorage.getItem("token")
        const request = await axios.delete(`${process.env.REACT_APP_BASE_URL}api/v1/notifications`,
        {
            headers:{
                "Authorization": `Bearer ${token}`
            }
        })
        return request.data;
    }
    catch (error){
        return [];
    }
}