import { Container } from "react-bootstrap";
import styled from "styled-components";

export const InputadorComponentStyled = styled(Container)`
	justify-content: space-between;
	bottom: 10;
	right: 10;
	display: flex;
	background-color: white;
	padding: 0.5em;
	border-color: transparent;
	border-width: 0.1em;
	border-style: solid;
	border-radius: 1em;
	box-shadow: 0px 4px 16px 0px rgba(48, 100, 217, 0.5);
	margin-right: .5em;
	margin-bottom: .3em;
	@media (max-width: 900px) {
		width: 100%;
		bottom: 0;
	}
`;
