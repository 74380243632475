import styled from "styled-components";
import { COLORS } from "../constants/colors";



export const PageTitle = styled.h1`
    font-size: 1.4rem;
    font-family: Roboto;
    font-weight: 600;
    color:  ${COLORS.primaryBlue};
`