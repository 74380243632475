import { useEffect } from "react";
import { ApiEndpoints } from "../../constants/urls";
import { useFetchItems } from "../../hooks/useFetchItems";
import { TaskInterface } from "../../interfaces/task";
import { SelectInputStyled } from "./selectStyled";

export const TaskSelectComponent = (props: {
  name: string;
  onChange: any;
  value: string;
}) => {
  const { data, isLoading, error, fetchItems } = useFetchItems(
    ApiEndpoints.task
  );
  useEffect(() => {
    fetchItems();
  }, []);
  return (
    <SelectInputStyled
      name={props.name}
      onChange={props.onChange}
      value={props.value}
    >
      <option>¿En qué estas trabajando?</option>
      {data &&
        data.map((task: TaskInterface, index: number) => (
          <option key={task.id} value={`${task.id}#${task.project_name}`}>
            {" "}
            {task.name}
          </option>
        ))}
    </SelectInputStyled>
  );
};

export const TaskSOptionCmponent = (props: {
  name: string;
  onChange: any;
  value: string;
}) => {
  const { data, isLoading, error, fetchItems } = useFetchItems(
    ApiEndpoints.task
  );
  useEffect(() => {
    fetchItems();
  }, []);
  return (
    <SelectInputStyled
      name={props.name}
      onChange={props.onChange}
      value={props.value}
    >
      <option>Selecciona la tarea</option>
      {data &&
        data.map((task: TaskInterface, index: number) => (
          <option key={task.id} value={`${task.id}`}>
            {" "}
            {task.name}
          </option>
        ))}
    </SelectInputStyled>
  );
};
