import styled from "styled-components";

export const SelectButtonStyled = styled.button<{ active: boolean }>`
	background-color: ${props => props.active ? "rgba(48, 100, 217, 1)" : "rgba(48, 100, 217, 0.1)"};
	border-color: transparent;
	border-radius: 0.3em;
	color: ${props => props.active ? "white" : "rgba(48, 100, 217, 1)"};
    font-size: 0.75rem;
    font-weight: 500;
	margin-right: 0.5em;
	padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;

`;


export const DownloadButtonStyled = styled.button`
	background-color: rgba(48, 100, 217, 1);
	border-color: transparent;
	border-radius: 0.3em;
	color: white;
    font-size: 0.75rem;
    font-weight: 500;
	margin-right: 0.5em;
	padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
	text-decoration: none;

`;
