import { Col, Form, Row, Container } from "react-bootstrap";
import { CardComponent } from "../../components/dashboard/cardComponent";
import { TitleText } from "../login/LoginStyles";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate, useParams } from "react-router-dom";
import { LabelStyled } from "../../components/formComponents/labelStyled";
import { InputStyled } from "../../components/formComponents/inputStyled";
import { ProjectFormSelectComponent } from "../../components/formComponents/projectFormSelect";
import { toast } from "react-toastify";
import { Plus } from "react-feather";
import { createExpenseApi } from "../../api/ExpenseApiHandler";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { useEffect, useState } from "react";
import { isEmpty } from "../../utility/isEmpty";

export const ExpenseCreatePage = () => {

  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);
  const [selectedImage, setSelectedImage] = useState('');
  const [image, setImage] = useState('');
  const { id } = useParams();

  const [projectId, setProjectId] = useState(id);


  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const createExpenseHandler = async (event: any) => {
    event.preventDefault();
    if (event.target.name.value === "") {
      toast.error("Type of expenses field is required!");
      return;
    }
    if (event.target.amount.value === "") {
      toast.error("Expense amount field is required!");
      return;
    }

    let formData = new FormData();
    formData.append("file", selectedImage);
    formData.append("project_id", event.target.project_id.value);
    formData.append("name", event.target.name.value);
    formData.append("amount", event.target.amount.value);
    formData.append("concept", event.target.concept.value);
    if(!isEmpty(image)) formData.append("invoice", image.substring(image.lastIndexOf(".") + 1));

    const createExpense = await createExpenseApi(formData);

    if (createExpense) navigate(-1);

  };
	

  return (
    <Container fluid style={{ padding: "1em" }}>
      <Row>
        <Col lg={2} md={12} sm={12} style={{ marginRight: "1em" }}>
          <AddButtonStyled onClick={() => navigate("/budget/create")}>
            <Plus /> Add Budget
          </AddButtonStyled>
          <InputComponentStyled
            placeholder="buscar gastos"
            disabled
          />
        </Col>
        <Col lg={9} md={12} sm={12}>
          <TitleText> Presupuestos </TitleText>
          <Container
            fluid
            style={{
              backgroundColor: "white",
              paddingTop: "1em",
              borderRadius: ".3em",
              height: "100%",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <CardComponent style={{ paddingBottom: "0" }}>
              <form onSubmit={createExpenseHandler}>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Project related
                      </LabelStyled>
                      <Col sm={8}>
                        <ProjectFormSelectComponent
                          name={"project_id"}
                          value={parseInt(projectId!)}
                          onChange={(event: any) => {
                            setProjectId(event.target.value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Type of expenses
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          name="name"
                          type="text"
                          placeholder="Type of expense"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Amount (no VAT)
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          name="amount"
                          type="text"
                          placeholder="amount"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Concept
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          name="concept"
                          type="text"
                          placeholder="concept"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <LabelStyled column sm={4}>
                        Photo (add)
                      </LabelStyled>
                      <Col sm={8}>
                        <InputStyled
                          value={image}
                          placeholder="choose any image"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <Form.Group as={Row}>
                      <Col sm={4}></Col>
                      <Col sm={8}>
                        <AddButtonStyled
                          style={{
                            width: "60%",
                            borderRadius: "0.3em",
                            padding: ".2em",
                          }}
                          type="button"
                          onClick={(event) => {
                            document.getElementById("imageInput")!.click();
                          }}
                        >
                          Add file
                        </AddButtonStyled>
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .bmp, .gif"
                          name="photo"
                          id="imageInput"
                          hidden={true}
                          onChange={(event: any) => {
                            if (event.target.files[0]) {
                              setSelectedImage(event.target.files[0]);
                              setImage(event.target.files[0].name);
                            } else {
                              setSelectedImage("");
                              setImage("");
                            }
                          }}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1em" }}>
                  <Col lg={8} md={9} sm={10} xs={12}>
                    <AddButtonStyled
                      style={{
                        width: "60%",
                        borderRadius: "0.3em",
                        padding: ".2em",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Add expenses
                    </AddButtonStyled>
                  </Col>
                </Row>
              </form>
            </CardComponent>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};