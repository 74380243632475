import styled from "styled-components";
import Form from 'react-bootstrap/Form';


export const InputComponentStyled = styled(Form.Control)`
    ::placeholder {
        font-size: 0.75rem;
        font-weight: 400;
        font-family: 'Roboto';
        line-height: 0.7rem;
    }
    margin-top: 0.5em;
`