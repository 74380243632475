import { useEffect, useState } from "react";
import { ChatFeed } from "react-chat-ui";
import { Container } from "react-bootstrap";
import { InputComponentStyled } from "../../components/inputComponent/inputComponent";
import { ChevronLeft } from "react-feather";
import {
  getMessagesApi,
  createMessagesApi,
  getRoomApi,
} from "../../api/EmployeeApiHandler";
import Button from "react-bootstrap/Button";
import { Send } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export const ChatMobilePage = () => {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [room, setRoom] = useState();
  const navigate = useNavigate();

  const formattingMessages = (messages) => {
    const formattedMessages = messages.map((message) => ({
      id: Number(message.status),
      message: message.message,
      senderName: message.senderName,
    }));
    setMessages(formattedMessages);
  };
  const sendMessageOnClick = async () => {
    if (inputValue) {
      await createMessagesApi({ message: inputValue }, id);
      setInputValue("");

      const updatedMessages = await getMessagesApi(id);
      formattingMessages(updatedMessages);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const fetchMessagesAndRoom = async () => {
      const messagesData = await getMessagesApi(id);
      formattingMessages(messagesData);
      if (!room) {
        const roomData = await getRoomApi(id);
        setRoom(roomData);
      }
    };
    fetchMessagesAndRoom();

    const intervalId = setInterval(fetchMessagesAndRoom, 2000);

    return () => clearInterval(intervalId);
  }, [id, room]);

  return (
    <Container
      style={{ width: "100%", paddingLeft: 0, height: "100%", paddingRight: 0 }}
    >
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          paddingTop: ".5em",
          paddingBottom: ".5em",
        }}
      >
        <ChevronLeft
          onClick={() => {
            navigate(`/chat`);
          }}
          style={{ marginRight: ".5em" }}
          size={"24"}
          color="rgba(0, 122, 255, 1)"
        />
        <p style={{ margin: 0 }}>{room && room.person}</p>
      </div>
      <Container style={{ borderRadius: "1em", height: "70vh" }}>
        <Container
          style={{
            marginTop: "10px",
            width: "100%",
            paddingLeft: 0,
            paddingRight: 0,
            height: "100%",
          }}
        >
          <Container
            style={{
              position: "relative",
              backgroundColor: "rgba(213, 212, 242, 1)",
              width: "100%",
              height: "100%",
              paddingRight: 0,
            }}
          >
            <div
              style={{
                maxHeight: "415px",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              <ChatFeed
                messages={messages}
                isTyping={true}
                hasInputField={false}
                showSenderName
                bubblesCentered={false}
                bubbleStyles={{
                  text: {
                    fontSize: 14,
                    color: "#000000",
                  },
                  chatbubble: {
                    borderRadius: 15,
                    padding: 10,
                  },
                  userBubble: {
                    backgroundColor: "rgba(52, 151, 249, 0.4)",
                  },
                }}
              />
            </div>
            <div
              style={{
                width: "90%",
                position: "absolute",
                bottom: 7,
                left: "5%",
                right: "10%",
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <div
                onClick={sendMessageOnClick}
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                }}
              >
                <InputComponentStyled
                  type="text"
                  placeholder="Ingresa tu mensaje"
                  value={inputValue}
                  onChange={handleChange}
                />

                <Button
                  variant="primary"
                  style={{
                    marginTop: "8px",
                    marginLeft: "5px",
                  }}
                >
                  <Send size={18} />
                </Button>
              </div>
            </div>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};
