import { toast } from "react-toastify";
import { resetPasswordEmail } from "../../api/UserApiHandler";
import { SelectButtonStyled } from "../../components/EmployeeComponents/buttonTypes";
import { HeaderLoginComponent } from "../../components/headerLogin/HeaderLogin";
import {
  GlobalContainer,
  TitleText,
  FormContainer,
  Label,
  InputContainer,
  Input,
  AsteriskText,
} from "./LoginStyles";
import { useNavigate } from "react-router-dom";

export const RecoverPasswordPage = () => {
  const navigate = useNavigate()
  const recoverUserPasswordHandler = async (event: any) => {
    event.preventDefault();
    const request = await resetPasswordEmail(event.target.email.value)
    if (request){
      toast.success("Se ha enviado una nueva contraseña al correo")
      setTimeout(() => {navigate("/login")},2000)
    }else
      toast.error("Error recuperando la contraseña")
  }

  return (
    <>
      <HeaderLoginComponent isNewUserText={true} />
      <GlobalContainer>
        <TitleText>Recuperar contraseña</TitleText>
        <FormContainer onSubmit={recoverUserPasswordHandler}>
          <InputContainer>
            <Label>
              Email <AsteriskText>*</AsteriskText>
            </Label>
            <Input type="text" name="email" placeholder="Introduce tu correo electrónico" />
          </InputContainer>
          <div style={{ display: "flex", justifyContent: "end" }}>
						<SelectButtonStyled
							active={true}
							style={{ width: "30%" }}>
							<p style={{ margin: 0 }}>Enviar</p>
						</SelectButtonStyled>
					</div>
        </FormContainer>
      </GlobalContainer>
    </>
  );
};
