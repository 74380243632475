import { useEffect, useState } from "react";
import { useFetchItems } from "../../hooks/useFetchItems";
import { ApiEndpoints } from "../../constants/urls";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TitleText } from "../login/LoginStyles";
import { AddButtonStyled } from "../../components/buttonComponent/buttonStyled";
import { useNavigate } from "react-router-dom";
import { Plus } from "react-feather";
import {
  EmployeeTableName,
  EmployeeTableText,
} from "../../components/EmployeeList/employeeTableStyled";

export const HolidayListPage = () => {
  const [holiday, setHoliday] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);
  const navigate = useNavigate();
  const { data, isLoading, error, fetchItems } = useFetchItems(
    ApiEndpoints.holiday
  );
  useEffect(() => {
    if (data === null) {
      fetchItems();
    } else if (data !== null && holiday.length === 0) {
      setHoliday(data);
    }
  }, [data]);
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);
  console.log(holiday);
  return (
    <Container
      fluid
      style={{
        padding: "1em",
        paddingRight: isMobileView ? "1rem" : "0",
      }}
    >
      <Row>
        <Col lg={2} md={12} sm={12} style={{ marginRight: "1em" }}>
          <AddButtonStyled
            onClick={() => {
              navigate("/holiday/create");
            }}
          >
            <Plus /> Añadir turno de vacaciones
          </AddButtonStyled>
        </Col>

        <Col lg={9} md={12} sm={12}>
          {!isMobileView && <TitleText> Vacaciones </TitleText>}
          <Container
            fluid
            style={{
              backgroundColor: "white",
              paddingTop: "1em",
              borderRadius: ".3em",
              height: "100%",
              marginTop: isMobileView ? "1em" : "0",
            }}
          >
            <Row
              style={{
                height: "2em",
                textAlign: "start",
                padding: " .5em 1em 1em 1em",
              }}
            >
              <Col lg={4}>
                <EmployeeTableName>Nombre</EmployeeTableName>
              </Col>
              
            </Row>
            <hr
              style={{
                color: "rgba(149, 187, 232, 0.6)",
                borderWidth: "2px",
                marginTop: 0,
                marginBottom: 0,
              }}
            />
            {holiday &&
              holiday.map((holiday: any) => (
                <div
                  key={holiday.id}
                  onClick={() => {
                    navigate(`/holiday/${holiday.id}`);
                  }}
                >
                  <Row
                    style={{
                      height: "3em",
                      textAlign: "start",
                      padding: "2em",
                    }}
                  >
                    <Col lg={4}>
                      <EmployeeTableName>{holiday.name}</EmployeeTableName>
                    </Col>
                   
                  </Row>
                  <hr
                    style={{
                      color: "rgba(149, 187, 232, 0.6)",
                      borderWidth: "2px",
                      marginTop: "1em",
                      marginBottom: 0,
                    }}
                  />
                </div>
              ))}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
